import { Injectable, OnInit } from '@angular/core';
import { Globals } from '@app/services/globals';

@Injectable({
    providedIn: 'root'
})
export class MathUtils {
    constructor(
        public globals: Globals
    ) { }

    // clamps a value between a min & max value
    clamp(value, min, max) {
        return Math.min(Math.max(value, min), max);
    }

    // checks if a string contains only numbers (works only for positive numbers!)
    isNumericString(input) {
        return /^\d+$/.test(input);
    }
}
