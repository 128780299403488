import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ModalService } from '@app/services/modal.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@app/services/settings.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WeightChargesGridComponent } from '@app/grids/weight-charges-grid/weight-charges-grid.component';
import { ServicesChargesGridComponent } from '@app/grids/services-charges-grid/services-charges-grid.component';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ItemsChargesGridComponent } from '@app/grids/items-charges-grid/items-charges-grid.component';
import { take } from 'rxjs/operators';
import { SurchargeGridComponent } from '@app/grids/surcharge-grid/surcharge-grid.component';
import { GenericService } from '@app/services/generic.service';

@Component({
  selector: 'app-zone-charges-modal',
  templateUrl: './zone-charges-modal.component.html',
  styleUrls: ['./zone-charges-modal.component.scss']
})
export class ZoneChargesModalComponent implements OnInit, OnDestroy {
  @ViewChild(WeightChargesGridComponent, { static: false }) weightChargesGridComponent: WeightChargesGridComponent;
  @ViewChild(ItemsChargesGridComponent, { static: false }) itemsChargesGridComponent: ItemsChargesGridComponent;
  @ViewChild(ServicesChargesGridComponent, { static: false }) servicesChargesGridComponent: ServicesChargesGridComponent;
  @ViewChild(SurchargeGridComponent, { static: false }) surchargeGridComponent: SurchargeGridComponent;

  listen = [];
  myForm: FormGroup;

  customerId = null;
  dataLoading = false;
  forRegionCharge = false;

  modalOpen = false;

  // form
  id;
  name;
  chargeId;
  weightValue;
  priceValue;
  additionalWeightCharge = null;
  defaultWeightCharges = [];
  additionalPerItemCharge = null;
  defaultPerItemCharges = [];
  servicesCharges = [];
  fuelSurcharges = [];
  tempId;

  timeWindowChargeLabel = '';
  payOnDeliveryChargeLabel = '';
  sameDayDeliveryChargeLabel = '';
  requestItemIdentifierHash;

  // chargeCategoryXSystemicCourierServiceIdsToDelete = [];
  systemicCouriers = [];
  selectedSystemicCouriers = [];
  selectedSystemicCouriersServiceIds = [];
  systemicCourierServiceIdsToData = {};

  constructor(
    private http: HttpClient,
    public translate: TranslateService,
    private modalService: ModalService,
    private settingsService: SettingsService,
    public globals: Globals,
    public router: Router,
    formBuilder: FormBuilder,
    public genericService: GenericService,
  ) {
    this.myForm = formBuilder.group({
      'id': [this.id],
      'requestItemIdentifierHash': [this.requestItemIdentifierHash],
      'name': [this.name],
      'systemic_courier_services': [this.selectedSystemicCouriersServiceIds],
      'charge_category_X_systemic_courier_services': [this.selectedSystemicCouriers],
      'defaultWeightCharges': [this.defaultWeightCharges],
      'additionalWeightCharge': [this.additionalWeightCharge],
      'defaultPerItemCharges': [this.defaultPerItemCharges],
      'additionalPerItemCharge': [this.additionalPerItemCharge],
      'servicesCharges': [this.servicesCharges],
      'fuelSurcharges': [this.fuelSurcharges],
      'temp_id': [this.tempId]
    });

    this.listen.push(this.settingsService.openZoneChargesModalListen().subscribe(data => {
      if (this.modalOpen === false) {
        this.loadAndOpenZoneModal(data);

        // if (this.globals.isInRoute('customerCollaborators') || this.globals.isInRoute('partners')) {
        //   setTimeout(() => {
        //     if (this.surchargeGridComponent) {
        //       if (data) {
        //         if (data['id']) {
        //           this.surchargeGridComponent.chargeCategoryBeingEditedId = data['id'];
        //           this.surchargeGridComponent.setSurchargesGridData();
        //         } else {
        //           this.surchargeGridComponent.chargeCategoryBeingEditedId = null;
        //         }
        //       } else {
        //         this.surchargeGridComponent.chargeCategoryBeingEditedId = null;
        //       }
        //     }
        //   }, 100);
        // }
      }
    }));
    this.listen.push(this.settingsService.closeZoneChargesModalListen().subscribe((response: any) => {
      this.closeModal();
    }));

    // after weight charges grid is updated, patch the form with the new grid data
    this.listen.push(this.modalService.updateWeightChargesFormListen().subscribe((data) => {
      this.myForm.patchValue({
        'defaultWeightCharges': data.defaultWeightCharges,
        'additionalWeightCharge': data.additionalWeightCharge
      });
    }));

    // after items charges grid is updated, patch the form with the new grid data
    this.listen.push(this.modalService.updateItemsChargesFormListen().subscribe((data) => {
      this.myForm.patchValue({
        'defaultPerItemCharges': data.defaultPerItemCharges,
        'additionalPerItemCharge': data.additionalPerItemCharge
      });
    }));

    // after services charges grid is updated, patch the form with the new grid data
    this.listen.push(this.modalService.updateServicesChargesFormListen().subscribe((data) => {
      this.myForm.patchValue({
        'servicesCharges': data
      });
    }));

    this.listen.push(this.modalService.updateSurchargesListen().subscribe((data) => {
      console.warn(data);
      this.fuelSurcharges = data;
      this.myForm.patchValue({
        'fuelSurcharges': data
      });
      this.surchargeGridComponent.surchargesDataArray = [];
      this.surchargeGridComponent.surcharges = data;
      this.surchargeGridComponent.updateGrid(this.surchargeGridComponent.surcharges);


      // // edit already saved charge
      // if (data.id) {
      //   for (let index = 0; index < this.gridData.length; index++) {
      //     if (this.gridData[index].id == data.id) {
      //       this.gridData[index] = data;
      //       break;
      //     }
      //   }
      // }

      // // create/edit unsaved charge
      // else {
      //   // edit unsaved charge
      //   if (data.temp_id) {
      //     for (let index = 0; index < this.gridData.length; index++) {
      //       if (this.gridData[index].temp_id) {
      //         if (this.gridData[index].temp_id == data.temp_id) {
      //           this.gridData[index] = data;
      //           break;
      //         }
      //       }
      //     }
      //   }
      //   // create unsaved charge (add tempId for delete/edit to work with multiple null id charges)
      //   else {
      //     data.temp_id = this.tempIdCounter;
      //     this.tempIdCounter++;
      //     this.gridData.push(data);
      //   }
      // }

      // this.updateGrid(this.gridData);
      // this.modalService.updateServicesChargesForm(this.gridData);
    }));
  }

  updateZoneData(data) {
    this.patchForm(data);

    let additionalWeightCharge = null;
    if (data.additionalWeightCharge) {
      if (data.additionalWeightCharge.id) {
        additionalWeightCharge = data.additionalWeightCharge;
      }
    }

    let additionalPerItemCharge = null;
    if (data.additionalPerItemCharge) {
      if (data.additionalPerItemCharge.id) {
        additionalPerItemCharge = data.additionalPerItemCharge;
      }
    }

    let weightCharges = {
      'defaultWeightCharges': data.defaultWeightCharges,
      'additionalWeightCharge': additionalWeightCharge
    };
    let itemsCharges = {
      'defaultPerItemCharges': data.defaultPerItemCharges,
      'additionalPerItemCharge': additionalPerItemCharge
    };

    this.weightChargesGridComponent.updateGrid(weightCharges);
    this.itemsChargesGridComponent.updateGrid(itemsCharges);
    this.servicesChargesGridComponent.updateGrid(data.servicesCharges);
    this.surchargeGridComponent.surcharges = data.fuelSurcharges;
    this.surchargeGridComponent.updateGrid(data.fuelSurcharges);
  }

  // loads the initial values for a new zone charge
  loadNewZoneData() {
    let data = {
      id: null,
      requestItemIdentifierHash: this.globals.generateChargeCategoryIdentifierHash(),
      name: null,
      defaultWeightCharges: [],
      defaultPerItemCharges: [],
      servicesCharges: [
        {
          "id": null,
          "service_type": this.globals.voucherServicesTypesConstants['TIME_WINDOW'],
          "service_price": 0,
          "service_name": this.timeWindowChargeLabel,
          "is_default_service": true,
          "is_per_item": false,
          "temp_id": 1
        },
        {
          "id": null,
          "service_type": this.globals.voucherServicesTypesConstants['PAY_ON_DELIVERY'],
          "service_price": 0,
          "service_name": this.payOnDeliveryChargeLabel,
          "is_default_service": true,
          "is_per_item": false,
          "temp_id": 2
        },
        {
          "id": null,
          "service_type": this.globals.voucherServicesTypesConstants['SAME_DAY_DELIVERY'],
          "service_price": 0,
          "service_name": this.sameDayDeliveryChargeLabel,
          "is_default_service": true,
          "is_per_item": false,
          "temp_id": 3
        },
      ],
      fuelSurcharges: []
    };

    setTimeout(() => {
      this.updateZoneData(data);
    }, 200);

  }

  loadAndOpenZoneModal(data) {
    if (data) {
      if (data === 'forRegionCharge') {
        this.forRegionCharge = true;
        this.openModal();
        this.loadNewZoneData();
      } else {
        this.openModal();

        setTimeout(() => {
          this.updateZoneData(data);
        }, 200);
      }
    } else {
      this.openModal();
      this.loadNewZoneData();
    }
  }

  dataLoadingListen(event) {
    if (event === 'true') {
      this.dataLoading = true;
    } else if (event === 'false') {
      this.dataLoading = false;
    }
  }

  resetForm() {
    this.forRegionCharge = false;
    this.selectedSystemicCouriers = [];
    this.myForm.patchValue({
      'id': null,
      'requestItemIdentifierHash': null,
      'name': null,
      'charge_category_X_systemic_courier_services': [],
      'defaultWeightCharges': [],
      'additionalWeightCharge': null,
      'servicesCharges': [],
      'fuelSurcharges': this.fuelSurcharges,
      'temp_id': null
    });

    M.updateTextFields();
    this.weightChargesGridComponent.updateGrid(null);
    this.itemsChargesGridComponent.updateGrid(null);
    this.servicesChargesGridComponent.updateGrid(null);
    this.surchargeGridComponent.updateGrid(null);

    this.surchargeGridComponent.chargeCategoryBeingEditedId = null;
  }

  patchForm(data) {
    let additionalWeightCharge = null;
    if (data.additionalWeightCharge) {
      if (data.additionalWeightCharge.id) {
        additionalWeightCharge = data.additionalWeightCharge;
      }
    }

    let additionalPerItemCharge = null;
    if (data.additionalPerItemCharge) {
      if (data.additionalPerItemCharge.id) {
        additionalPerItemCharge = data.additionalPerItemCharge;
      }
    }

    const systemicCouriers = [], selectedSystemicCouriersServiceIds = [];
    if (data.charge_category_X_systemic_courier_services) {
      data.charge_category_X_systemic_courier_services.forEach(systemicCourier => {
        const serviceData = this.systemicCourierServiceIdsToData[systemicCourier.service_id];
        if (serviceData) {
          systemicCouriers.push(serviceData);
          selectedSystemicCouriersServiceIds.push(systemicCourier.service_id);
        }
      });
    }

    this.myForm.patchValue({
      'id': data.id,
      'requestItemIdentifierHash': data.requestItemIdentifierHash,
      'name': data.name,
      'charge_category_X_systemic_courier_services': systemicCouriers,
      'systemic_courier_services': selectedSystemicCouriersServiceIds,
      'defaultWeightCharges': data.defaultWeightCharges,
      'additionalWeightCharge': additionalWeightCharge,
      'defaultPerItemCharges': data.defaultPerItemCharges,
      'additionalPerItemCharge': additionalPerItemCharge,
      'servicesCharges': data.servicesCharges,
      'fuelSurcharges': data.fuelSurcharges,
      'temp_id': data.temp_id
    });
    M.updateTextFields();
  }

  calculateSystemicCouriers() {
    const selectedSystemicCouriersServiceIds = this.myForm.value.systemic_courier_services;
    this.selectedSystemicCouriers = [];

    if (selectedSystemicCouriersServiceIds) {
      selectedSystemicCouriersServiceIds.forEach(serviceId => {
        const serviceData = this.systemicCourierServiceIdsToData[serviceId];
        if (serviceData) {
          this.selectedSystemicCouriers.push(serviceData);
        }
      });
    }

    this.myForm.patchValue({
      'charge_category_X_systemic_courier_services': this.selectedSystemicCouriers,
    });
    M.updateTextFields();
  }

  // deleteSystemicCourier(id) {
  //   this.chargeCategoryXSystemicCourierServiceIdsToDelete.push(id);
  // }

  openModal() {
    this.genericService.comm100ZIndexFix();
    this.modalOpen = true;
    setTimeout(() => {
      const modal = document.querySelector('.zone-charges-modal');
      const modalBackground = document.querySelector('.zone-charges-modal-background');
      modal.classList.add('open');
      modal.classList.remove('closed');
      modalBackground.classList.remove('hidden');
    }, 100);
  }

  closeModal() {
    const modal = document.querySelector('.zone-charges-modal');
    const modalBackground = document.querySelector('.zone-charges-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
    this.resetForm();
    setTimeout(() => {
      this.modalOpen = false;
    }, 1000);
  }

  closeAndCancelChanges() {
    // this.settingsService.updateZoneChargesGrid();
    this.weightChargesGridComponent.deleteDefaultChargesIds = [];
    this.weightChargesGridComponent.deleteAdditionalChargesIds = [];
    this.itemsChargesGridComponent.deleteDefaultChargesIds = [];
    this.itemsChargesGridComponent.deleteAdditionalChargesIds = [];
    this.servicesChargesGridComponent.deleteServiceChargesIds = [];
    // this.chargeCategoryXSystemicCourierServiceIdsToDelete = [];
    this.closeModal();
  }

  submitZoneCharges() {
    this.calculateSystemicCouriers();
    let chargeCategories = {
      'chargeCategories': [
        this.myForm.value
      ]
    };

    if (this.myForm.value.name) {
      // settings
      if (this.router.url.split('/')[1] == 'settings') {
        if (this.myForm.value.id) {
          const zoneId = this.myForm.value.id;

          // delete rows marked for deletion & then put
          let deleteCharges = {
            'defaultWeightChargesIdsToDelete': this.weightChargesGridComponent.deleteDefaultChargesIds,
            'additionalWeightChargesIdsToDelete': this.weightChargesGridComponent.deleteAdditionalChargesIds,
            'defaultPerItemChargesIdsToDelete': this.itemsChargesGridComponent.deleteDefaultChargesIds,
            'additionalPerItemChargesIdsToDelete': this.itemsChargesGridComponent.deleteAdditionalChargesIds,
            'serviceChargesIdsToDelete': this.servicesChargesGridComponent.deleteServiceChargesIds,
            // 'chargeCategoryXSystemicCourierServiceIdsToDelete': this.chargeCategoryXSystemicCourierServiceIdsToDelete,
          };

          if (deleteCharges.defaultWeightChargesIdsToDelete.length || deleteCharges.serviceChargesIdsToDelete || deleteCharges.serviceChargesIdsToDelete) {
            this.http.post('api/v1/delete-charges', JSON.stringify(deleteCharges)).pipe(take(1)).subscribe(() => {
              this.http.put('api/v1/charge-categories/' + zoneId, JSON.stringify(chargeCategories)).pipe(take(1)).subscribe(() => {
                this.closeModal();
                this.globals.getZones();
                this.settingsService.updateZoneChargesGrid();
                this.settingsService.updateRegionalChargesGrid();
              });
            });
          }
        } else {
          this.http.post('api/v1/charge-categories', JSON.stringify(chargeCategories)).pipe(take(1)).subscribe(response => {
            this.globals.getZones();
            this.settingsService.updateZoneChargesGrid();
            if (this.forRegionCharge) {
              if (response['items']) {
                if (response['items'][0]) {
                  this.settingsService.selectZoneInRegionsCharge(response['items'][0]);
                }
              }
            } else {
              this.settingsService.updateRegionalChargesGrid();
            }
            this.closeModal();
          });
        }
      }

      // collaborator form
      else {
        this.modalService.updateCollaboratorsChargesForm(this.myForm.value);
        this.closeModal();
      }
    }
  }

  getTranslations() {
    this.listen.push(this.translate.get('SETTINGS.DEFAULT_SERVICE_CHARGES').subscribe((res: string) => {
      this.timeWindowChargeLabel = res['TIME_WINDOW'];
      this.payOnDeliveryChargeLabel = res['PAY_ON_DELIVERY'];
      this.sameDayDeliveryChargeLabel = res['SAME_DAY_DELIVERY'];
    }));
    this.listen.push(this.translate.get('SYSTEMIC_COURIER_SERVICES').subscribe((res: string) => {
      const systemicCourierLabels = {
        WORLDWIDE_EXPRESS: res['SYSTEMIC_COURIER_WORLDWIDE_EXPRESS'],
        WORLDWIDE_ECONOMY: res['SYSTEMIC_COURIER_WORLDWIDE_ECONOMY'],
        ACS: res['ACS'],
      }

      const systemicCouriers = [];
      if (this.globals.systemicCouriers) {
        Object.keys(this.globals.systemicCouriers).forEach(systemicCourierName => {
          Object.keys(this.globals.systemicCouriers[systemicCourierName]).forEach(systemicCourierId => {
            const label = systemicCourierLabels[this.globals.systemicCouriers[systemicCourierName][systemicCourierId]];
            if (label) {
              this.systemicCourierServiceIdsToData[systemicCourierId] = {
                service_id: Number(systemicCourierId),
                systemic_courier_name_const: systemicCourierName,
              };
              systemicCouriers.push({
                label: label,
                value: Number(systemicCourierId),
              });
            }
          });
        });
      }
      this.systemicCouriers = systemicCouriers;
    }));
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }
}
