import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private openDepotModalListeners = new Subject<any>();
  private openDynamicFieldModalListeners = new Subject<any>();
  private openPackagingModalListeners = new Subject<any>();
  private openWeightChargesModalListeners = new Subject<any>();
  private openItemChargesModalListeners = new Subject<any>();
  private openServicesChargesModalListeners = new Subject<any>();
  private openExtraChargesModalListeners = new Subject<any>();
  private openRegionChargesModalListeners = new Subject<any>();
  private closeRegionChargesModalListeners = new Subject<any>();
  private openZoneChargesModalListeners = new Subject<any>();
  private closeZoneChargesModalListeners = new Subject<any>();
  private updateDepotsGridListeners = new Subject<any>();
  private updateDynamicFieldsGridListeners = new Subject<any>();
  private updatePackagingGridListeners = new Subject<any>();
  private updateRulesGridListeners = new Subject<any>();
  private updateTimeWindowGridListeners = new Subject<any>();
  private updateWeightChargesGridListeners = new Subject<any>();
  private updateZoneChargesGridListeners = new Subject<any>();
  private updateServicesChargesGridListeners = new Subject<any>();
  private updateRegionalChargesGridListeners = new Subject<any>();
  private selectZoneInRegionsChargeListeners = new Subject<any>();
  private updateExtraChargesGridListeners = new Subject<any>();
  private updateCustomerCollaboratorsGridListeners = new Subject<any>();
  private setRegionChargeGeometryListeners = new Subject<any>();
  private setAvailableZonesListeners = new Subject<any>();
  private setSurchargesListeners = new Subject<any>();

  openDepotModalListen(): Observable<any> {
    return this.openDepotModalListeners.asObservable();
  }
  openDynamicFieldModalListen(): Observable<any> {
    return this.openDynamicFieldModalListeners.asObservable();
  }
  openPackagingModalListen(): Observable<any> {
    return this.openPackagingModalListeners.asObservable();
  }
  openWeightChargesModalListen(): Observable<any> {
    return this.openWeightChargesModalListeners.asObservable();
  }
  openItemChargesModalListen(): Observable<any> {
    return this.openItemChargesModalListeners.asObservable();
  }
  openServicesChargesModalListen(): Observable<any> {
    return this.openServicesChargesModalListeners.asObservable();
  }
  openExtraChargesModalListen(): Observable<any> {
    return this.openExtraChargesModalListeners.asObservable();
  }
  openRegionChargesModalListen(): Observable<any> {
    return this.openRegionChargesModalListeners.asObservable();
  }
  closeRegionChargesModalListen(): Observable<any> {
    return this.closeRegionChargesModalListeners.asObservable();
  }
  openZoneChargesModalListen(): Observable<any> {
    return this.openZoneChargesModalListeners.asObservable();
  }
  closeZoneChargesModalListen(): Observable<any> {
    return this.closeZoneChargesModalListeners.asObservable();
  }
  updateDepotsGridListen(): Observable<any> {
    return this.updateDepotsGridListeners.asObservable();
  }
  updateDynamicFieldsGridListen(): Observable<any> {
    return this.updateDynamicFieldsGridListeners.asObservable();
  }
  updatePackagingGridListen(): Observable<any> {
    return this.updatePackagingGridListeners.asObservable();
  }
  updateRulesGridListen(): Observable<any> {
    return this.updateRulesGridListeners.asObservable();
  }
  updateTimeWindowGridListen(): Observable<any> {
    return this.updateTimeWindowGridListeners.asObservable();
  }
  updateWeightChargesGridListen(): Observable<any> {
    return this.updateWeightChargesGridListeners.asObservable();
  }
  updateZoneChargesGridListen(): Observable<any> {
    return this.updateZoneChargesGridListeners.asObservable();
  }
  updateServicesChargesGridListen(): Observable<any> {
    return this.updateServicesChargesGridListeners.asObservable();
  }
  updateRegionalChargesGridListen(): Observable<any> {
    return this.updateRegionalChargesGridListeners.asObservable();
  }
  selectZoneInRegionsChargeListen(): Observable<any> {
    return this.selectZoneInRegionsChargeListeners.asObservable();
  }
  updateCustomerCollaboratorsGridListen(): Observable<any> {
    return this.updateCustomerCollaboratorsGridListeners.asObservable();
  }
  updateExtraChargesModalListen(): Observable<any> {
    return this.updateExtraChargesGridListeners.asObservable();
  }
  setRegionChargeGeometryListen(): Observable<any> {
    return this.setRegionChargeGeometryListeners.asObservable();
  }
  setAvailableZonesListen(): Observable<any> {
    return this.setAvailableZonesListeners.asObservable();
  }
  setSurchargesListen(): Observable<any> {
    return this.setSurchargesListeners.asObservable();
  }

  openDepotModal(data) {
    this.openDepotModalListeners.next(data);
  }
  openDynamicFieldModal() {
    this.openDynamicFieldModalListeners.next(1);
  }
  openPackagingModal() {
    this.openPackagingModalListeners.next(1);
  }
  openWeightChargesModal(data) {
    this.openWeightChargesModalListeners.next(data);
  }
  openItemChargesModal(data) {
    this.openItemChargesModalListeners.next(data);
  }
  openServicesChargesModal(data) {
    this.openServicesChargesModalListeners.next(data);
  }
  openExtraChargesModal(data) {
    this.openExtraChargesModalListeners.next(data);
  }
  openRegionChargesModal(data) {
    this.openRegionChargesModalListeners.next(data);
  }
  closeRegionChargesModal() {
    this.closeRegionChargesModalListeners.next(1);
  }
  openZoneChargesModal(data) {
    this.openZoneChargesModalListeners.next(data);
  }
  closeZoneChargesModal() {
    this.closeZoneChargesModalListeners.next(1);
  }
  updateDepotsGrid() {
    this.updateDepotsGridListeners.next(1);
  }
  updateDynamicFieldsGrid() {
    this.updateDynamicFieldsGridListeners.next(1);
  }
  updatePackagingGrid() {
    this.updatePackagingGridListeners.next(1);
  }
  updateRulesGrid() {
    this.updateRulesGridListeners.next(1);
  }
  updateTimeWindowGrid() {
    this.updateTimeWindowGridListeners.next(1);
  }
  updateWeightChargesGrid() {
    this.updateWeightChargesGridListeners.next(1);
  }
  updateZoneChargesGrid() {
    this.updateZoneChargesGridListeners.next(1);
  }
  updateCustomerCollaboratorsGrid() {
    this.updateCustomerCollaboratorsGridListeners.next(1);
  }
  updateServicesChargesGrid() {
    this.updateServicesChargesGridListeners.next(1);
  }
  updateRegionalChargesGrid() {
    this.updateRegionalChargesGridListeners.next(1);
  }
  selectZoneInRegionsCharge(zone) {
    this.selectZoneInRegionsChargeListeners.next(zone);
  }
  updateExtraChargesGrid() {
    this.updateExtraChargesGridListeners.next(1);
  }
  setRegionChargeGeometry(geometry) {
    this.setRegionChargeGeometryListeners.next(geometry)
  }
  setAvailableZones() {
    this.setAvailableZonesListeners.next(1);
  }
  setSurcharges() {
    this.setSurchargesListeners.next(1);
  }
}
