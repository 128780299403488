import { Globals } from '@app/services/globals';
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@app/services/settings.service';
import { of } from 'rxjs';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-services-charges-grid',
  templateUrl: './services-charges-grid.component.html',
  styleUrls: ['./services-charges-grid.component.scss']
})
export class ServicesChargesGridComponent implements OnInit, OnDestroy {
  @ViewChild('servicesChargesGrid', { static: false }) servicesChargesGrid: AgGridAngular;

  listen = [];
  searchString: String = '';

  gridApi;
  gridColumnApi;
  domLayout = 'autoHeight';
  servicesChargesDataArray = [];
  deleteServiceChargesIds = [];
  rowData: any;
  columnDefs;
  tempIdCounter = 4; // first 3 tempIds are reserved for the creation of the default service charges!

  amountLabel: string;
  actionsLabel: string;
  currencyLabel: string;

  collaboratorServices;
  collaboratorId;

  // widths
  nameWidth;
  amountWidth;
  actionsWidth;

  serviceTitle: string;
  amountTitle: string
  actionsTitle: string;
  perItemLabel: string;
  gridData: any;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private settingsService: SettingsService,
    public gridsService: GridsService,
    public modalService: ModalService,
    public globals: Globals,
    public router: Router,
  ) {
    this.listen.push(this.modalService.updateServiceChargesListen().subscribe((data) => {
      // edit already saved charge
      if (data.id) {
        for (let index = 0; index < this.gridData.length; index++) {
          if (this.gridData[index].id == data.id) {
            this.gridData[index] = data;
            break;
          }
        }
      }

      // create/edit unsaved charge
      else {
        // edit unsaved charge
        if (data.temp_id) {
          for (let index = 0; index < this.gridData.length; index++) {
            if (this.gridData[index].temp_id) {
              if (this.gridData[index].temp_id == data.temp_id) {
                this.gridData[index] = data;
                break;
              }
            }
          }
        }
        // create unsaved charge (add tempId for delete/edit to work with multiple null id charges)
        else {
          data.temp_id = this.tempIdCounter;
          this.tempIdCounter++;
          this.gridData.push(data);
        }
      }

      this.updateGrid(this.gridData);
      this.modalService.updateServicesChargesForm(this.gridData);
    }));
  }

  setServicesChargesGridData() {
  }

  updateGrid(data) {
    let gridObject = {};
    this.gridData = data;
    this.servicesChargesDataArray = [];

    if (data) {
      if (data.length) {
        data.forEach(charge => {
          // Check if per-item enabled/disabled first
          let amountValue = '';
          if (charge.service_price >= 0) {
            amountValue = '+ ';
          }
          if (charge.is_per_item) {
            amountValue += charge.service_price + this.currencyLabel + ` (${this.perItemLabel})`;
          } else {
            amountValue += charge.service_price + this.currencyLabel
          }

          gridObject = {
            id: charge.id,
            service_name: charge.service_name,
            amount: amountValue,
            actions: {
              actions: 'X',
              isDefaultService: charge.is_default_service,
            },
            objData: charge,
          };
          this.servicesChargesDataArray.push(gridObject);
        });
      } else {
        const noData = { noDataText: 'No data' }
        this.servicesChargesDataArray.push(noData);
      }
    } else {
      const noData = { noDataText: 'No data' }
      this.servicesChargesDataArray.push(noData);
    }

    this.rowData = of(this.servicesChargesDataArray);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  rowClicked(event) {
    const target = event.event.target.closest('div');
    if (!event.data.noDataText && !target.classList.contains('actions-container')) {
      this.settingsService.openServicesChargesModal(event.data.objData);
    }
  }

  onFirstDataRendered(params) { }

  newServiceCharge() {
    this.settingsService.openServicesChargesModal(null);
  }

  deleteServiceCharge(event) {
    if (!event.data.objData.is_default_service) {
      // remove existing entry
      if (event.data.id) {
        for (let index = 0; index < this.gridData.length; index++) {
          if (this.gridData[index].id) {
            if (this.gridData[index].id == event.data.id) {
              this.deleteServiceChargesIds.push(this.gridData[index].id);
              this.gridData.splice(index, 1);
              this.updateGrid(this.gridData);
              this.modalService.updateServicesChargesForm(this.gridData);
              break;
            }
          }
        }
      }
      else {
        for (let index = 0; index < this.gridData.length; index++) {
          // remove new (unsubmitted) entry using temp id
          if (this.gridData[index].temp_id) {
            if (this.gridData[index].temp_id == event.data.objData.temp_id) {
              this.gridData.splice(index, 1);
              this.updateGrid(this.gridData);
              this.modalService.updateServicesChargesForm(this.gridData);
              break;
            }
          }
        }
      }
    }
  }

  // custom renderer for actions
  actionsRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (!params.getValue().isDefaultService) {
        columnObject += '<div class="single-cell standard-width actions-container">' + '<i class="fas fa-times fa-lg actions-delete"></i>' + '</div>';
      }
    }

    return columnObject;
  }

  getTranslations() {
    this.listen.push(this.translate.get('SETTINGS.SERVICE_NAME').subscribe((res: string) => { this.serviceTitle = res; }));
    this.listen.push(this.translate.get('SETTINGS.COST_AMOUNT').subscribe((res: string) => { this.amountTitle = res; }));
    this.listen.push(this.translate.get('SETTINGS.ACTIONS').subscribe((res: string) => { this.actionsTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currencyLabel = res; }));
    this.listen.push(this.translate.get('SETTINGS.PER_ITEM_SHORT').subscribe((res: string) => { this.perItemLabel = res; }));

    this.updateColumnDefs();
  }

  updateColumnDefs() {
    this.columnDefs = [
      { headerName: this.serviceTitle, field: 'service_name', width: this.gridsService.widthCalculatorContainerId(this.nameWidth, 'services-charges-container') },
      { headerName: this.amountTitle, field: 'amount', width: this.gridsService.widthCalculatorContainerId(this.amountWidth, 'services-charges-container') },
      {
        headerName: this.actionsTitle,
        field: 'actions',
        cellRenderer: this.actionsRenderer,
        onCellClicked: this.deleteServiceCharge.bind(this),
        width: this.gridsService.widthCalculatorContainerId(this.actionsWidth, 'services-charges-container')
      },
    ];
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    // set widths for columns based on url
    if (this.router.url === '/settings') {
      this.nameWidth = 60;
      this.amountWidth = 25;
      this.actionsWidth = 15;
    }
    else if (this.router.url.split('/')[2] == 'collaboratorView') {
      this.nameWidth = 60;
      this.amountWidth = 20;
      this.actionsWidth = 20;
    }

    this.updateColumnDefs();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
