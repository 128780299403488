import { MathUtils } from './../../utils/math-utils';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { SettingsService } from '@app/services/settings.service';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal.service';

@Component({
    selector: 'app-collaborator-discounts-modal',
    templateUrl: './collaborator-discounts-modal.component.html',
    styleUrls: ['./collaborator-discounts-modal.component.scss']
})
export class CollaboratorDiscountsModalComponent implements OnInit, OnDestroy {

    myForm: FormGroup;
    listen = [];
    isClickedOnce = false;

    id;
    tempId;
    percentage;
    percentageValueString;
    selectedZones = [];
    selectedZonesIds = [];
    collaboratorZones = [];
    availableCollaboratorZones = []; // only zones that have not been assigned
    availableZones = []; // zones that have not been assigned + the current zone's assigned zones
    tempIdCounter = 1;

    collaboratorId;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        private settingsService: SettingsService,
        public modalService: ModalService,
        public router: Router,
        public mathUtils: MathUtils,
        formBuilder: FormBuilder,
    ) {
        this.myForm = formBuilder.group({
            "id": [this.id],
            "tempId": [this.tempId],
            "percentage": [this.percentage],
            "percentage_value_string": [this.percentageValueString],
            // "selectedZones": [this.selectedZones],
            "selectedZonesIds": [this.selectedZonesIds]
        });
        // this.listen.push(this.settingsService.openWeightChargesModalListen().subscribe((data) => {
        //     if (data) {
        //         this.loadDiscount(data);
        //     }

        //     this.openModal();
        // }));
    }

    percentageValueChanged(event) {
        const stringFixedWithRemovedMinus = event.target.value.replace(/-/g, '');;
        if (this.mathUtils.isNumericString(stringFixedWithRemovedMinus)) {
            this.percentage = parseFloat(stringFixedWithRemovedMinus).toFixed(2);
            this.percentage = this.mathUtils.clamp(parseFloat(stringFixedWithRemovedMinus), 1, 100);

        } else {
            this.percentage = 0;
        }

        this.myForm.patchValue({
            "percentage": this.percentage,
            "percentage_value_string": String(this.percentage)
        })
    }

    selectPercentage() {
        (<HTMLInputElement>document.getElementById('percentage-form')).select();
    }

    patchForm() {
        this.percentageValueString = String(parseFloat(this.percentage).toFixed(2));
        this.myForm.patchValue({
            "id": this.id,
            "tempId": this.tempId,
            "percentage": this.percentage,
            "percentage_value_string": this.percentageValueString,
            // "selectedZones": this.selectedZones,
            "selectedZonesIds": this.selectedZonesIds,
        });
        // setTimeout(() => {
        //     M.updateTextFields();
        // }, 100);
    }

    resetForm() {
        this.id = null;
        this.tempId = null;
        this.percentage = 0;
        this.percentageValueString = '';
        this.selectedZonesIds = [];

        this.patchForm();

        setTimeout(() => {
            M.updateTextFields();
        }, 100);
    }

    loadDiscount(data = null) {

        // edit discount
        if (data) {
            this.id = data.id;
            this.tempId = JSON.parse(JSON.stringify(data)).tempId;
            this.percentage = data.percentage;
            this.selectedZones = data.zones;
            this.collaboratorZones = this.modalService.collaboratorZones;
            this.selectedZonesIds = data.selectedZonesIds;

            this.setAvailableZones(data);
        }
        // new discount
        else {
            this.id = null;
            this.tempId = this.tempIdCounter;
            this.percentage = 0;
            this.selectedZones = [];
            this.collaboratorZones = this.modalService.collaboratorZones;
            this.selectedZonesIds = [];

            this.tempIdCounter++;

            this.setAvailableZones(null)
        }

        this.patchForm();

        this.openModal();
    }

    // sets the modal's available zones to be availableZones + this discount's assigned zones
    setAvailableZones(discount) {
        this.availableZones = [...this.availableCollaboratorZones];

        if (discount) {
            if (discount.selectedZonesIds.length) {
                discount.selectedZonesIds.forEach(id => {
                    this.collaboratorZones.forEach(zone => {
                        if (zone.id == id) {
                            this.availableZones.push(zone);
                        }
                    });
                });
            }
        }
    }

    openModal() {
        const modal = document.querySelector('#discount-zones-modal');
        const modalBackground = document.querySelector('#discount-zones-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');
    }

    closeModal() {
        const modal = document.querySelector('#discount-zones-modal');
        const modalBackground = document.querySelector('#discount-zones-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');

        this.resetForm();
    }

    submitDiscount() {
        this.isClickedOnce = false;
        this.modalService.updateDiscountZones(this.myForm.value);
        this.closeModal();
    }

    getTranslations() { }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}