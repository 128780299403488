import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SettingsService } from '@app/services/settings.service';
import { of } from 'rxjs';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
import { Router } from '@angular/router';
import { Globals } from '@app/services/globals';
import { ModalService } from '@app/services/modal.service';
import { take } from 'rxjs/operators';
import { CollaboratorSelectModalComponent } from '@app/modals/collaborator-select-modal/collaborator-select-modal.component';

@Component({
  selector: 'app-region-charges-grid',
  templateUrl: './region-charges-grid.component.html',
  styleUrls: ['./region-charges-grid.component.scss']
})
export class RegionChargesGridComponent implements OnInit, OnDestroy {
  @ViewChild('extraChargesGrid', { static: false }) extraChargesGrid: AgGridAngular;
  @ViewChild(CollaboratorSelectModalComponent, { static: false }) collaboratorSelectModalComponent: CollaboratorSelectModalComponent;

  listen = [];
  chargeRegions = [];
  searchString: String = '';
  colours = [];
  rowId;

  gridApi;
  gridColumnApi;
  domLayout = 'autoHeight';
  regionalChargesDataArray = [];
  rowData: any;
  columnDefs;

  collaboratorId;
  selectedRegion = null;

  amountLabel: string;
  actionsLabel: string;
  currencyLabel: string;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private settingsService: SettingsService,
    private modalService: ModalService,
    public gridsService: GridsService,
    public router: Router,
    public globals: Globals,
  ) {
    this.listen.push(this.settingsService.updateRegionalChargesGridListen().subscribe(() => {
      if (this.router.url.split('/')[1] == 'settings') {
        this.setRegionalChargesGridData();
      }
    }));

    // sets grid data from collaborator form
    this.listen.push(this.modalService.setSpatialCollaboratorListen().subscribe((spatialCategories) => {
      // search labelsForZones and alter the appropriate charge name in every spatial category
      this.setRegionalChargesGridDataWithSpecificData(spatialCategories);
    }));
  }

  setRegionalChargesGridDataWithSpecificData(spatialData) {
    this.regionalChargesDataArray = [];
    if (spatialData.length) {
      spatialData.forEach(data => {
        let labelsForZones = '';
        data.charge_category_groups.forEach(chargeCategoryGroup => {
          if (chargeCategoryGroup.charge_category_group.chargeCategories) {
            chargeCategoryGroup.charge_category_group.chargeCategories.forEach(chargeCategory => {
              if (chargeCategory.name) {
                if (labelsForZones) {
                  labelsForZones += ', ';
                }
                labelsForZones += chargeCategory.name;
              }
            });
          } else if (chargeCategoryGroup.charge_category_group.charge_category_ids) {
            chargeCategoryGroup.charge_category_group.charge_category_ids.forEach(chargeCategoryId => {
              let chargeCategory = this.globals.chargeZonesById[chargeCategoryId];
              if (!chargeCategory) {
                this.modalService.collaboratorZones.forEach(zone => {
                  if (zone.id === chargeCategoryId) {
                    chargeCategory = zone;
                  }
                })
              }
              if (chargeCategory) {
                if (chargeCategory.name) {
                  if (labelsForZones) {
                    labelsForZones += ', ';
                  }
                  labelsForZones += chargeCategory.name;
                }
              }
            });
          }
        });
        let gridObject = {
          id: data.id,
          charge_name: data.name,
          zones: labelsForZones,
          objData: data,
        };
        this.regionalChargesDataArray.push(gridObject);
      });
    } else {
      const noData = { noDataText: 'No data' }
      this.regionalChargesDataArray.push(noData);
    }

    this.rowData = of(this.regionalChargesDataArray);
  }

  setRegionalChargesGridData(collaboratorId = null) {
    // collaborator
    if (collaboratorId) {
      this.collaboratorId = collaboratorId;
      this.http.get('api/v1/spatial-category?pageSize=500&collaboratorId=' + this.collaboratorId).pipe(take(1)).subscribe(response => {
        this.regionalChargesDataArray = [];
        if (response['items']) {
          if (response['items'].length) {
            response['items'].forEach(data => {
              let labelsForZones = '';
              data.charge_category_groups.forEach(chargeCategoryGroup => {
                chargeCategoryGroup.charge_category_group.chargeCategories.forEach(chargeCategory => {
                  if (chargeCategory.name) {
                    if (labelsForZones) {
                      labelsForZones += ', ';
                    }
                    labelsForZones += chargeCategory.name;

                    // this.modalService.updateCollaboratorsChargesForm(chargeCategory);
                  }
                });
              });
              let gridObject = {
                id: data.id,
                charge_name: data.name,
                zones: labelsForZones,
                objData: data,
              };
              this.regionalChargesDataArray.push(gridObject);
            });
          } else {
            const noData = { noDataText: 'No data' }
            this.regionalChargesDataArray.push(noData);
          }
        } else {
          const noData = { noDataText: 'No data' }
          this.regionalChargesDataArray.push(noData);
        }
        this.rowData = of(this.regionalChargesDataArray);
      });
    }

    // settings & new collaborator
    else {
      // this.collaboratorId = collaboratorId;
      this.http.get('api/v1/spatial-category?pageSize=500').pipe(take(1)).subscribe(response => {
        this.regionalChargesDataArray = [];
        if (response['items']) {
          if (response['items'].length) {
            response['items'].forEach(data => {
              let labelsForZones = '';
              data.charge_category_groups.forEach(chargeCategoryGroup => {
                chargeCategoryGroup.charge_category_group.chargeCategories.forEach(chargeCategory => {
                  if (chargeCategory.name) {
                    if (labelsForZones) {
                      labelsForZones += ', ';
                    }
                    labelsForZones += chargeCategory.name;
                  }
                });
              });
              let gridObject = {
                id: data.id,
                charge_name: data.name,
                zones: labelsForZones,
                objData: data,
              };
              this.regionalChargesDataArray.push(gridObject);
            });
            this.settingsService.updateZoneChargesGrid();
          } else {
            const noData = { noDataText: 'No data' }
            this.regionalChargesDataArray.push(noData);
          }
        } else {
          const noData = { noDataText: 'No data' }
          this.regionalChargesDataArray.push(noData);
        }
        this.rowData = of(this.regionalChargesDataArray);
      });
    }
  }

  emptyRegionChargesGridData() {
    this.regionalChargesDataArray = [{ noDataText: 'No data' }];
    this.rowData = of(this.regionalChargesDataArray);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  rowClicked(event) {
    this.rowId = event.data.id;
    const target = event.event.target.closest('div');
    if (event.data && this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN']) {
      if (!event.data.noDataText && !target.classList.contains('dropdown-dots')) {
        this.settingsService.openRegionChargesModal(event.data.objData);
      }
    }
  }

  onFirstDataRendered(params) { }

  newExtraCharge() {
    this.settingsService.openRegionChargesModal(null);
  }

  removeRowFromArray(indexToBeRemoved) {
    if (!isNaN(indexToBeRemoved)) {
      if (this.regionalChargesDataArray[indexToBeRemoved]) {
        this.regionalChargesDataArray.splice(indexToBeRemoved, 1);

        const dataToUpdateGrid = [];
        this.regionalChargesDataArray.forEach(charge => {
          dataToUpdateGrid.push(charge.objData);
        });
        this.setRegionalChargesGridDataWithSpecificData(dataToUpdateGrid);

        this.selectedRegion = null
        const dropdown = document.getElementById('zones-dropdown-container');
        dropdown.style.display = 'none';
      }
    }
  }

  deleteRegion() {
    if (this.selectedRegion) {
      if (this.router.url.split('/')[1] == 'settings') {

        const deleteCharges = {
          'spatialCategoriesIdsToDelete': [this.selectedRegion.id]
        };
        this.http.post('api/v1/delete-charges', JSON.stringify(deleteCharges)).pipe(take(1)).subscribe(() => {
          const dropdown = document.getElementById('regions-dropdown-container');
          dropdown.style.display = 'none';
          this.globals.getSpatialCategories();
          this.setRegionalChargesGridData();
        });

      } else {
        // delete saved region
        if (this.selectedRegion.objData.id) {
          for (let index = 0; index < this.regionalChargesDataArray.length; index++) {
            if (this.regionalChargesDataArray[index].objData.id == this.selectedRegion.id) {
              this.modalService.deleteCollaboratorsRegionForm(this.selectedRegion.objData)
              this.removeRowFromArray(index);
              break;
            }
          }
        }

        // delete unsaved region
        else {
          for (let index = 0; index < this.regionalChargesDataArray.length; index++) {
            if (this.regionalChargesDataArray[index].objData.temp_id == this.selectedRegion.objData.temp_id) {
              this.modalService.deleteCollaboratorsRegionForm(this.selectedRegion.objData);
              this.removeRowFromArray(index);
              break;
            }
          }
        }
      }

      // hide dropdown & empty selectedRegion
      this.selectedRegion = null;
      const dropdown = document.getElementById('regions-dropdown-container');
      dropdown.style.display = 'none';
    }
  }

  copyRegion() {
    this.collaboratorSelectModalComponent.mode = 'copyRegion';
    this.collaboratorSelectModalComponent.spatialId = this.selectedRegion['id'];
    this.collaboratorSelectModalComponent.getCompanyCollaborators();
    this.collaboratorSelectModalComponent.openModal();
  }

  // custom renderer for dropdown actions
  dropdownRenderer(params) {
    const self = this;
    const dots = document.createElement('div');
    dots.classList.add('dropdown-dots');
    dots.innerHTML = '<i class="fas fa-ellipsis-v"></i>';
    const dropdown = document.getElementById('regions-dropdown-container');
    dots.addEventListener('click', function () {
      setTimeout(() => {
        if (!self.selectedRegion) {
          if (!self.globals.isInRoute('settings')) {
            dropdown.style.top = 'calc(' + dots.getBoundingClientRect().bottom + 'px - 64px)';
            dropdown.style.left = 'calc(' + dots.getBoundingClientRect().left + 'px - 14vw)';
          } else {
            dropdown.style.top = 'calc(' + dots.getBoundingClientRect().bottom + 'px - 10px)';
            dropdown.style.left = 'calc(' + dots.getBoundingClientRect().left + 'px - 9vw)';
          }
          dropdown.style.display = 'block';
          self.selectedRegion = params.data;
        } else {
          self.selectedRegion = null
          dropdown.style.display = 'none';
        }
      }, 20);
    });
    return dots;
  }

  getTranslations() {
    let zonesLabel, regionsLabel;

    this.listen.push(this.translate.get('SETTINGS.ZONES').subscribe((res: string) => { zonesLabel = res; }));
    this.listen.push(this.translate.get('SETTINGS.REGIONS').subscribe((res: string) => { regionsLabel = res; }));
    
    this.columnDefs = [
      {
        headerName: regionsLabel,
        field: 'charge_name',
        width: this.gridsService.widthCalculatorContainerId(48, 'region-charges')
      },
      {
        headerName: zonesLabel,
        field: 'zones',
        width: this.gridsService.widthCalculatorContainerId(45, 'region-charges')
      },
      {
        headerName: '', field: '',
        cellClass: 'dropdown-cell',
        cellRenderer: this.dropdownRenderer.bind(this),
        width: this.gridsService.widthCalculatorContainerId(7, 'region-charges')
      }
    ];
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    // load regional charges on settings
    if (this.router.url.split('/')[1] == 'settings') {
      this.setRegionalChargesGridData();
    }
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
