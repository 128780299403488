import { GenericService } from '@app/services/generic.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from './globals';
import { Router } from '@angular/router';
import { StopPointUtils } from '@app/utils/stop-point-utils';

@Injectable({
    providedIn: 'root'
})
export class StopPointService {
    stopPointGeneralUrl = 'api/v1/stop-points';
    newStopPointCollaboratorUrl = 'api/v1/partner-new-stop-points';
    stopPointCollaboratorUrl = 'api/v1/partner-stop-points/';
    stopPointsUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points';
    getStopPointUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points/';

    constructor(
        private http: HttpClient,
        public globals: Globals,
        public router: Router,
        public genericService: GenericService,
        public stopPointUtils: StopPointUtils,
    ) { }

    saveStopPoint(stopPointData, stopPointId, projectProblemId, inWarehouse) {
        let requestObservable: Observable<any>;

        if (stopPointData) {
            if (stopPointData.stopPoint) {
                if (this.router.url.includes('newShipmentsView') || (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled)) {
                    if (stopPointId) {
                        const editUrl = this.newStopPointCollaboratorUrl + '/' + stopPointId;
                        return this.http.put(editUrl, stopPointData);
                    } else {
                        // [food mode]: allow request for pickup when form is submitted successfully (only for creation - not edit)
                        if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
                            this.genericService.canRequestForPickup = true;
                        }
                        return this.http.post(this.newStopPointCollaboratorUrl, stopPointData);
                    }
                } else if (inWarehouse || !projectProblemId) {
                    const multipleStopPointsData = {
                        stopPoints: [
                            stopPointData
                        ]
                    };
                    if (stopPointId) {
                        return this.http.put(this.stopPointGeneralUrl, multipleStopPointsData);
                    } else {
                        return this.http.post(this.stopPointGeneralUrl, multipleStopPointsData);
                    }
                } else {
                    const stopPointsUrl = this.stopPointsUrl.replace('PROJECT_PROBLEM_ID', projectProblemId);
                    if (stopPointId) {
                        const editUrl = stopPointsUrl + '/' + stopPointId;
                        return this.http.put(editUrl, stopPointData);
                    } else {
                        return this.http.post(stopPointsUrl, stopPointData);
                    }
                }
            } else {
                console.error('There are no data to save this stop point');
            }
        } else {
            console.error('There are no data to save this stop point');
        }

        return requestObservable;
    }

    saveStopPointWithPickup(stopPointsData, stopPointId, projectProblemId, inWarehouse) {
        let requestObservable: Observable<any>;

        if (stopPointsData.stopPoints) {
            if (stopPointsData.stopPoints.length) {
                let isDeliveryToStation = false;
                let isPickupFromStation = false;
                stopPointsData.stopPoints.forEach(stopPoint => {
                    if (stopPoint.stopPoint.voucher) {
                        isDeliveryToStation = stopPoint.stopPoint.voucher.options.delivery_to_station
                        isPickupFromStation = stopPoint.stopPoint.voucher.options.pickup_from_station
                    };
                });
                const bypassProjectProblem = !stopPointId && isDeliveryToStation && isPickupFromStation;
                if (this.router.url.includes('newShipmentsView') || (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled)) {

                    // mutate data
                    stopPointsData['company_partner'] = stopPointsData.stopPoints[0]['company_partner'];

                    if (stopPointId) {
                        const editUrl = this.newStopPointCollaboratorUrl;
                        return this.http.put(editUrl, stopPointsData);
                    } else {
                        // [food mode]: allow request for pickup when form is submitted successfully (only for creation - not edit)
                        if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
                            this.genericService.canRequestForPickup = true;
                        }
                        return this.http.post(this.newStopPointCollaboratorUrl, stopPointsData);
                    }
                } else if (inWarehouse || !projectProblemId || bypassProjectProblem) {
                    if (stopPointId) {
                        return this.http.put(this.stopPointGeneralUrl, stopPointsData);
                    } else {
                        return this.http.post(this.stopPointGeneralUrl, stopPointsData);
                    }
                }
                // handle update
                else if (stopPointId) {
                    return this.http.put(this.stopPointGeneralUrl, stopPointsData);
                } else {
                    const stopPointsUrl = this.stopPointsUrl.replace('PROJECT_PROBLEM_ID', projectProblemId);
                    if (stopPointId) {
                        return this.http.put(stopPointsUrl, stopPointsData);
                    } else {
                        return this.http.post(stopPointsUrl, stopPointsData);
                    }
                }
            } else {
                console.error('There are no data to save this stop point');
            }
        } else {
            console.error('There are no data to save this stop point');
        }

        return requestObservable;
    }

    getStopPoint(stopPointId, projectProblemId) {

        if (
            this.globals.isInRoute('newShipmentsView') ||
            this.globals.isInRoute('partnerView') ||
            this.globals.isInRoute('partners') ||
            this.globals.isInRoute('collaboratorPartnerView') ||
            this.globals.collaboratorModeEnabled
        ) {
            return this.http.get(this.stopPointCollaboratorUrl + stopPointId);
        }
        else if (projectProblemId) {
            const stopPointsUrl = this.getStopPointUrl.replace('PROJECT_PROBLEM_ID', projectProblemId);
            return this.http.get(stopPointsUrl + stopPointId);
        } else {
            return this.http.get(this.stopPointGeneralUrl + '/' + stopPointId);
        }
    }

}
