import { Observable } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SettingsService } from '@app/services/settings.service';
import { RegionChargesFormComponent } from '@app/forms/region-charges-form/region-charges-form.component';
import { Router } from '@angular/router';
import { Globals } from '@app/services/globals';
import { take } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { GenericService } from '@app/services/generic.service';

@Component({
  selector: 'app-region-charges-modal',
  templateUrl: './region-charges-modal.component.html',
  styleUrls: ['./region-charges-modal.component.scss']
})
export class RegionChargesModalComponent implements OnInit, OnDestroy {

  @ViewChild(RegionChargesFormComponent, { static: false }) regionChargesFormComponent: RegionChargesFormComponent;
  listen = [];

  customerId = null;
  dataLoading = false;

  modalOpen = false;

  overlapErrorMessage: String;

  constructor(
    public translate: TranslateService,
    private settingsService: SettingsService,
    public router: Router,
    public globals: Globals,
    public genericService: GenericService,
  ) {
    this.listen.push(this.settingsService.openRegionChargesModalListen().subscribe(data => {
      if (this.modalOpen === false) {
        this.loadAndOpenRegionModal(data);
      }
    }));
    this.listen.push(this.settingsService.closeRegionChargesModalListen().subscribe((response: any) => {
      this.closeModal();
    }));
  }

  loadAndOpenRegionModal(data) {
    this.openModal();
    if (data) {
      setTimeout(() => {
        this.regionChargesFormComponent.loadSpatialCharge(data);
      }, 100);
    }
  }

  dataLoadingListen(event) {
    if (event === 'true') {
      this.dataLoading = true;
    } else if (event === 'false') {
      this.dataLoading = false;
    }
  }

  openModal() {
    this.genericService.comm100ZIndexFix();
    this.modalOpen = true;
    setTimeout(() => {
      const modal = document.querySelector('.region-charges-modal');
      const modalBackground = document.querySelector('.region-charges-modal-background');
      modal.classList.add('open');
      modal.classList.remove('closed');
      modalBackground.classList.remove('hidden');
    }, 100);
  }

  closeModal() {
    const modal = document.querySelector('.region-charges-modal');
    const modalBackground = document.querySelector('.region-charges-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
    this.regionChargesFormComponent.resetForm();
    setTimeout(() => {
      this.modalOpen = false;
    }, 1000);
  }

  submitSpatialCharge() {
    const observable = this.regionChargesFormComponent.submitForm();
    if (observable instanceof Observable) {
      observable.pipe(take(1)).subscribe(response => {
        this.globals.getSpatialCategories();
        this.closeModal();
        this.settingsService.updateRegionalChargesGrid();
      },
        error => {
          if (error.error.message == 'Invalid Geofence') {
            alert(this.overlapErrorMessage);
          }
        }
      );
    }
  }

  getTranslations() {
    this.listen.push(this.translate.get('WARNINGS.REGION_POLYLINE_OVERLAP').subscribe((res: string) => { this.overlapErrorMessage = res; }));
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }
}
