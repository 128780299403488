import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SettingsService } from '@app/services/settings.service';
import { of } from 'rxjs';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
import { Router } from '@angular/router';
import { Globals } from '@app/services/globals';
import { ModalService } from '@app/services/modal.service';
import { CollaboratorDiscountsModalComponent } from '@app/modals/collaborator-discounts-modal/collaborator-discounts-modal.component';

@Component({
  selector: 'app-collaborator-discounts-grid',
  templateUrl: './collaborator-discounts-grid.component.html',
  styleUrls: ['./collaborator-discounts-grid.component.scss']
})
export class CollaboratorDiscountsGridComponent implements OnInit, OnDestroy {
  @ViewChild('extraChargesGrid', { static: false }) extraChargesGrid: AgGridAngular;
  @ViewChild(CollaboratorDiscountsModalComponent, { static: false }) collaboratorDiscountsModalComponent: CollaboratorDiscountsModalComponent;

  listen = [];
  chargeRegions = [];
  searchString: String = '';
  colours = [];

  gridApi;
  gridColumnApi;
  domLayout = 'autoHeight';
  discountsDataArray = [];
  rowData: any;
  columnDefs;

  selectedDiscount;
  collaboratorId;
  selectedRegion = null;
  tempIdCounter = 1;

  actionsLabel;

  constructor(
    public translate: TranslateService,
    private settingsService: SettingsService,
    private modalService: ModalService,
    public gridsService: GridsService,
    public router: Router,
    public globals: Globals,
  ) {
    // add/edit discount w/ zones to grid on modal submit
    this.listen.push(this.modalService.updateDiscountZonesListen().subscribe(discount => {
      if (this.discountsDataArray[0].noDataText) {
        this.discountsDataArray = [];
      }
      this.updateAvailableZones();
      this.updateDiscountsGridData(discount);
    }));

    // on zone submit, update the discount that contains that zone
    this.listen.push(this.modalService.updateCollaboratorsChargesFormListen().subscribe(data => {
      if (!this.discountsDataArray[0].noDataText) {
        this.discountsDataArray.forEach(discount => {
          if (discount.selectedZonesIds.length) {
            discount.selectedZonesIds.forEach(id => {
              if (data.id == id) {
                this.updateDiscountsGridData(discount);
              }
            });
          }
        });
      }
    }));

    // on zone delete, update the discount that contains that zone with that zone removed
    this.listen.push(this.modalService.deleteZoneInCollaboratorListen().subscribe(data => {
      if (!this.discountsDataArray[0].noDataText) {
        this.discountsDataArray.forEach((discount, index) => {
          if (discount.selectedZonesIds.length) {
            discount.selectedZonesIds.forEach((id, zoneIndex) => {
              if (data == id) {
                this.discountsDataArray[index].selectedZonesIds.splice(zoneIndex, 1);
                this.updateDiscountsGridData(discount);
                return;
              }
            });
          }
        });
      }
    }));
  }

  setDiscountsGridData(data) {
    this.discountsDataArray = [];
    const discounts = data.discounts;
    const chargeCategories = data.chargeCategories;

    if (data) {
      if (data.discounts.length) {
        discounts.forEach(discount => {
          const percentageLabel = String(discount.discount.percentage + '%');

          // match zones with this collaborator's charge categories via their ids & create label for this discount's zones
          let labelsForZone = '';
          let selectedZonesIds = [];
          chargeCategories.forEach(zone => {
            discount.discount.charge_category_ids.forEach(id => {
              if (id == zone.id) {
                selectedZonesIds.push(zone.id);

                if (labelsForZone == '') {
                  labelsForZone = zone.name;
                } else {
                  labelsForZone += ', ' + zone.name;
                }
              }
            });
          });

          let gridObject = {
            id: discount.discount.id,
            percentageLabel: percentageLabel,
            percentage: discount.discount.percentage,
            selectedZonesIds: selectedZonesIds,
            zonesLabels: labelsForZone,
            objData: data
          };
          this.discountsDataArray.push(gridObject);
        });
      }
      else {
        this.emptyDiscountsGridData();
      }
    }

    this.updateAvailableZones();
    this.rowData = of(this.discountsDataArray);

  }

  // update the data of a discount or add a new one
  updateDiscountsGridData(insertedDiscount) {

    let discountsDataArray = [...this.discountsDataArray];

    if (insertedDiscount) {
      const percentageLabel = String(insertedDiscount.percentage + '%');

      let labelsForZone = '';
      this.modalService.collaboratorZones.forEach(zone => {
        insertedDiscount.selectedZonesIds.forEach(id => {
          if (id) {
            if (id == zone.id) {
              if (labelsForZone == '') {
                labelsForZone = zone.name;
              } else {
                labelsForZone += ', ' + zone.name;
              }
            }
          }
        })
      });

      let gridObject = {
        id: insertedDiscount.id,
        tempId: insertedDiscount.tempId ? insertedDiscount.tempId : null,
        percentageLabel: percentageLabel,
        percentage: insertedDiscount.percentage,
        selectedZonesIds: insertedDiscount.selectedZonesIds,
        zonesLabels: labelsForZone,
        objData: insertedDiscount
      };

      // edit discount
      let isDiscountFound = false;
      discountsDataArray.forEach((discount, index) => {

        // edit saved
        if (discount.id) {
          if (insertedDiscount.id == discount.id) {
            discountsDataArray[index] = gridObject;
            isDiscountFound = true;
          }
        }
        // edit unsaved
        else {
          if (insertedDiscount.tempId == discount.tempId) {
            discountsDataArray[index] = gridObject;
            isDiscountFound = true;
          }
        }
      });

      // add new discount
      if (!isDiscountFound) {
        gridObject['tempId'] = this.tempIdCounter;
        this.tempIdCounter++;
        discountsDataArray.push(gridObject);
      }
    }

    this.discountsDataArray = discountsDataArray;
    // this.updateAvailableZones();
    this.rowData = of(this.discountsDataArray);
  }

  deleteDiscount() {
    let discountsDataArray = [...this.discountsDataArray];
    discountsDataArray.forEach((discount, index) => {
      if (this.selectedDiscount.id) {
        if (this.selectedDiscount.id == discount.id) {
          discountsDataArray.splice(index, 1);
        }
      } else {
        if (this.selectedDiscount.tempId == discount.tempId) {
          discountsDataArray.splice(index, 1);
        }
      }
    });

    // set empty data if the array has no length
    if (!discountsDataArray.length) {
      discountsDataArray = [{ noDataText: 'No data' }];
    }

    this.discountsDataArray = discountsDataArray;
    this.rowData = of(this.discountsDataArray);

    this.updateAvailableZones();

    // reset dropdown
    this.selectedDiscount = null;
    const dropdown = document.getElementById('discounts-dropdown-container');
    dropdown.style.display = 'none';
  }

  updateAvailableZones() {
    setTimeout(() => {
      let availableZones = [...this.modalService.collaboratorZones];

      // check all zones for null id & if so, set hash as id
      availableZones.forEach((zone, index) => {
        if (!zone.id) {
          availableZones[index].id = zone.requestItemIdentifierHash;
        }
      });

      this.discountsDataArray.forEach(discount => {
        if (discount.selectedZonesIds) {
          if (discount.selectedZonesIds.length) {
            discount.selectedZonesIds.forEach(id => {
              availableZones.forEach((zone, index) => {
                if (zone) {
                  if (zone.id == id) {
                    availableZones.splice(index, 1);
                  }
                }
              })
            });
          }
        }
      });
      this.collaboratorDiscountsModalComponent.availableCollaboratorZones = availableZones;
    }, 100);
  }

  emptyDiscountsGridData() {
    this.discountsDataArray = [{ noDataText: 'No data' }];
    this.rowData = of(this.discountsDataArray);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  rowClicked(event) {
    const target = event.event.target.closest('div');
    if (event.data) {
      if (!event.data.noDataText && !target.classList.contains('dropdown-dots')) {
        this.collaboratorDiscountsModalComponent.openModal();
        this.collaboratorDiscountsModalComponent.loadDiscount(event.data);
      }
      // get object data for dropdown options (delete)
      else if (target.classList.contains('dropdown-dots')) {
        this.selectedDiscount = event.data;
      }
    }
  }

  onFirstDataRendered(params) { }

  newDiscount() {
    this.collaboratorDiscountsModalComponent.loadDiscount(null);
  }

  removeRowFromArray(indexToBeRemoved) {
    if (!isNaN(indexToBeRemoved)) {
      if (this.discountsDataArray[indexToBeRemoved]) {
        this.discountsDataArray.splice(indexToBeRemoved, 1);

        const dataToUpdateGrid = [];
        this.discountsDataArray.forEach(charge => {
          dataToUpdateGrid.push(charge.objData);
        });
        this.setDiscountsGridData(dataToUpdateGrid);

        this.selectedRegion = null
        const dropdown = document.getElementById('discounts-dropdown-container');
        dropdown.style.display = 'none';
      }
    }
  }

  // custom renderer for dropdown actions
  dropdownRenderer(params) {
    const self = this;
    const dots = document.createElement('div');
    dots.classList.add('dropdown-dots');
    dots.innerHTML = '<i class="fas fa-ellipsis-v"></i>';
    const dropdown = document.getElementById('discounts-dropdown-container');
    dots.addEventListener('click', function () {
      setTimeout(() => {
        if (!self.selectedRegion) {
          if (!self.globals.isInRoute('settings')) {
            dropdown.style.top = 'calc(' + dots.getBoundingClientRect().bottom + 'px - 64px)';
            dropdown.style.left = 'calc(' + dots.getBoundingClientRect().left + 'px - 14vw)';
          } else {
            dropdown.style.top = 'calc(' + dots.getBoundingClientRect().bottom + 'px - 10px)';
            dropdown.style.left = 'calc(' + dots.getBoundingClientRect().left + 'px - 9vw)';
          }
          dropdown.style.display = 'block';
          self.selectedRegion = params.data;
        } else {
          self.selectedRegion = null
          dropdown.style.display = 'none';
        }
      }, 20);
    });
    return dots;
  }

  getTranslations() {
    let percentageLabel, zonesLabel;

    this.listen.push(this.translate.get('CUSTOMER_COLLABORATORS.DISCOUNT').subscribe((res: string) => { percentageLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_COLLABORATORS.ZONES').subscribe((res: string) => { zonesLabel = res; }));

    this.columnDefs = [
      {
        headerName: percentageLabel,
        field: 'percentageLabel',
        width: this.gridsService.widthCalculatorContainerId(48, 'discount-zones-container')
      },
      {
        headerName: zonesLabel,
        field: 'zonesLabels',
        width: this.gridsService.widthCalculatorContainerId(45, 'discount-zones-container')
      },
      {
        headerName: '', field: '',
        cellClass: 'dropdown-cell',
        cellRenderer: this.dropdownRenderer.bind(this),
        width: this.gridsService.widthCalculatorContainerId(7, 'discount-zones-container')
      }
    ];
  }

  ngOnInit() {
    this.emptyDiscountsGridData();

    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }
}
