import { Component, OnInit, OnDestroy, Input, Injector, AfterViewInit, ViewChild, Renderer2, ElementRef, AfterViewChecked, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { Observable, Subscription, finalize, mergeWith, of, switchMap, take, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DashboardReportViewModelService, LmDashboardReportQueryType, LmReportsQueryFragmentType } from './dashboard-report-viewmodel.service';
import { ILmCSemiCircleChartWidgetTab, ILmChartWidgetTab } from '@app/model/widget';
import { SHIPMENTS as sc, PRODUCTIVITY as pc, VERTICAL as vc, VERTICAL_OPTIONS as vco, DOUGHNUT as dc, DRIVERS as drc, BRANCHES as brc, SEMICIRCLE as smc, GENERAL_PERFORMANCE, DELIVERIES_OVERVIEW, EXPERIENCE_OVERVIEW, PRODUCTIVITY_OVERVIEW, SHIPMENTS_OVERVIEW} from './dashboard-report-dummy-data';
import { DELIVERIES as deliveriesTabs} from './dashboard-report-dummy-data';
import { LmProgressCardConfig } from '@app/shared/structure/progress-card/progress-card.component';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { Globals } from '@app/services/globals';
import { SelectMapperService } from '@app/core/services/select-mapper.service';
import { LmNotificationService } from '@app/core/services/notification.service';
import { LmCalendarComponent } from '@app/shared/containers/calendar-dialog/calendar-dialog.component';
import { LmBarChartWidgetComponent } from '@app/shared/structure/widgets/barchart-widget.component';
import { LmChartWidgetComponent } from '@app/shared/structure/widgets/widget-base';
import { DashboardActivityComponent } from '@app/dashboard/dashboard-views/dashboard-activity/dashboard-activity.component';
import { ActivityUtils } from '@app/utils/activity-utils';
import { HttpClient } from '@angular/common/http';
import { DashboardProjectsLiveComponent } from '@app/dashboard/dashboard-views/dashboard-projects-live/dashboard-projects-live.component';
import { CalendarModalComponent } from '@app/modals/calendar-modal/calendar-modal.component';
import { ProjectProblemReportModalComponent } from '@app/modals/project-problem-report-modal/project-problem-report-modal.component';
import { GenericService } from '@app/services/generic.service';

@UntilDestroy()
@Component({
    selector: 'dashboard-report',
    templateUrl: './dashboard-report.component.html',
    providers: [DashboardReportViewModelService] 
})
export class DashboardReportComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChild('shipments') shipments:LmBarChartWidgetComponent;
  @ViewChild('productivity') productivity:LmBarChartWidgetComponent;
  @ViewChild('drivers') drivers:LmBarChartWidgetComponent;
  @ViewChild('branches') branches:LmBarChartWidgetComponent;

  @ViewChild(DashboardActivityComponent, { static: false }) dashboardActivityComponent: DashboardActivityComponent;
  @ViewChild(DashboardProjectsLiveComponent, { static: false }) dashboardProjectsLiveComponent: DashboardProjectsLiveComponent;
  @ViewChild(CalendarModalComponent, { static: false }) calendarModalComponent: CalendarModalComponent; // new pp
  @ViewChild(ProjectProblemReportModalComponent, { static: false }) projectProblemReportModalComponent: ProjectProblemReportModalComponent;
  calendarComponent = LmCalendarComponent;
  doughnut: ChartData | any;
  cancelation: ChartData | any;
  deliveries: ChartData | any;
  performance: any;

  verticalOptions: ChartOptions;
  verticalHeight: number;
  shipmentProgressOptions: LmProgressCardConfig;
  productivityProgressOptions: LmProgressCardConfig;
  experienceProgressOptions: LmProgressCardConfig;
  deliveriesProgressOptions: LmProgressCardConfig;

  activeProjects;
  projectsArrayForSelect;
  
  // used in pagination for activity log
  isActivityLogsLoading;
  lastObtainedActivityId;
  hasLoadedAllActivityPages;
  
  periodFragments: LmButtonConfig[];
  fragment: string;
  liveNowFilterQuery:string;

  cancelData;
  cancelOptions;
  cancelPlugins;
  
  filterLiveNow$: (e) => Observable<any[]>;
  request$: () => Subscription;
  clearProjects$: () => Subscription;
  fragmentRequest$: (...args) => Subscription;
  promptCalendar: (...args) => void;
  driversRequest$: (...args) => Observable<any>;
  branchesRequest$: (...args) => Observable<any>;

  listen = [];

  constructor(
    private genericService: GenericService,
    private notificationSvc: LmNotificationService, 
    public VM: DashboardReportViewModelService, 
    public globals: Globals, 
    private _injector: Injector,
    public selectMapperSvc: SelectMapperService,
    private activityUtils: ActivityUtils,
    private http: HttpClient,
    private _cdRef: ChangeDetectorRef
  ){
    this.doughnut = {...dc}
    this.performance = GENERAL_PERFORMANCE
    this.verticalOptions = {...vco};

    this.shipmentProgressOptions = SHIPMENTS_OVERVIEW
    this.productivityProgressOptions = PRODUCTIVITY_OVERVIEW
    this.experienceProgressOptions = EXPERIENCE_OVERVIEW
    this.deliveriesProgressOptions = DELIVERIES_OVERVIEW

    this.activeProjects = [];
    this.projectsArrayForSelect = this.globals.projectsArrayForSelect;

    this.listen.push(this.genericService.listenOpenProjectProblemReportModal().subscribe(data => {
        this.http.get(`api/internal/v2/drivers?projectProblemId=${data.projectProblemId}`).subscribe(res => {
            const driverIds = [];
            res['items'].forEach(currDriver => {
                driverIds.push(currDriver.driver.id);
            });
            this.projectProblemReportModalComponent.loadAndOpen(driverIds, data.departureTime, data.projectProblemId, data.projectName);
        });
    }));

    this.periodFragments = 
      ['day', 'week', 'month','year', 'custom'].map((period, i) => ({
          label: period, 
          fragment: period,
          activeColor:'#00aeba', 
          ...(period==='week' && {active: true}),
          ...(period==='custom' && {command:_=>this.promptCalendar(this.periodFragments[i])}),
          ...(period!=='custom' && {command:_=>this.fragmentRequest$(this.periodFragments[i])})
      }));

       // this.periodFragments.forEach(period => period.label = 
      //  this.VM.words['DASHBOARD'][period.label.toUpperCase()] ?? period.label);
      // this.VM.translate$().subscribe();
    this.filterLiveNow$ = (e) => {
      this.dashboardProjectsLiveComponent.filterGrid(e);
      return of(null)
    }

    this.fragmentRequest$ = (period) => {
      this.fragment = undefined;
      this.periodFragments.map(_period => (_period.active = _period.fragment === period.fragment));
      return this.VM.fragmentRequest$(period).pipe(untilDestroyed(this)).subscribe();
    }

    this.request$ = () => {
      this.dashboardProjectsLiveComponent?.initGrid(VM.query.projectId);
      return this.VM.getAll$(this.VM.query).pipe(untilDestroyed(this)).subscribe();
    }

    this.driversRequest$ = (_query) =>{
      const {currentPage, pageSize, tabChartType} = _query;
      this.VM.driversQuery = {page:currentPage, pageSize: pageSize, tabChartType: tabChartType}
      return this.VM.getPagedDrivers({...this.VM.driversQuery, ...this.VM.query})
    }

    this.branchesRequest$ = (_query) =>{
      const {currentPage, pageSize, tabChartType} = _query;
      this.VM.branchesQuery = {page:currentPage, pageSize: pageSize, tabChartType: tabChartType}
      return this.VM.getPagedBranches({...this.VM.branchesQuery, ...this.VM.query})
    }

    this.clearProjects$ = () => {
      this.VM.query.projectId = [];
      return this.request$();                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
    }

    this.promptCalendar = (period) => {
      this.periodFragments.map(_period => (_period.active = _period.fragment === period.fragment));
      const {Period, query} = this.VM;

      this.notificationSvc.showDialog<boolean>({   
        componentType: LmCalendarComponent,   
        injector: this._injector, 
        data: {period:Period, query:query} 
      })
      .pipe(
        tap((res:any) => (res && (this.VM.query = res))),
        tap(_ => this.fragment = `${Period.startDate} - ${Period.endDate}`),
        switchMap(res =>  of((res && this.request$()) ?? res))
      )
      .subscribe();
    }

    this.request$()
  }  

  checkForNextActivityPage() {
    const elem = document.getElementById('activity-container');
    if (elem.scrollHeight / 1.2 < elem.scrollTop + elem.clientHeight && !this.isActivityLogsLoading && !this.hasLoadedAllActivityPages) {
        this.setActivityLog(); // pages are fetched via lastObtainedId
    }
  }

  setActivityLog() {
      this.isActivityLogsLoading = true;
      this.VM.getActivity({lastObtainedId: this.lastObtainedActivityId ?? null}).pipe(untilDestroyed(this)).subscribe(logsRes => {
        // setTimeout(() => {
            this.isActivityLogsLoading = false;

            if (logsRes?.activityLogs) {
              let activities = [];
              this.lastObtainedActivityId = null;
              if (logsRes.activityLogs.length) {
                const activityLogsCount = logsRes.activityLogs.length;
                this.lastObtainedActivityId = logsRes.activityLogs[activityLogsCount-1].id;
                activities = this.activityUtils.getActivitiesFormatted(logsRes);
              }
              // if an empty array is returned, then determine that everything has been loaded
              else this.hasLoadedAllActivityPages = true;
              
              activities = [...activities];
              this.dashboardActivityComponent.activities = this.dashboardActivityComponent.activities.concat(activities);
              //  REMOVE ASAP - CDR
              this.dashboardActivityComponent.changeDetectorRef.detectChanges(); // (on-push specific) force-update dom
          }
            // load activities avatars
            this.loadActivitiesAvatarsAsync();
        // }, 500);
      });
  }


  //αυτο πρεπει δυστυχως να ξηλωθει ολο
  loadActivitiesAvatarsAsync() {
      const activities = this.dashboardActivityComponent.activities;
      const hashesAlreadyGot = [];

      if (activities.length) {
          // if an imageHash exists, perform a request to the appropriate endpoint for the avatar based on the activity type
          activities.forEach((activity) => {
              if (activity.imageHash) {
                  const url = this.activityUtils.getAvatarUrl(activity);

                  if (!hashesAlreadyGot.includes(activity.imageHash)) {
                      hashesAlreadyGot.push(activity.imageHash);
                      // perform request
                      this.http.get(url).subscribe(image => {
                          this.showAvatar(activity.imageHash, image['base64'])
                      });
                  }
              }
          });

      }
  }

  showAvatar(hash, base64) { 
      const activities = this.dashboardActivityComponent.activities;
      // !!activities.length καλυτερα
      if (activities.length) {
          activities.forEach((activity, index) => {
              if (activity.imageHash == hash) {
                  this.dashboardActivityComponent.activities[index]['avatar'] = base64;
              }
          });
      }
      this.dashboardActivityComponent.activities = [...activities];
      this.dashboardActivityComponent.changeDetectorRef.detectChanges();
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.VM.cancelations.subscribe((res:any) =>{
      if (res.d) this.cancelation = res.d;
      if (res.o) this.verticalOptions = res.o;
      if (res.h) this.verticalHeight = res.h
    });
    this.VM.deliveries.subscribe((res:any) =>{
      this.deliveries = {...res.data};
    });
    this.VM.update.subscribe(res => {
      this[res].setHeader(this[res].activeTab);
      this[res].setChart(this[res].activeTab);
      this[res].setPagination(this[res].activeTab);
      this[res].chart.refresh(this[res].activeTab); 
      this[res].widgetHeader.tabActiveColor = this[res].activeTab.btn.activeColor;
    });

    if (this.globals.companyLogoHash || (this.globals.collaboratorModeEnabled && this.globals.portalSettings['live_tracking_image_hash'])) {
      this.VM.getLogo(this.globals.companyLogoHash);
    }
    this.setActivityLog();
  }

  newProjectProblem() {
    this.calendarModalComponent.toggleModal();
    this.calendarModalComponent.initPickers();
  }

  ngOnInit() {    
  }

  ngOnDestroy() {
    this.VM.shipments.complete();
    this.VM.productivity.complete();
    this.VM.cancelations.complete();
    this.VM.deliveries.complete();
    this.VM.drivers.complete();
    this.VM.branches.complete();
    this.VM.update.complete();

    this.listen.forEach(element => {
        element.unsubscribe();
    });
  }

}
