import { Injectable } from '@angular/core';
import { Globals } from '@app/services/globals';
import { TranslateService } from '@ngx-translate/core';
import { FulfillmentUtils } from './fulfillment-event-utils';

@Injectable({
    providedIn: 'root'
})
export class StopPointUtils {
    receiptData = {};
    listen = [];
    currency;

    constructor(
        public globals: Globals,
        public translate: TranslateService,
        private fulfillmentUtils: FulfillmentUtils,
    ) { }

    getProjectProblemsObject(projectProblemsArray) {
        const projectProblemsObject = {};
        projectProblemsArray.forEach(projectProblem => {
            projectProblemsObject[projectProblem.id] = projectProblem;
        });
        return projectProblemsObject;
    }

    getAllStopPointsObject(stopPointsArray, relatedStopPointsArray) {
        const stopPointsObject = {}
        relatedStopPointsArray.forEach(relatedStopPoint => {
            stopPointsObject[relatedStopPoint.id] = relatedStopPoint;
        });
        stopPointsArray.forEach(stopPoint => {
            stopPointsObject[stopPoint.id] = stopPoint;
        });
        return stopPointsObject;
    }

    printVouchers(stopPointsArray, async = false) {
        localStorage.setItem('vouchersToPrint', JSON.stringify(stopPointsArray));
        // const stopPointIdsWithImageVoucher = this.getStopPointIdsWithImageVoucher(stopPointsArray);

        // if (stopPointIdsWithImageVoucher.length) {
        //     localStorage.setItem('stopPointIdsWithImageVoucher', JSON.stringify(stopPointIdsWithImageVoucher));
        //     if (async) {
        //         document.getElementById('print-button-fix').click();
        //     } else {
        //         // this.openBothPrintTabs();
        //         const urls = [
        //             window.location.origin + '/printExtraVouchers',
        //             window.location.origin + '/printVouchers'
        //         ];
        //         [0, 1].forEach(i => {
        //             window.open(urls[i], String(i));
        //         });
        //     }
        // } else {
        // localStorage.removeItem('stopPointIdsWithImageVoucher');
        if (async) {
            document.getElementById('print-button-fix').click();
        } else {
            window.open(window.location.origin + '/printVouchers', '_blank').focus();
        }
        // }
    }

    printImageVouchers(stopPointsArray, async = false) {
        const stopPointIdsWithImageVoucher = this.getStopPointIdsWithImageVoucher(stopPointsArray);
        if (stopPointIdsWithImageVoucher.length) {
            localStorage.setItem('stopPointIdsWithImageVoucher', JSON.stringify(stopPointIdsWithImageVoucher));
            if (async) {
                document.getElementById('print-image-button-fix').click();
            } else {
                window.open(window.location.origin + '/printExtraVouchers', '_blank').focus();
            }
        }
    }

    openPrintTab(forUps = false) {
        if (forUps) {
            const stopPointIdsWithImageVoucherString = localStorage.getItem('stopPointIdsWithImageVoucher');
            if (stopPointIdsWithImageVoucherString) {
                const stopPointIdsWithImageVoucher = JSON.parse(stopPointIdsWithImageVoucherString);
                if (stopPointIdsWithImageVoucher.length) {
                    this.openBothPrintTabs();
                } else {
                    window.open(window.location.origin + '/printVouchers', '_blank').focus();
                }
            }
        } else {
            window.open(window.location.origin + '/printVouchers', '_blank').focus();
        }
    }

    openBothPrintTabs() {
        const urls = [
            window.location.origin + '/printExtraVouchers',
            window.location.origin + '/printVouchers'
        ];
        [0, 1].forEach(i => {
            window.open(urls[i], String(i));
        });
    }

    printSingleImageVoucher(id) {
        localStorage.setItem('stopPointIdsWithImageVoucher', JSON.stringify([id]));
        window.open(window.location.origin + '/printExtraVouchers', '_blank').focus();
    }

    getStopPointIdsWithImageVoucher(stopPoints) {
        const stopPointIds = [];
        stopPoints.forEach(stopPoint => {
            if (stopPoint.stopPoint['voucher']) {
                if (stopPoint.stopPoint['voucher']['company_voucher_X_systemic_courier']) {
                    if (stopPoint.stopPoint['voucher']['company_voucher_X_systemic_courier']['systemic_courier_name_const']) {
                        if (!stopPointIds.includes(stopPoint.stopPoint['voucher']['id'])) {
                            stopPointIds.push(stopPoint.stopPoint['voucher']['id']);
                        }
                    }
                }
            }
        });
        return stopPointIds;
    }

    // barcode generally has higher priority than voucher hash, unless specified otherwise
    getBarcodeOrVoucherHash(stopPoint) {
        let barcodeValue = '-';
        if (stopPoint.barcode) {
            barcodeValue = stopPoint.barcode;
        } else if (stopPoint.voucher) {
            barcodeValue = stopPoint.voucher.hash;
        }
        return barcodeValue;
    }

    stopPointStatusRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="voucher-status-container">' + '<div class="single-cell standard-width ' + params.getValue().colorClass + '">' + params.getValue().label + '</div>' + '</div>';
        }
        return columnObject;
    }

    getStopPointVolumeWeightColumnData(stopPointData) {
        let count = 1;

        if (stopPointData.mass_and_dimensions) {
            count = 0;
            stopPointData.mass_and_dimensions.forEach(item => {
                count += Number(item.count);
            });
        } else {
            count = stopPointData.package_count;
        }

        const volumeWeightColumnData = {
            load: stopPointData.volume,
            weight: stopPointData.weight,
            serviceType: stopPointData.service_type,
            icon: '',
            count: count,
        }

        switch (volumeWeightColumnData.serviceType) {
            case (this.globals.stopPointServiceTypeConstants['DELIVERY']):
                volumeWeightColumnData.icon = `<svg viewBox='0 0 48 48' width='25' xmlns='http://www.w3.org/2000/svg'><path class="fill-evenodd light-grey-icon" d='M38 18h-8V6H18v12h-8l14 14 14-14zM10 36v4h28v-4H10z' /></svg>`;
                break;
            case (this.globals.stopPointServiceTypeConstants['PICKUP']):
                volumeWeightColumnData.icon = `<svg viewBox='0 0 48 48' width='25' xmlns='http://www.w3.org/2000/svg'><path class="fill-evenodd light-grey-icon" d='M18 32h12V20h8L24 6 10 20h8zm-8 4h28v4H10z' /></svg>`;
                break;
        }
        return volumeWeightColumnData;
    }

    checkVoucherCanBeInvoiced(stopPoint) {
        const voucher = stopPoint.voucher;
        let canBeInvoiced = false;
        if (stopPoint) {
            if (voucher) {
                voucher.company_voucher_collaborators.forEach(collaborator => {
                    if (collaborator.type == voucher.paid_by && !voucher.invoice_id) {
                        canBeInvoiced = true;
                    }
                });
            }
        }
        return canBeInvoiced;
    }

    // checks if a collaborator exists inside the collaborators array inside a voucher that has a type equal to pay_on_delivery_return
    checkVoucherCollaboratorValidity(stopPoint) {
        let isValid = false;
        let collaborator;
        if (stopPoint) {
            if (stopPoint.voucher && stopPoint.voucher.company_voucher_collaborators) {
                stopPoint.voucher.company_voucher_collaborators.forEach(currCollaborator => {
                    if (currCollaborator.type == stopPoint.pay_on_delivery_return) {
                        isValid = true;
                        collaborator = currCollaborator.collaborator;
                    }
                });
            }
        }
        return {
            isValid: isValid,
            collaborator: collaborator
        };
    }

    // returns delivery & pickup stop points
    getDeliveryAndPickupData(stopPointData) {
        let deliveryData, pickupData;
        if (stopPointData.stopPoint.service_type === this.globals.stopPointServiceTypeConstants['DELIVERY']) {
            deliveryData = stopPointData.stopPoint;
            pickupData = stopPointData.relatedStopPoint;
        } else if (stopPointData.stopPoint.service_type === this.globals.stopPointServiceTypeConstants['PICKUP']) {
            deliveryData = stopPointData.relatedStopPoint;
            pickupData = stopPointData.stopPoint;
        }
        return { deliveryData: deliveryData, pickupData: pickupData }
    }

    // gets two stop points and returns the one appropriate to calculate the voucher charges
    // will return the delivery SP if exists, otherwise it will return the first one given
    // stopPoint2 will be undefined if the stopPoint1 is only delivery or only pickup, in this case, stopPoint1 is returned
    getStopPointToCalculateCharges(stopPoint1, stopPoint2) {
        let deliveryStopPoint;
        if (stopPoint1?.service_type === this.globals.stopPointServiceTypeConstants['DELIVERY']) {
            deliveryStopPoint = stopPoint1;
        } else if (stopPoint2?.service_type === this.globals.stopPointServiceTypeConstants['DELIVERY']) {
            deliveryStopPoint = stopPoint2;
        } else {
            deliveryStopPoint = stopPoint1;
        }
        return deliveryStopPoint;
    }

    getDeliveryFromTwoStopPoints(stopPoint1, stopPoint2) {
        let deliveryStopPoint;
        if (stopPoint1?.service_type === this.globals.stopPointServiceTypeConstants['DELIVERY']) {
            deliveryStopPoint = stopPoint1;
        } else if (stopPoint2?.service_type === this.globals.stopPointServiceTypeConstants['DELIVERY']) {
            deliveryStopPoint = stopPoint2;
        }
        return deliveryStopPoint;
    }

    getPickupFromTwoStopPoints(stopPoint1, stopPoint2) {
        let pickupStopPoint;
        if (stopPoint1?.service_type === this.globals.stopPointServiceTypeConstants['PICKUP']) {
            pickupStopPoint = stopPoint1;
        } else if (stopPoint2?.service_type === this.globals.stopPointServiceTypeConstants['PICKUP']) {
            pickupStopPoint = stopPoint2;
        }
        return pickupStopPoint;
    }

    getRecipientEmail(stopPointData) {
        if (stopPointData.recipient_email) { return stopPointData.recipient_email; }
        if (stopPointData.mails?.length) { return stopPointData.mails[0].value; }
    }

    // fullfilment events utils
    isCompleted(reason) {
        if (
            reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']].AT_TIME ||
            reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']].COMPLETED_HANDED
        ) {
            return true;
        }
        return false;
    }

    isCompletedHanded(reason) {
        if (
            reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']].COMPLETED_HANDED
        ) {
            return true;
        }
        return false;
    }

    isInProgress(reason) {
        if (
            reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']].AT_TIME
        ) {
            return true;
        }
        return false;
    }

    isInProgressPending(reason) {
        if (
            reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']].PENDING
        ) {
            return true;
        }
        return false;
    }

    isArrived(reason) {
        if (
            reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']].AT_TIME
        ) {
            return true;
        }
        return false;
    }

    isCanceled(reason) {
        if (this.fulfillmentUtils.cancelReasons.includes(reason)) {
            return true;
        }
        return false;
    }

    isCanceledWithoutHanded(reason) {
        if (this.fulfillmentUtils.notHandedCancelReasons.includes(reason)) {
            return true;
        }
        return false;
    }

    isCanceledHanded(reason) {
        if (this.fulfillmentUtils.handedCancelReasons.includes(reason)) {
            return true;
        }
        return false;
    }

    isScheduledPickup(reason) {
        if (
            reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['SCHEDULED']].AT_TIME_PICKUP
        ) {
            return true;
        }
        return false;
    }

    // returns true if the reason is before "in progress"
    isNotStarted(reason) {
        if (reason < this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']].AT_TIME) {
            return true;
        }
        return false;
    }

    // returns the latest canceled (and not handed) event's details
    getLatestCanceledWithoutHandedDetails(fulfillmentEvents) {
        let details = {
            description: '-',
            fulfillment_datetime: null
        };
        for (let event of fulfillmentEvents) {
            if (this.isCanceledWithoutHanded(event.reason)) {
                details.description = event.description;
                details.fulfillment_datetime = event.fulfillment_datetime;
                break;
            }
        }
        return details;
    }

    isDelivery(serviceType) {
        if (serviceType == this.globals.stopPointServiceTypeConstants['DELIVERY']) {
            return true;
        } else {
            return false;
        }
    }

    isPickup(serviceType) {
        if (serviceType == this.globals.stopPointServiceTypeConstants['PICKUP']) {
            return true;
        } else {
            return false;
        }
    }

    // collaborators
    getSenderCollaborator(collaborators) {
        if (collaborators) {
            for (let collaborator of collaborators) {
                if (collaborator.type == this.globals.stopPointCollaboratorTypesConstants['SENDER']) {
                    return collaborator;
                }
            }
        }
    }
    getConsignorCollaborator(collaborators) {
        if (collaborators) {
            for (let collaborator of collaborators) {
                if (collaborator.type == this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR']) {
                    return collaborator;
                }
            }
        }
    }
    getRecipientCollaborator(collaborators) {
        if (collaborators) {
            for (let collaborator of collaborators) {
                if (collaborator.type == this.globals.stopPointCollaboratorTypesConstants['RECIPIENT']) {
                    return collaborator;
                }
            }
        }
    }

    getDefaultLoad(partner = null) {
        if (partner && partner.default_stop_point_delivery_load) {
            return Number(partner.default_stop_point_delivery_load);
        }
        return this.globals.defaultLoad;
    }

    getTranslations() {
        this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));
    }

    getTotalMassAndDimensions(massAndDimensions) {
        const totals = {
            totalMass: 0,
            totalVolume: 0,
            totalVolumetricWeight: 0,
            countTotal: 0
        };

        if (massAndDimensions?.length) {
            massAndDimensions.forEach(item => {
                totals.totalMass += item.mass ? item.mass * item.count : 0;
                totals.totalVolume += item.mass ? item.mass * this.globals.volumeDenominatorConstant : 0;
                totals.totalVolumetricWeight += item.volume ? (item.volume / this.globals.volumeDenominatorConstant) * item.count : 0;
                totals.countTotal += item.count;
            });
        }

        return totals;
    }

    getCollaboratorName(stopPoint) {
        let name = '';
        if (stopPoint['collaborator']) {
            name = stopPoint['collaborator']['collaboratorData']['collaborator_name'];
        } else if (stopPoint.voucher['company_voucher_collaborators']) {
            var collaboratorObject = stopPoint.voucher['company_voucher_collaborators'][0];
            if (collaboratorObject?.collaborator) {
                name = collaboratorObject['collaborator']['collaboratorData']['collaborator_name'];
            }
        }
        return name;
    }

    getPickupAndDeliveryLoad(stopPoints) {
        const delivery = this.getDeliveryFromTwoStopPoints(stopPoints?.stopPoint, stopPoints?.relatedStopPoint);
        const pickup = this.getPickupFromTwoStopPoints(stopPoints?.stopPoint, stopPoints?.relatedStopPoint);
        let pickupLoad, deliveryLoad;
        pickupLoad = pickup ? pickup.load : null;
        deliveryLoad = delivery ? delivery.load : null;

        return { pickupLoad, deliveryLoad }
    }
}
