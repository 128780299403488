import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { SettingsService } from '@app/services/settings.service';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal.service';

@Component({
    selector: 'app-item-charge-modal',
    templateUrl: './item-charge-modal.component.html',
    styleUrls: ['./item-charge-modal.component.scss']
})
export class ItemChargeModalComponent implements OnInit, OnDestroy {

    myForm: FormGroup;
    listen = [];
    isClickedOnce = false;
    id;
    tempId;
    confinesSelectValues = [];
    confinesMeasureSelectValues = [];
    confinesForEvery: string;
    confinesLimit: string;
    confinesMeasure: string;
    itemsLabel: string;
    itemValue;
    itemValueString;
    priceValue;
    priceValueString;

    collaboratorExtraCharges;
    collaboratorId;
    confines: any;
    itemType: any;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        private settingsService: SettingsService,
        private modalService: ModalService,
        public router: Router,
        formBuilder: FormBuilder,
    ) {
        this.myForm = formBuilder.group({
            "id": [this.id],
            "charge_value": [this.itemValue],
            "charge_value_string": [this.itemValueString],
            "price_value": [this.priceValue],
            "price_value_string": [this.priceValueString],
            "confines": [this.confines],
            "item_type": [this.itemType],
            "temp_id": [this.tempId]
        });
        this.listen.push(this.settingsService.openItemChargesModalListen().subscribe((data) => {
            if (data) {
                this.loadItemCharges(data);
            }

            this.openModal();
        }));
    }

    chargeValueChanged(event) {
        const stringFixed = event.target.value.replace(/,/g, '.');
        this.itemValue = parseInt(stringFixed).toFixed(2);

        this.myForm.patchValue({
            "charge_value": this.itemValue,
        })
    }

    priceValueChanged(event) {
        const stringFixed = event.target.value.replace(/,/g, '.');
        this.priceValue = parseFloat(stringFixed).toFixed(2)

        this.myForm.patchValue({
            "price_value": this.priceValue,
        })
    }

    populateConfines(data) {
        // available confines choices
        if (!data.gridData.additionalItemCharge || (data.gridData.additionalItemCharge && data.confinesValue)) {
            this.confinesSelectValues = [
                {
                    value: 0,
                    label: this.confinesLimit
                },
                {
                    value: 1,
                    label: this.confinesForEvery
                },
            ];
        }
        else {
            this.confinesSelectValues = [
                {
                    value: 0,
                    label: this.confinesLimit
                }
            ];
        }

        this.confinesMeasureSelectValues = [
            {
                value: 'items',
                label: this.itemsLabel
            }
        ];

        // preselected confines choices
        // existing
        if (data.rowData) {
            this.myForm.patchValue({
                "confines": data.confinesValue,
                "item_type": 'items'
            })
        }
        // new
        else {
            this.myForm.patchValue({
                "confines": 0,
                "item_type": 'items'
            })
        }
    }

    patchForm() {
        this.myForm.patchValue({
            "id": this.id,
            "charge_value": this.itemValue,
            "price_value": this.priceValue,
            "temp_id": this.tempId,
            "charge_value_string": this.itemValueString,
            "price_value_string": this.priceValueString,
        });
        setTimeout(() => {
            M.updateTextFields();
        }, 100);
    }

    loadItemCharges(data) {
        if (data) {
            // row clicked
            if (data.rowData) {
                this.id = data.rowData.id;
                this.itemValue = data.rowData.charge_value;
                this.priceValue = data.rowData.price_value;
                this.priceValueString = data.rowData.price_value;
                this.itemValueString = data.rowData.charge_value;
                this.tempId = data.rowData.temp_id;

                this.patchForm();
                this.populateConfines(data);
            }
            // new charge
            else {
                this.populateConfines(data);
            }
        }

        this.openModal();
    }

    openModal() {
        const modal = document.querySelector('#item-charges-modal');
        const modalBackground = document.querySelector('#item-charges-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');
    }

    closeModal() {
        const modal = document.querySelector('#item-charges-modal');
        const modalBackground = document.querySelector('#item-charges-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');
        this.resetItemChargesModal();
    }

    resetItemChargesModal() {
        this.id = null;
        this.itemValue = null;
        this.priceValue = null;
        this.confines = 0;
        this.itemType = 0;
        this.tempId = null;
        this.priceValueString = '';
        this.itemValueString = '';

        this.patchForm();
    }

    submitItemCharge() {
        this.modalService.updateItemCharges(this.myForm.value);
        this.isClickedOnce = false;
        this.closeModal();
    }

    getTranslations() {
        this.listen.push(this.translate.get('SETTINGS').subscribe((res: string) => {
            this.confinesLimit = res['LIMIT'];
            this.confinesForEvery = res['FOR_EVERY'];
            this.confinesMeasure = res['WEIGHT_TYPE'];
            this.itemsLabel = res['ITEMS_TYPE'];
        }));
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
