import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { MapService } from '@app/services/map.service';
import { Globals } from '@app/services/globals';
// import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ColourService } from '@app/services/colour.service';
import { ModalService } from '@app/services/modal.service';

declare var H: any;

@Component({
    selector: 'app-extra-charges-map',
    templateUrl: './extra-charges-map.component.html',
    styleUrls: ['./extra-charges-map.component.scss']
})
export class ExtraChargesMapComponent implements OnInit, AfterViewInit, OnDestroy {

    private platform: any;
    @ViewChild('extraChargesMap', { static: false }) public mapElement: ElementRef;
    @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
    @Output() geofenceChanged = new EventEmitter<string>();

    map;
    ui;
    behavior;

    listen = [];

    depots = [];
    depotsGroup = new H.map.Group();
    polygonGroup = new H.map.Group();
    regionsGroup = new H.map.Group();
    polygonMarkersGroup = new H.map.Group();
    polygonLineString = new H.geo.LineString();
    polygonVerticesCoords = [];
    polygonLineStringPolyline;

    drawModeEnabled = true;
    driversRegions = [];

    activeDriverIndex = null;

    constructor(
        private mapService: MapService,
        public globals: Globals,
        private colourService: ColourService,
        private modalService: ModalService,
    ) {
        this.platform = this.mapService.platform;
    }

    emptyDrawMode() {
        if (this.polygonLineStringPolyline) {
            this.map.removeObject(this.polygonLineStringPolyline);
        }
        this.polygonLineStringPolyline = null;
        this.polygonVerticesCoords = [];
        this.polygonLineString = new H.geo.LineString();
        this.polygonMarkersGroup.removeAll();
        const polygons = this.polygonGroup.getObjects();
        polygons.forEach(mainGroup => {
            if (!mainGroup.getVisibility()) {
                mainGroup.setVisibility(true);
            }
        });
    }

    removePolygons() {
        this.emptyDrawMode();
        this.removeRegions();
        this.polygonGroup.removeAll();
    }

    removeRegions() {
        this.regionsGroup.removeAll();
    }

    getPolygonCount() {
        return this.polygonGroup.getObjects().length;
    }

    centerToPolygon(driverId) {
        let bounds = this.polygonGroup.getBoundingBox();
        if (driverId) {
            const polygons = this.polygonGroup.getObjects();
            const mainGroups = [];
            polygons.forEach(mainGroup => {
                if (mainGroup.getData().id === driverId) {
                    bounds = mainGroup.getBoundingBox();
                }
            });
        }
        if (bounds) {
            if (this.map) {
                this.map.getViewModel().setLookAtData({ bounds: bounds }, true);
            } else {
                console.error('The map is not yet initialized.');
            }
        } else {
            console.error('This polygon group has no bounds.');
        }
    }

    createResizablePolygon(lineString, colour, editable = true) {
        this.modalService.clearRegionsSelections();
        const self = this;
        const rgb = this.colourService.hexToRGB(colour.replace('#', ''));
        const rgbaColour = 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', 0.3)';
        const circleIcon = this.svgIconsComponent.svgCircleColour.replace('markerColour', colour);
        const polygonData = this.mapService.createPolygonMainGroup(lineString, circleIcon, null, rgbaColour, colour);
        const verticeGroup = polygonData.verticeGroup;
        const mainGroup = polygonData.mainGroup;
        const polygon = polygonData.polygon;

        if (editable) {
            self.polygonGroup.removeAll();
            self.polygonGroup.addObject(mainGroup);
            const polygonWKT = polygon.getGeometry().toString();
            this.geofenceChanged.emit(polygonWKT);
            // event listener for main group to show markers if moved in with mouse (or touched on touch devices)
            mainGroup.addEventListener('pointerenter', function (evt) {
                // show vertice markers
                verticeGroup.setVisibility(true);
            }, true);

            // event listener for main group to hide vertice markers if moved out with mouse (or released finger on touch devices)
            // the vertice markers are hidden on touch devices after specific timeout
            self.polygonGroup.addEventListener('pointerleave', function (evt) {
                const timeout = (evt.currentPointer.type === 'touch') ? 1000 : 0;
                // hide vertice markers
                verticeGroup.setVisibility(false);
            }, true);

            // event listener for vertice markers group to change the cursor to pointer
            verticeGroup.addEventListener('pointerenter', function (evt) {
                document.body.style.cursor = 'pointer';
            }, true);

            // event listener for vertice markers group to change the cursor to default
            verticeGroup.addEventListener('pointerleave', function (evt) {
                document.body.style.cursor = 'default';
            }, true);

            // event listener for vertice markers group to resize the geo polygon object if dragging over markers
            verticeGroup.addEventListener('drag', function (evt) {
                const pointer = evt.currentPointer,
                    geoLineString = polygon.getGeometry().getExterior(),
                    geoPoint = self.map.screenToGeo(pointer.viewportX, pointer.viewportY);

                // set new position for vertice marker
                evt.target.setGeometry(geoPoint);

                // set new position for polygon's vertice
                geoLineString.removePoint(evt.target.getData()['verticeIndex']);
                geoLineString.insertPoint(evt.target.getData()['verticeIndex'], geoPoint);
                polygon.setGeometry(new H.geo.Polygon(geoLineString));

                // stop propagating the drag event, so the map doesn't move
                evt.stopPropagation();
            }, true);

            verticeGroup.addEventListener('dragend', function (evt) {
                self.savePolygonChanges(evt.target.getData().id);
            }, true);
        } else {
            self.regionsGroup.addObject(mainGroup);
        }
    }

    updatePolygon(lineString, colour, driverId) {
        const polygons = this.polygonGroup.getObjects();
        const mainGroups = [];
        polygons.forEach(mainGroup => {
            if (mainGroup.getData().id === driverId) {
                mainGroups.push(mainGroup);
            }
        });
        if (mainGroups.length) {
            mainGroups.forEach(mainGroup => {
                this.polygonGroup.removeObject(mainGroup);
            });
            this.createResizablePolygon(lineString, colour);
        }
    }

    savePolygonChanges(id) {
        const polygons = this.polygonGroup.getObjects();
        polygons.forEach(mainGroup => {
            if (mainGroup.getData().id === id) {
                mainGroup.getObjects().forEach(polygon => {
                    if (polygon instanceof H.map.Polygon) {
                        const polygonWKT = polygon.getGeometry().toString();
                        this.geofenceChanged.emit(polygonWKT);
                    }
                });
            }
        });
    }

    createPolygonMarker(lat, lon) {
        const icon = new H.map.Icon(this.svgIconsComponent.svgCircle);
        const coords = { lat: lat, lng: lon };
        const marker = new H.map.Marker(coords, { icon: icon });
        this.polygonMarkersGroup.addObject(marker);
    }

    createLineString(lat, lon) {
        if (!this.polygonLineStringPolyline) {
            const firstPointCoords = this.polygonVerticesCoords[0];
            this.polygonLineString.pushLatLngAlt(firstPointCoords.lat, firstPointCoords.lng, 0);
            this.polygonLineString.pushLatLngAlt(lat, lon, 0);
            this.polygonLineStringPolyline = new H.map.Polyline(this.polygonLineString, {
                style: {
                    lineWidth: 5,
                    strokeColor: '#00aeba',
                }
            });
            this.map.addObject(this.polygonLineStringPolyline);
        } else {
            this.polygonLineString.pushLatLngAlt(lat, lon, 0);
            this.polygonLineStringPolyline.setGeometry(this.polygonLineString);
        }
    }

    clickOnMap(event) {
        const self = this;
        const target = event.target;
        const polygons = this.polygonGroup.getObjects();

        if (!(target instanceof H.map.Marker)) {
            const coords = self.map.screenToGeo(event.currentPointer.viewportX, event.currentPointer.viewportY);
            if (event.originalEvent.button === 0 || event.originalEvent.type === 'touchend') {
                self.polygonVerticesCoords.push(coords);
                if (self.polygonVerticesCoords.length === 1) {
                    polygons.forEach(mainGroup => {
                        if (mainGroup.getData().id === 1) {
                            mainGroup.setVisibility(false);
                        }
                    });
                    self.createPolygonMarker(coords.lat, coords.lng);
                } else if (self.polygonVerticesCoords.length >= 2) {
                    self.createLineString(coords.lat, coords.lng);
                }
            }
        }

        // check if marker is pressed again to create zone polygon
        if (self.polygonVerticesCoords.length > 2 && target instanceof H.map.Marker) {
            this.createResizablePolygon(self.polygonLineString, '#00aeba');
            this.emptyDrawMode();
            this.polygonMarkersGroup.removeAll();
        }

    }

    initMap(depotLat, depotLon) {
        // const self = this;
        const defaultLayers = this.platform.createDefaultLayers();
        this.map = new H.Map(
            this.mapElement.nativeElement,
            defaultLayers.vector.normal.map,
            {
                zoom: 12,
                center: { lat: depotLat, lng: depotLon },
                // pixelRatio: window.devicePixelRatio || 1
            }
        );
        var provider = this.map.getBaseLayer().getProvider();
        var style = new H.map.Style('/assets/lastmilyAssets/light-final.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
        provider.setStyle(style);
        const mapEvents = new H.mapevents.MapEvents(this.map);
        this.map.addEventListener('tap', this.clickOnMap.bind(this));
        this.behavior = new H.mapevents.Behavior(mapEvents);
        this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
        const mapSettings = this.ui.getControl('mapsettings');
        mapSettings.setAlignment('top-left');
        this.map.addObject(this.polygonMarkersGroup);
        this.map.addObject(this.polygonGroup);
        this.map.addObject(this.regionsGroup);
        this.polygonGroup.setZIndex(10);

    }

    loadAllRegions(polygons) {
        polygons.forEach((polygon, index) => {
            if (polygon) {
                const colour = this.colourService.colourCalculator(index + 1);
                const lineString = this.mapService.calculatePolygon(polygon);
                this.createResizablePolygon(lineString, colour, false);
            }
        });
    }

    loadSpatialAreaOnMap(polygon) {
        if (polygon) {
            const colour = this.colourService.colourCalculator(0);
            const lineString = this.mapService.calculatePolygon(polygon);
            this.createResizablePolygon(lineString, colour);
        }
    }

    ngOnInit() { }

    public ngAfterViewInit() {
        const self = this;
        const dataRefreshIntervalId = setInterval(dataChecker, 200);
        function dataChecker() {
            if (self.globals.depotsDataDone) {
                clearInterval(dataRefreshIntervalId);
                const depotLat = self.globals.currentLat;
                const depotLon = self.globals.currentLon;
                self.initMap(depotLat, depotLon);
            }
        }
    }

    ngOnDestroy() {
        this.map.removeEventListener('tap', this.clickOnMap);
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
