import { ModalService } from '@app/services/modal.service';
import { Globals } from '@app/services/globals';
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SettingsService } from '@app/services/settings.service';
import { of } from 'rxjs';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
import { Router } from '@angular/router';
import { ItemChargeModalComponent } from '@app/modals/item-charge-modal/item-charge-modal.component';
import { eventNames } from 'process';

@Component({
  selector: 'app-items-charges-grid',
  templateUrl: './items-charges-grid.component.html',
  styleUrls: ['./items-charges-grid.component.scss']
})
export class ItemsChargesGridComponent implements OnInit, OnDestroy {
  @ViewChild('itemsChargesGrid', { static: false }) itemChargesGrid: AgGridAngular;
  @ViewChild(ItemChargeModalComponent, { static: false }) itemChargeModalComponent: ItemChargeModalComponent;

  listen = [];
  searchString: String = '';

  gridApi;
  gridColumnApi;
  domLayout = 'autoHeight';
  itemChargesDataArray = [];
  deleteDefaultChargesIds = [];
  deleteAdditionalChargesIds = [];
  rowData: any;
  columnDefs;
  tempIdCounter = 1;

  itemType: string;
  confines: string;
  untilLabel: string;
  forEveryLabel: string;
  fromToLabel: string;
  currencyLabel: string;

  collaboratorExtraCharges;
  collaboratorId;

  // widths
  confinesWidth;
  measureWidth;
  amountWidth;
  actionsWidth;

  confinesTitle: string;
  measureTitle: string;
  amountTitle: string;
  actionsTitle: string;
  gridData: any;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private settingsService: SettingsService,
    public gridsService: GridsService,
    public modalService: ModalService,
    public globals: Globals,
    public router: Router,
  ) {
    this.listen.push(this.modalService.updateItemChargesListen().subscribe((data) => {
      // edit already saved charge
      if (data.id) {
        if (data.confines) {
          this.gridData.additionalPerItemCharge = {
            id: data.id,
            price_value: data.price_value,
            charge_value: data.charge_value
          };
        } else {
          for (let index = 0; index < this.gridData.defaultPerItemCharges.length; index++) {
            if (this.gridData.defaultPerItemCharges[index].id == data.id) {
              this.gridData.defaultPerItemCharges[index] = data;
              break;
            }
          }
        }
      }

      // create/edit unsaved charge
      else {
        // replace unsaved additional charge
        if (data.confines) {
          this.gridData.additionalPerItemCharge = {
            id: null,
            price_value: data.price_value,
            charge_value: data.charge_value,
          };
        }

        // create/edit unsaved additional charge
        else {
          // edit unsaved charge
          if (data.temp_id) {
            for (let index = 0; index < this.gridData.defaultPerItemCharges.length; index++) {
              if (this.gridData.defaultPerItemCharges[index].temp_id == data.temp_id) {
                this.gridData.defaultPerItemCharges[index] = data;
                break;
              }
            }
          }

          // create unsaved charge
          else {
            data.temp_id = this.tempIdCounter;
            this.tempIdCounter++;
            this.gridData.defaultPerItemCharges.push(data);
          }
        }
      }

      this.updateGrid(this.gridData);
      this.modalService.updateItemsChargesForm(this.gridData);
    }));
  }

  setItemChargesGridData() {

  }

  updateGrid(data) {
    let gridObject = {};
    this.gridData = data;
    this.itemChargesDataArray = [];

    if (data) {
      if (data.defaultPerItemCharges.length) {
        data.defaultPerItemCharges.forEach(charge => {
          let confinesLabel = this.untilLabel + ' ' + Math.round(charge.charge_value);

          gridObject = {
            id: charge.id,
            confines: confinesLabel,
            confinesValue: 0,
            measure: this.itemType,
            amount: charge.price_value + this.currencyLabel,
            actions: 'X',
            objData: charge
          };
          this.itemChargesDataArray.push(gridObject);
        });
      }

      if (data.additionalPerItemCharge) {
        let confinesLabel = this.forEveryLabel + ' ' + Math.round(data.additionalPerItemCharge.charge_value);

        gridObject = {
          id: data.additionalPerItemCharge.id,
          confines: confinesLabel,
          confinesValue: 1,
          measure: this.itemType,
          amount: data.additionalPerItemCharge.price_value + this.currencyLabel,
          actions: 'X',
          objData: data.additionalPerItemCharge
        };
        this.itemChargesDataArray.push(gridObject);
      }

      if (!data.defaultPerItemCharges.length && !data.additionalPerItemCharge) {
        const noData = { noDataText: 'No data' }
        this.itemChargesDataArray.push(noData);
      }
    } else {
      const noData = { noDataText: 'No data' }
      this.itemChargesDataArray.push(noData);
    }

    this.rowData = of(this.itemChargesDataArray);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  rowClicked(event) {
    const target = event.event.target.closest('div');
    if (!event.data.noDataText && !target.classList.contains('actions-container')) {
      let data = {
        rowData: event.data.objData,
        gridData: this.gridData,
        confinesValue: event.data.confinesValue
      }

      this.settingsService.openItemChargesModal(data);
    }
  }

  onFirstDataRendered(params) { }

  newItemCharge() {
    let data = {
      rowData: null,
      gridData: this.gridData
    }
    this.settingsService.openItemChargesModal(data);
  }

  deleteItemCharge(event) {
    // additional charge
    if (event.data.confinesValue) {
      if (event.data.id) {
        // remove existing additional charge
        if (this.gridData.additionalPerItemCharge.id) {
          this.deleteAdditionalChargesIds.push(this.gridData.additionalPerItemCharge.id);
          this.gridData.additionalPerItemCharge = null;
          this.updateGrid(this.gridData);
          this.modalService.updateItemsChargesForm(this.gridData);
        }
        // remove new (submitted) additional charge
        else {
          this.gridData.additionalPerItemCharge = null;
          this.updateGrid(this.gridData);
          this.modalService.updateItemsChargesForm(this.gridData);
        }
      }
    }

    // default charges
    else {
      if (event.data.id) {
        for (let index = 0; index < this.gridData.defaultPerItemCharges.length; index++) {
          // remove existing default charge
          if (this.gridData.defaultPerItemCharges[index].id) {
            if (this.gridData.defaultPerItemCharges[index].id == event.data.id) {
              this.deleteDefaultChargesIds.push(this.gridData.defaultPerItemCharges[index].id);
              this.gridData.defaultPerItemCharges.splice(index, 1);
              this.updateGrid(this.gridData);
              this.modalService.updateItemsChargesForm(this.gridData);
              break;
            }
          }
        }
      }

      // remove new (submitted) default charge
      else {
        for (let index = 0; index < this.gridData.defaultPerItemCharges.length; index++) {
          if (this.gridData.defaultPerItemCharges[index].temp_id) {
            if (this.gridData.defaultPerItemCharges[index].temp_id == event.data.objData.temp_id) {
              this.gridData.defaultPerItemCharges.splice(index, 1);
              this.updateGrid(this.gridData);
              this.modalService.updateItemsChargesForm(this.gridData);
              break;
            }
          }
        }
      }
    }
  }

  // custom renderer for actions
  actionsRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      columnObject += '<div class="single-cell standard-width actions-container">' + '<i class="fas fa-times fa-lg actions-delete"></i>' + '</div>';
    }
    return columnObject;
  }

  getTranslations() {
    this.listen.push(this.translate.get('SETTINGS.CONFINES').subscribe((res: string) => { this.confinesTitle = res; }));
    this.listen.push(this.translate.get('SETTINGS.MEASURE').subscribe((res: string) => { this.measureTitle = res; }));
    this.listen.push(this.translate.get('SETTINGS.COST_AMOUNT').subscribe((res: string) => { this.amountTitle = res; }));
    this.listen.push(this.translate.get('SETTINGS.ACTIONS').subscribe((res: string) => { this.actionsTitle = res; }));
    this.listen.push(this.translate.get('SETTINGS.ITEMS_TYPE').subscribe((res: string) => { this.itemType = res; }));
    this.listen.push(this.translate.get('SETTINGS.CHARGE_UNTIL').subscribe((res: string) => { this.untilLabel = res; }));
    this.listen.push(this.translate.get('SETTINGS.CHARGE_FOR_EVERY').subscribe((res: string) => { this.forEveryLabel = res; }));
    this.listen.push(this.translate.get('SETTINGS.FROM_TO').subscribe((res: string) => { this.fromToLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currencyLabel = res; }));

    this.updateColumnDefs();
  }

  updateColumnDefs() {
    this.columnDefs = [
      { headerName: this.confinesTitle, field: 'confines', width: this.gridsService.widthCalculatorContainerId(this.confinesWidth, 'item-charges-container') },
      { headerName: this.measureTitle, field: 'measure', width: this.gridsService.widthCalculatorContainerId(this.measureWidth, 'item-charges-container') },
      { headerName: this.amountTitle, field: 'amount', width: this.gridsService.widthCalculatorContainerId(this.amountWidth, 'item-charges-container') },
      {
        headerName: this.actionsTitle,
        field: 'actions',
        cellRenderer: this.actionsRenderer,
        onCellClicked: this.deleteItemCharge.bind(this),
        width: this.gridsService.widthCalculatorContainerId(this.actionsWidth, 'item-charges-container')
      },
    ]
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    // set widths for columns based on url
    if (this.router.url === '/settings') {
      this.confinesWidth = 40;
      this.measureWidth = 20;
      this.amountWidth = 25;
      this.actionsWidth = 15;
    }
    else if (this.router.url.split('/')[2] == 'collaboratorView'
      || this.router.url.split('/')[1] == 'customerCollaborators') {
      this.confinesWidth = 30;
      this.measureWidth = 30;
      this.amountWidth = 20;
      this.actionsWidth = 20;
    }

    this.updateColumnDefs();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
