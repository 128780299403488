import { take } from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import * as moment from 'moment-timezone';
import { DashboardDriversDummyComponent } from './dashboard-drivers-dummy/dashboard-drivers-dummy.component';
import { HttpClient } from '@angular/common/http';
// import { Chart } from 'chart.js';
import { ChartService } from '@app/services/chart.service';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    ChartOptions
  } from 'chart.js';

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  );

@Component({
    selector: 'app-dashboard-drivers',
    templateUrl: './dashboard-drivers.component.html'
})

export class DashboardDriversComponent implements OnInit, AfterViewInit {

    @ViewChild(DashboardDriversDummyComponent, { static: false }) dashboardDriversDummyComponent: DashboardDriversDummyComponent;

    listen = [];

    currentActiveChart = 'rating'; // is set via the main dashboard component on filter change for this chart container

    chart;

    metaPagesCount = 3; // mock
    currentPage = 0;

    driversNamesCurrentData = [];
    driversRatingCurrentData = [];
    driversAvatarsCurrentData = [];
    driversShipmentsCurrentData = [];
    driversDurationCurrentData = [];
    driversRecipientsRatingCurrentData = [];

    allDriversData;

    showSampleDataText = false;

    // chart legend labels
    ratingLegend;
    durationLegend;
    shipmentsLegend;
    recipientsRatingLegend;

    constructor(
        private http: HttpClient,
        public globals: Globals,
        public translate: TranslateService,
        public chartService: ChartService,
    ) {
        // this.listen.push(this.modalService.updateStopPointsDateListen().subscribe((dates) => {
        //     this.selectedDatesQuery = 'startDate=' + dates.start.replaceAll("/", "-") + '&endDate=' + dates.end.replaceAll("/", "-") + '&timezone=' + moment.tz.guess();
        //     this.updateView();
        // }));
        console.log('this.chart:', this.chart)
    }


    // CHART
    initRatingChart() {
        const chartOptions:ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptionsDashboardDriversBars));
        console.log('chartOptions 1:', chartOptions)
        let chartDataValues = [], chartDataNames = [], chartDataAvatars = [];
        let backgroundColors = [
            '#00aeba',
            '#a54d78',
            '#b35f06',
            '#0b5393',
            '#535353'
        ];

        // dummy data
        if (!this.driversRatingCurrentData.length) {
            backgroundColors = [
                '#00aeba50',
                '#a54d7850',
                '#b35f0650',
                '#0b539350',
                '#53535350'
            ];

            for (let i = 0; i < 5; i++) {
                chartDataValues.push(5 - i);
                chartDataNames.push('-');
                chartDataAvatars.push(this.globals.driverDefaultBase64);
            }

            this.showSampleDataText = true;
        }
        else {
            chartDataValues = this.driversRatingCurrentData;
            chartDataAvatars = this.driversAvatarsCurrentData;

            // create short names
            this.driversNamesCurrentData.forEach(driverName => {
                chartDataNames.push(driverName.split(' ')[0]);
            });

            this.showSampleDataText = false;
        }

        const ctx = <HTMLCanvasElement>document.getElementById('drivers-chart');
        const self = this;
        if (this.chart) { this.chart.destroy(); }
        chartOptions.plugins.legend.display = false;
        chartOptions['tooltips'] = {
            callbacks: {
                title: function (tooltipItem, tooltipData) {
                    return chartDataNames[tooltipItem[0].index];
                }
            },
            yAlign: 'top',
            enabled: self.driversRatingCurrentData.length ? true : false // disable tooltips on dummy data
        };
        if (ctx) {
            const self = this;
            const barAvatar = {
                id: 'barAvatar',
                afterDraw(chart, args, options) {
                    const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;
                    ctx.save();

                    for (let driverIndex = 0; driverIndex < chartDataAvatars.length; driverIndex++) {
                        const driverImg = new Image();
                        if (chartDataAvatars[driverIndex]) {
                            driverImg.src = 'data:image/jpeg;base64,' + chartDataAvatars[driverIndex];
                        }
                        ctx.save();
                        ctx.beginPath();
                        // ctx.arc(chart.scales.x.getPixelForValue(driverIndex), chart.scales.y.getPixelForValue(chartDataValues[driverIndex]) - 28, 24, 0, Math.PI * 2, false);
                        ctx.arc(chart.scales.x.getPixelForValue(driverIndex), chart.scales.y.getPixelForValue(chartDataValues[driverIndex]) - 28, 24, 0, Math.PI * 2, false);
                        ctx.stroke();
                        ctx.clip();
                        ctx.drawImage(driverImg, (<Chart>chart).scales.x.getPixelForValue(driverIndex) - (48 / 2), chart.scales.y.getPixelForValue(chartDataValues[driverIndex]) - (48 + 4), 48, 48);
                        ctx.restore();
                    }
                }
            }

            // grace is the amount of spacing between the top of the highest valued bar & the end of the chart
            // (used so that avatars are always limited inside the chart, while keeping correct analogies between the bars)
            const customGrace = {
                id: 'customGrace',
                beforeLayout: (chart, options, c) => {
                    let max = 5;
                    let min = 0;
                    let grace = options.grace || 0;

                    grace = '26%';

                    chart.data.datasets.forEach((dataset) => {
                        max = Math.max(max, 5);
                        min = Math.min(min, 0);
                    })

                    if (typeof grace === 'string' && grace.includes('%')) {
                        // grace = Number(grace.replace('%', '')) / 100
                        // chart.options.scales.yAxes[0].ticks.max = max + (max * grace);
                        // chart.options.scales.yAxes[0].ticks.min = min - (min * grace);

                    } else if (typeof grace === 'number') {
                        // chart.options.scales.yAxes[0].ticks.max = max + grace;
                        // chart.options.scales.yAxes[0].ticks.min = min - grace;
                    }
                }
            };

            // fix for rating avatar changing the max value of yAxis
            // chartOptions.scales.yAxes[0]['afterTickToLabelConversion'] = (scaleInstance) => {
            //     scaleInstance['ticks'].forEach((tick, index) => {
            //         if (Number(tick) > 5 || (Number(tick) != 0 && Number(tick) != 5)) {
            //             scaleInstance['ticks'][index] = '';
            //         }
            //     });
            // };

            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: chartDataNames,
                    datasets: [
                        {
                            // label: this.ratingLegend,
                            data: chartDataValues,
                            //fill: true,
                            backgroundColor: backgroundColors,
                            barPercentage: 0.5
                        }
                    ]
                },
                // options: chartOptions
                options: chartOptions,
                plugins: [
                    barAvatar,
                    customGrace
                ],
            });
        }
    }

    initAvgDurationStopPoint() {
        const chartOptions:ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptionsDashboardDriversBars));
        let chartDataValues = [], chartDataNames = [], chartDataAvatars = [];
        let backgroundColors = [
            '#00aeba',
            '#a54d78',
            '#b35f06',
            '#0b5393',
            '#535353'
        ];

        // dummy data
        if (!this.driversDurationCurrentData.length) {
            backgroundColors = [
                '#00aeba50',
                '#a54d7850',
                '#b35f0650',
                '#0b539350',
                '#53535350'
            ];

            for (let i = 0; i < 5; i++) {
                chartDataValues.push(14 - i * 2);
                chartDataNames.push('-');
                chartDataAvatars.push(this.globals.driverDefaultBase64);
            }

            this.showSampleDataText = true;
        }
        else {
            chartDataValues = this.driversDurationCurrentData;
            chartDataAvatars = this.driversAvatarsCurrentData;

            // create short names
            this.driversNamesCurrentData.forEach(driverName => {
                chartDataNames.push(driverName.split(' ')[0]);
            });

            this.showSampleDataText = false;
        }

        // chartOptions.scales.yAxes[0].ticks['maxTicksLimit'] = 4;

        const ctx = <HTMLCanvasElement>document.getElementById('drivers-chart');
        const self = this;
        if (this.chart) { this.chart.destroy(); }
        chartOptions.plugins.legend.display = false;
        chartOptions['tooltips'] = {
            callbacks: {
                title: function (tooltipItem, tooltipData) {
                    return chartDataNames[tooltipItem[0].index];
                }
            },
            yAlign: 'top',
            enabled: self.driversDurationCurrentData.length ? true : false // disable tooltips on dummy data
        };
        if (ctx) {
            const self = this;
            const barAvatar = {
                id: 'barAvatar',
                afterDraw(chart, args, options) {
                    const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;
                    ctx.save();

                    for (let driverIndex = 0; driverIndex < chartDataValues.length; driverIndex++) {
                        const driverImg = new Image();
                        if (chartDataAvatars[driverIndex]) {
                            driverImg.src = 'data:image/jpeg;base64,' + chartDataAvatars[driverIndex];
                        }
                        ctx.save();
                        ctx.beginPath();
                        ctx.arc(chart.scales.x.getPixelForValue(driverIndex), chart.scales.y.getPixelForValue(chartDataValues[driverIndex]) - 28, 24, 0, Math.PI * 2, false);
                        ctx.stroke();
                        ctx.clip();
                        ctx.drawImage(driverImg, chart.scales.x.getPixelForValue(driverIndex) - (48 / 2), chart.scales.y.getPixelForValue(chartDataValues[driverIndex]) - (48 + 4), 48, 48);
                        ctx.restore();
                    }
                }
            }

            let maxValue = 0;
            this.allDriversData['average_stop_over_duration_per_shipment_array'].forEach(duration => {
                if (moment.duration(duration['value'], 'seconds').asMinutes() > maxValue) {
                    maxValue = moment.duration(duration['value'], 'seconds').asMinutes();
                }
            });
            maxValue = Math.floor(maxValue);
            // grace is the amount of spacing between the top of the highest valued bar & the end of the chart
            // (used so that avatars are always limited inside the chart, while keeping correct analogies between the bars)
            const customGrace = {
                id: 'customGrace',
                beforeLayout: (chart, options, c) => {
                    let max = maxValue;
                    let min = 0;
                    let grace = options.grace || 0;

                    grace = '26%';

                    chart.data.datasets.forEach((dataset) => {
                        max = Math.max(max, Math.max(...dataset.data));
                        min = Math.min(min, Math.min(...dataset.data));
                    })

                    if (typeof grace === 'string' && grace.includes('%')) {
                        grace = Number(grace.replace('%', '')) / 100
                        // chart.options.scales.yAxes[0].ticks.max = max + (max * grace);
                        // chart.options.scales.yAxes[0].ticks.min = min - (min * grace);

                    } else if (typeof grace === 'number') {
                        // chart.options.scales.yAxes[0].ticks.max = max + grace;
                        // chart.options.scales.yAxes[0].ticks.min = min - grace;
                    }
                }
            };

            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: chartDataNames,
                    datasets: [
                        {
                            // label: this.durationLegend,
                            data: chartDataValues,
                            //fill: true,
                            backgroundColor: backgroundColors,
                            barPercentage: 0.5
                        }
                    ]
                },
                options: chartOptions,
                plugins: [
                    barAvatar,
                    customGrace
                ],
            });
        }
    }

    initAvgDeliveries() {
        const chartOptions:ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptionsDashboardDriversBars));

        let chartDataValues = [], chartDataNames = [], chartDataAvatars = [];
        let backgroundColors = [
            '#00aeba',
            '#a54d78',
            '#b35f06',
            '#0b5393',
            '#535353'
        ];

        // dummy data
        if (!this.driversShipmentsCurrentData.length) {
            backgroundColors = [
                '#00aeba50',
                '#a54d7850',
                '#b35f0650',
                '#0b539350',
                '#53535350'
            ];

            for (let i = 0; i < 5; i++) {
                chartDataValues.push(12 - i * 2);
                chartDataNames.push('-');
                chartDataAvatars.push(this.globals.driverDefaultBase64);
            }

            this.showSampleDataText = true;
        }
        else {
            chartDataValues = this.driversShipmentsCurrentData;
            chartDataAvatars = this.driversAvatarsCurrentData;

            // create short names
            this.driversNamesCurrentData.forEach(driverName => {
                chartDataNames.push(driverName.split(' ')[0]);
            });

            this.showSampleDataText = false;
        }

        // chartOptions.scales.yAxes[0].ticks['maxTicksLimit'] = 4;

        const ctx = <HTMLCanvasElement>document.getElementById('drivers-chart');
        const self = this;
        if (this.chart) { this.chart.destroy(); }
        chartOptions.plugins.legend.display = false;
        chartOptions['tooltips'] = {
            callbacks: {
                title: function (tooltipItem, tooltipData) {
                    return chartDataNames[tooltipItem[0].index];
                }
            },
            yAlign: 'top',
            enabled: self.driversShipmentsCurrentData.length ? true : false // disable tooltips on dummy data
        };
        if (ctx) {
            const self = this;
            const barAvatar = {
                id: 'barAvatar',
                afterDraw(chart, args, options) {
                    const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;
                    ctx.save();

                    for (let driverIndex = 0; driverIndex < chartDataValues.length; driverIndex++) {
                        const driverImg = new Image();
                        if (chartDataAvatars[driverIndex]) {
                            driverImg.src = 'data:image/jpeg;base64,' + chartDataAvatars[driverIndex];
                        }
                        ctx.save();
                        ctx.beginPath();
                        ctx.arc(chart.scales.x.getPixelForValue(driverIndex), chart.scales.y.getPixelForValue(chartDataValues[driverIndex]) - 28, 24, 0, Math.PI * 2, false);
                        ctx.stroke();
                        ctx.clip();
                        ctx.drawImage(driverImg, chart.scales.x.getPixelForValue(driverIndex) - (48 / 2), chart.scales.y.getPixelForValue(chartDataValues[driverIndex]) - (48 + 4), 48, 48);
                        ctx.restore();
                    }
                }
            }

            let maxValue = 0;
            this.allDriversData['average_shipments_per_day_array'].forEach(shipment => {
                if (shipment['value'] > maxValue) {
                    maxValue = shipment['value'];
                }
            });
            maxValue = Math.floor(maxValue);

            // grace is the amount of spacing between the top of the highest valued bar & the end of the chart
            // (used so that avatars are always limited inside the chart, while keeping correct analogies between the bars)
            const customGrace = {
                id: 'customGrace',
                beforeLayout: (chart, options, c) => {
                    let max = maxValue;
                    let min = 0;
                    let grace = options.grace || 0;

                    grace = '26%';

                    chart.data.datasets.forEach((dataset) => {
                        max = Math.max(max, Math.max(...dataset.data));
                        min = Math.min(min, Math.min(...dataset.data));
                    })

                    if (typeof grace === 'string' && grace.includes('%')) {
                        // grace = Number(grace.replace('%', '')) / 100
                        // chart.options.scales.yAxes[0].ticks.max = max + (max * grace);
                        // chart.options.scales.yAxes[0].ticks.min = min - (min * grace);

                    } else if (typeof grace === 'number') {
                        // chart.options.scales.yAxes[0].ticks.max = max + grace;
                        // chart.options.scales.yAxes[0].ticks.min = min - grace;
                    }
                }
            };

            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: chartDataNames,
                    datasets: [
                        {
                            // label: this.shipmentsLegend,
                            data: chartDataValues,
                            //fill: true,
                            backgroundColor: backgroundColors,
                            barPercentage: 0.5
                        }
                    ]
                },
                options: chartOptions,
                plugins: [
                    barAvatar,
                    customGrace
                ],
            });
        }
    }


    initRecipientsRating() {
        const chartOptions:ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptionsDashboardDriversBars));
        let chartDataValues = [], chartDataNames = [], chartDataAvatars = [];
        let backgroundColors = [
            '#00aeba',
            '#a54d78',
            '#b35f06',
            '#0b5393',
            '#535353'
        ];

        // dummy data
        if (!this.driversRecipientsRatingCurrentData.length) {
            backgroundColors = [
                '#00aeba50',
                '#a54d7850',
                '#b35f0650',
                '#0b539350',
                '#53535350'
            ];

            for (let i = 0; i < 5; i++) {
                chartDataValues.push(5 - i);
                chartDataNames.push('-');
                chartDataAvatars.push(this.globals.driverDefaultBase64);
            }

            this.showSampleDataText = true;
        }
        else {
            chartDataValues = this.driversRecipientsRatingCurrentData;
            chartDataAvatars = this.driversAvatarsCurrentData;

            // create short names
            this.driversNamesCurrentData.forEach(driverName => {
                chartDataNames.push(driverName.split(' ')[0]);
            });

            this.showSampleDataText = false;
        }

        const ctx = <HTMLCanvasElement>document.getElementById('drivers-chart');
        const self = this;
        if (this.chart) { this.chart.destroy(); }
        // chartOptions['tooltips'] = {
        //     callbacks: {
        //         title: function (tooltipItem, tooltipData) {
        //             return chartDataNames[tooltipItem[0].index];
        //         }
        //     },
        //     yAlign: 'top',
        //     enabled: self.driversRecipientsRatingCurrentData.length ? true : false // disable tooltips on dummy data
        // };
        chartOptions.plugins.legend.display = false;
        if (ctx) {
            const self = this;
            const barAvatar = {
                id: 'barAvatar',
                afterDraw(chart, args, options) {
                    const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;
                    ctx.save();

                    for (let driverIndex = 0; driverIndex < chartDataValues.length; driverIndex++) {
                        const driverImg = new Image();
                        if (chartDataAvatars[driverIndex]) {
                            driverImg.src = 'data:image/jpeg;base64,' + chartDataAvatars[driverIndex];
                        }
                        ctx.save();
                        ctx.beginPath();
                        // ctx.arc(chart.scales.x.getPixelForValue(driverIndex), chart.scales.y.getPixelForValue(chartDataValues[driverIndex]) - 28, 24, 0, Math.PI * 2, false);
                        ctx.stroke();
                        ctx.clip();
                        ctx.drawImage(driverImg, chart.scales.x.getPixelForValue(driverIndex) - (48 / 2), chart.scales.y.getPixelForValue(chartDataValues[driverIndex]) - (48 + 4), 48, 48);
                        ctx.restore();
                    }
                }
            }

            // grace is the amount of spacing between the top of the highest valued bar & the end of the chart
            // (used so that avatars are always limited inside the chart, while keeping correct analogies between the bars)
            const customGrace = {
                id: 'customGrace',
                beforeLayout: (chart, options, c) => {
                    let max = 5;
                    let min = 0;
                    let grace = options.grace || 0;

                    grace = '26%';

                    chart.data.datasets.forEach((dataset) => {
                        max = Math.max(max, 5);
                        min = Math.min(min, 0);
                    })

                    if (typeof grace === 'string' && grace.includes('%')) {
                        // grace = Number(grace.replace('%', '')) / 100
                        // chart.options.scales.yAxes[0].ticks.max = max + (max * grace);
                        // chart.options.scales.yAxes[0].ticks.min = min - (min * grace);

                    } else if (typeof grace === 'number') {
                        // chart.options.scales.yAxes[0].ticks.max = max + grace;
                        // chart.options.scales.yAxes[0].ticks.min = min - grace;
                    }
                }
            };

            // fix for rating avatar changing the max value of yAxis
            // chartOptions.scales.yAxes[0]['afterTickToLabelConversion'] = (scaleInstance) => {
            //     scaleInstance['ticks'].forEach((tick, index) => {
            //         if (Number(tick) > 5 || (Number(tick) != 0 && Number(tick) != 5)) {
            //             scaleInstance['ticks'][index] = '';
            //         }
            //     });
            // };

            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: chartDataNames,
                    datasets: [
                        {
                            // label: this.recipientsRatingLegend,
                            data: chartDataValues,
                            //fill: true,
                            backgroundColor: backgroundColors,
                            barPercentage: 0.5
                        }
                    ]
                },
                options: chartOptions,
                plugins: [
                    barAvatar,
                    customGrace
                ],
            });
        }
    }

    // pages
    goToNextPage() {
        if (this.currentPage < this.metaPagesCount - 1) {
            this.currentPage += 1;
            this.getNewData();
        }
    }

    goToPreviousPage() {
        if (this.currentPage > 0) {
            this.currentPage -= 1;
            this.getNewData();
        }
    }

    // get new page
    getNewData() {
        this.driversNamesCurrentData = [];
        this.driversRatingCurrentData = [];
        this.driversShipmentsCurrentData = [];
        this.driversDurationCurrentData = [];
        this.driversRecipientsRatingCurrentData = [];
        this.driversAvatarsCurrentData = [];

        for (let driverIndex = 5 * this.currentPage; driverIndex < 5 * (this.currentPage + 1); driverIndex++) {
            if (this.allDriversData['ratings'][driverIndex]) {

                let objectKeyForData;
                if (this.currentActiveChart == 'rating') {
                    objectKeyForData = 'ratings';
                } else if (this.currentActiveChart == 'avgDurationStopPoint') {
                    objectKeyForData = 'average_stop_over_duration_per_shipment_array';
                } else if (this.currentActiveChart == 'avgDeliveries') {
                    objectKeyForData = 'average_shipments_per_day_array';
                } else {
                    objectKeyForData = 'recipient_ratings';
                }

                const driverName = this.allDriversData['driver_id_to_driver_info_map'][this.allDriversData[objectKeyForData][driverIndex]['driver_id']]['name'];
                this.driversNamesCurrentData.push(driverName);
                if (this.allDriversData['driver_id_to_driver_info_map'][this.allDriversData['ratings'][driverIndex]['driverImage']]) {
                    this.driversAvatarsCurrentData.push(this.allDriversData['driver_id_to_driver_info_map'][this.allDriversData['ratings'][driverIndex]['driverImage']]);
                } else {
                    this.driversAvatarsCurrentData.push(this.globals.driverDefaultBase64);
                }

                // rating
                const rating = this.allDriversData['ratings'][driverIndex]['value'] > 5 ? 5 : this.allDriversData['ratings'][driverIndex]['value'];
                if (rating) {
                    this.driversRatingCurrentData.push(rating.toFixed(2));
                } else {
                    this.driversRatingCurrentData.push(0);
                }

                // shipments
                this.driversShipmentsCurrentData.push(this.allDriversData['average_shipments_per_day_array'][driverIndex]['value']);

                // duration
                this.driversDurationCurrentData.push(Math.floor(moment.duration(this.allDriversData['average_stop_over_duration_per_shipment_array'][driverIndex]['value'], 'seconds').asMinutes()));

                // recipients rating
                const recipientRating = this.allDriversData['recipient_ratings'][driverIndex]['value'] / 2 > 5 ? 5 : this.allDriversData['recipient_ratings'][driverIndex]['value'] / 2;
                if (recipientRating) {
                    this.driversRecipientsRatingCurrentData.push(recipientRating.toFixed(2));
                } else {
                    this.driversRecipientsRatingCurrentData.push(0);
                }
            }
        }

        if (this.currentActiveChart == 'rating') {
            this.initRatingChart();
        } else if (this.currentActiveChart == 'avgDurationStopPoint') {
            this.initAvgDurationStopPoint();
        } else if (this.currentActiveChart == 'avgDeliveries') {
            this.initAvgDeliveries();
        } else {
            this.initRecipientsRating();
        }
    }

    getTranslations() {
        this.listen.push(this.translate.get('DASHBOARD.RATING').subscribe((res: string) => { this.ratingLegend = res; }));
        this.listen.push(this.translate.get('DASHBOARD.AVG_DURATION_STOP_POINT_LEGEND').subscribe((res: string) => { this.durationLegend = res; }));
        this.listen.push(this.translate.get('DASHBOARD.AVG_DELIVERIES').subscribe((res: string) => { this.shipmentsLegend = res; }));
        this.listen.push(this.translate.get('DASHBOARD.RECIPIENTS_RATING').subscribe((res: string) => { this.recipientsRatingLegend = res; }));
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngAfterViewInit() { }

    ngOnDestroy() {
        // this.listen.forEach(element => {
        //     element.unsubscribe();
        // });
    }
}
