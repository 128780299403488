import { AfterViewInit, Component, NgModule, OnDestroy } from '@angular/core';
import { IPeriodProcessor } from '@app/core/services/period-processor';
import { DashboardReportViewModelService, LmDashboardReportQueryType } from '@app/dashboard-report/dashboard-report-viewmodel.service';
import { LmDialogContext } from '@app/model/dialog';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { hasValue } from '@app/shared/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs';

@UntilDestroy()
@Component({
  template: `
    <lm-dialog 
      [style]="{ width: '30vw', 'margin-top': '120px', 'margin-right': '1.5rem'}" 
      [position] = "'top-right'" 
      [modal]="true" 
      label="{{ 'DASHBOARD.CUSTOM_DATES' | translate }}" 
      [closable]="true" 
      [dismissableMask]="true"
    >
      <ng-template lmTemplate="body">
          <lm-calendar
              [timeOnly]="false"
              [inline]="true"
              [selectionMode]="'range'" 
              [(ngModel)]="dates"
              [selectFn]="dateSelect"
          ></lm-calendar>
      </ng-template>

    <ng-template lmTemplate="footer">
      <lm-button [config]="btn" (click)="ok()"></lm-button>
    </ng-template>
  </lm-dialog>
  `,
  styleUrls: ['./calendar-dialog.component.scss']
})

export class LmCalendarComponent implements AfterViewInit, OnDestroy {
  dates: Date[];
  period:IPeriodProcessor;
  query:LmDashboardReportQueryType;
  btn: LmButtonConfig;
  dateSelect: (e) => void;

  constructor(public config: LmDialogContext<any>) {
    this.period = config.data.period;
    this.query =  config.data.query;
    this.btn = {label: 'OK', disabled: true};

    this.dateSelect = (e) => {
      console.log('this.dates:', this.dates)
      this.btn.disabled = !hasValue(this.dates[1]);
    } 
  }


  ok(){
    const start = this.period.asStartDate(this.dates[0]).convertDateForAPI().APIdate;
    const end = this.period.asEndDate(this.dates[1]).convertDateForAPI().APIdate;
    const query:LmDashboardReportQueryType = {...this.query, untilDateTime:end, daysBeforeUntilDateCount: this.period.formatter.countDaysBetween(start, end)+1}
    this.config.close(query);
  }
  
  cancel(){this.config.close();}

  ngOnInit(): void {}
  ngAfterViewInit() {}
  ngOnDestroy() {}
}
