import { Component, OnInit, ViewChild, OnDestroy, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import * as moment from 'moment-timezone';
import { Router } from '@angular/router';

@Component({
    selector: 'app-collaborator-select-modal',
    templateUrl: './collaborator-select-modal.component.html',
    styleUrls: ['./collaborator-select-modal.component.scss']
})
export class CollaboratorSelectModalComponent implements OnInit, OnDestroy {
    @ViewChild('modal', { static: false, read: ElementRef }) modal: ElementRef;
    @ViewChild('modalBackground', { static: false, read: ElementRef }) modalBackground: ElementRef;

    mode = 'copyZone'; // copyZone, copyRegion
    listen = [];
    canBeOpened = true;
    selectedCollaborators = [];
    collaborators = [];

    zoneId;
    spatialId;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        public router: Router,
    ) { }

    openModal() {
        if (this.canBeOpened) {
            this.canBeOpened = false;

            if (this.modal.nativeElement.classList.contains('closed')) {
                this.modal.nativeElement.classList.remove('closed');
                this.modal.nativeElement.classList.add('open');
                this.modalBackground.nativeElement.classList.remove('hidden');
            }
        }
    }

    closeModal() {
        this.modal.nativeElement.classList.add('closed');
        this.modal.nativeElement.classList.remove('open');
        this.modalBackground.nativeElement.classList.add('hidden');

        setTimeout(() => {
            this.resetModal();
            this.canBeOpened = true;
        }, 500);
    }

    getCompanyCollaborators() {
        this.http.get('api/v1/company-collaborators').subscribe(res => {
            console.log(res);
            this.collaborators = res['items'];
        });
    }

    resetModal() {
        this.collaborators = [];
        this.selectedCollaborators = [];
        this.zoneId = null;
        this.spatialId = null;
    }

    submitCollaborator() {
        const collaboratorsIds = [];
        this.selectedCollaborators.forEach(collaborator => {
            collaboratorsIds.push(collaborator.companyCollaborator.collaborator.collaboratorData.id);
        });

        if (this.selectedCollaborators.length) {
            switch (this.mode) {
                case 'copyZone':
                    const zoneObj = {
                        chargeCategoriesIds: [this.zoneId],
                        collaboratorIds: collaboratorsIds
                    };

                    this.http.post('api/v1/copy-charges', zoneObj).subscribe(res => {
                        this.closeModal();
                    });
                    break;
                case 'copyRegion':
                    const regionObj = {
                        spatialCategoriesIds: [this.spatialId],
                        collaboratorIds: collaboratorsIds
                    };

                    this.http.post('api/v1/copy-spatials', regionObj).subscribe(res => {
                        this.closeModal();
                    });
                    break;
            }
        }
    }

    getTranslations() {
        // this.listen.push(this.translate.get('PROJECT.RESPECT_NONE').subscribe((res: string) => { this.respectNoneLabel = res; }));
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
    }

}
