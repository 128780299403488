// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collaborator-select-modal {
  height: 40vh;
  top: 25vh;
}
.collaborator-select-modal .modal-body {
  display: flex;
  justify-content: center;
}
.collaborator-select-modal .modal-body .dropdown-title {
  color: #999;
  margin-bottom: 8px;
}

#collaborators-dropdown {
  width: 300px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbGxhYm9yYXRvci1zZWxlY3QtbW9kYWwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDSSxZQUFBO0VBQ0EsU0FBQTtBQURKO0FBR0k7RUFDSSxhQUFBO0VBQ0EsdUJBQUE7QUFEUjtBQUdRO0VBQ0ksV0FBQTtFQUNBLGtCQUFBO0FBRFo7O0FBTUE7RUFDSSxZQUFBO0FBSEoiLCJmaWxlIjoiY29sbGFib3JhdG9yLXNlbGVjdC1tb2RhbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgXCJzdHlsZXMvYWJzdHJhY3RzL3ZhcmlhYmxlc1wiO1xuXG4uY29sbGFib3JhdG9yLXNlbGVjdC1tb2RhbCB7XG4gICAgaGVpZ2h0OiA0MHZoO1xuICAgIHRvcDogMjV2aDtcblxuICAgIC5tb2RhbC1ib2R5IHtcbiAgICAgICAgZGlzcGxheTogZmxleDtcbiAgICAgICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG5cbiAgICAgICAgLmRyb3Bkb3duLXRpdGxlIHtcbiAgICAgICAgICAgIGNvbG9yOiAjOTk5O1xuICAgICAgICAgICAgbWFyZ2luLWJvdHRvbTogOHB4O1xuICAgICAgICB9XG4gICAgfVxufVxuXG4jY29sbGFib3JhdG9ycy1kcm9wZG93biB7XG4gICAgd2lkdGg6IDMwMHB4O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/modals/collaborator-select-modal/collaborator-select-modal.component.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;EACA,SAAA;AADJ;AAGI;EACI,aAAA;EACA,uBAAA;AADR;AAGQ;EACI,WAAA;EACA,kBAAA;AADZ;;AAMA;EACI,YAAA;AAHJ;AACA,o3BAAo3B","sourcesContent":["@import \"styles/abstracts/variables\";\n\n.collaborator-select-modal {\n    height: 40vh;\n    top: 25vh;\n\n    .modal-body {\n        display: flex;\n        justify-content: center;\n\n        .dropdown-title {\n            color: #999;\n            margin-bottom: 8px;\n        }\n    }\n}\n\n#collaborators-dropdown {\n    width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
