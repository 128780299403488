// this component is NOT USED
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SettingsService } from '@app/services/settings.service';
import { of } from 'rxjs';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
import { ExtraChargesMapComponent } from './extra-charges-map/extra-charges-map.component';
import { MapService } from '@app/services/map.service';
import { ColourService } from '@app/services/colour.service';

@Component({
  selector: 'app-extra-charges-regions',
  templateUrl: './extra-charges-regions.component.html',
  styleUrls: ['./extra-charges-regions.component.scss']
})
export class ExtraChargesRegionsComponent implements OnInit, OnDestroy {
  @ViewChild('extraChargesGrid', { static: false }) extraChargesGrid: AgGridAngular;
  @ViewChild(ExtraChargesMapComponent, { static: false }) extraChargesMapComponent: ExtraChargesMapComponent;

  listen = [];
  chargeRegions = [];
  searchString: String = '';
  colours = [];

  gridApi;
  gridColumnApi;
  domLayout = 'autoHeight';
  extraChargesDataArray = [];
  rowData: any;
  columnDefs;

  amountLabel: string;
  actionsLabel: string;
  currencyLabel: string;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private settingsService: SettingsService,
    private mapService: MapService,
    private colourService: ColourService,
    public gridsService: GridsService,
  ) {
    this.listen.push(this.settingsService.updateServicesChargesGridListen().subscribe(() => {
      this.setExtraChargesGridData();
    }));
  }

  setExtraChargesGridData() {
    const data = [
      {
        "id": 5,
        "charge_type": "PSTCH",
        "charge_name": "vol ch 1",
        "operator_type": "POGRT",
        "charge_registered_value": 1,
        "pricing_type": "PINCP",
        "geometry": "POLYGON ((23.6961373352716 38.01078446527426,23.709526922673945 37.99806975794384,23.715020086736445 38.01457139870404,23.6961373352716 38.01078446527426))",
        "charge_value": 15
      },
      {
        "id": 8,
        "charge_type": "PSTCH",
        "charge_name": "vol ch 2",
        "operator_type": "POGRT",
        "charge_registered_value": 1,
        "pricing_type": "PINCP",
        "geometry": "POLYGON ((23.622666265935663 38.022956055054166,23.63914575812316 37.97561074155869,23.662835028142695 38.00131627579721,23.648072149724726 38.01781718592698,23.648072149724726 38.03864090005137,23.622666265935663 38.022956055054166))",
        "charge_value": 2
      },
      {
        "id": 2,
        "charge_type": "PSTCH",
        "charge_name": "vol ch 3",
        "operator_type": "POGRT",
        "charge_registered_value": 1,
        "pricing_type": "PINCP",
        "geometry": "POLYGON ((23.697655465574037 37.96579967696624,23.733361031980287 37.961198174365954,23.7065818571756 37.95009924653152,23.66915967699982 37.9454967602251,23.668473031492006 37.96985958685688,23.687699105710756 37.979873071355755,23.697655465574037 37.96579967696624))",
        "charge_value": 6
      },
    ];
    let gridObject = {};
    this.extraChargesDataArray = [];
    if (data.length) {
      data.forEach(data => {
        gridObject = {
          id: data.id,
          charge_name: data.charge_name,
          charge_value: data.charge_value,
          objData: data,
        };
        this.extraChargesDataArray.push(gridObject);
        this.loadRegion(data.geometry, data.id);
      });
    } else {
      const noData = { noDataText: 'No data' }
      this.extraChargesDataArray.push(noData);
    }
    this.rowData = of(this.extraChargesDataArray);
  }

  // creates the region in the map using the polygon provided in the 'geometry' field
  loadRegion(geometry, id) {
    setTimeout(() => {
      if (this.extraChargesMapComponent) {
        let lineString = this.mapService.calculatePolygon(geometry);

        let chargeIndex = this.extraChargesDataArray.findIndex((charge) => {
          return charge.id == id;
        });

        this.colours.push(this.colourService.colourCalculator(chargeIndex));
        this.extraChargesMapComponent.createResizablePolygon(lineString, this.colours[chargeIndex]);

        // set background color for item in the dom
        // document.getElementById('extra-charge-colour-' + chargeIndex).style.backgroundColor = this.colours[chargeIndex];
      }
    }, 100);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  rowClicked(data) {
    if (data) {
      this.settingsService.openExtraChargesModal(data.objData);
    }
  }

  onFirstDataRendered(params) { }

  newExtraCharge() {
    // this.settingsService.toggleRegionChargeDrawMode();
  }

  getTranslations() {
    let zonesColumn: string;

    this.setExtraChargesGridData();

    this.columnDefs = [
      { headerName: zonesColumn, field: 'charge_name', width: this.gridsService.widthCalculatorSettings(30) },
    ];
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
