import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SettingsService } from '@app/services/settings.service';
import { of } from 'rxjs';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
import { Router } from '@angular/router';
import { Globals } from '@app/services/globals';
import { ModalService } from '@app/services/modal.service';
import { SurchargeModalComponent } from '@app/modals/surcharge-modal/surcharge-modal.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-surcharge-grid',
  templateUrl: './surcharge-grid.component.html',
  styleUrls: ['./surcharge-grid.component.scss']
})
export class SurchargeGridComponent implements OnInit, OnDestroy {
  @ViewChild('extraChargesGrid', { static: false }) extraChargesGrid: AgGridAngular;
  @ViewChild(SurchargeModalComponent, { static: false }) surchargeModalComponent: SurchargeModalComponent;

  listen = [];
  chargeRegions = [];
  searchString: String = '';
  colours = [];
  tempIdCounter = 1;

  gridApi;
  gridColumnApi;
  domLayout = 'autoHeight';
  surchargesDataArray = [];
  rowData: any;
  columnDefs;
  actionsTitle;

  selectedSurcharge;
  collaboratorId;
  selectedRegion = null;
  generalZoneLabel;

  surcharges = [];
  availableZones = [];

  chargeCategoryBeingEditedId; // used to enable the grid in a collaborator's charge category that already exists

  actionsLabel;

  constructor(
    public translate: TranslateService,
    private settingsService: SettingsService,
    private modalService: ModalService,
    public gridsService: GridsService,
    public router: Router,
    public globals: Globals,
    private http: HttpClient,
  ) {
    // service to refresh charge categories
    this.listen.push(this.settingsService.setAvailableZonesListen().subscribe(() => {
      if (this.globals.isInRoute('settings')) {
        this.getAvailableZones().subscribe(resZones => {
          this.setAvailableZones(this.surcharges, resZones);
        });
      }
    }));

    // service to refresh surcharges (used on zones grid zone delete)
    this.listen.push(this.settingsService.setSurchargesListen().subscribe(() => {
      this.setSurchargesGridData();
    }));
  }

  getAvailableZones() {
    return this.http.get('api/v1/charge-categories?dto=fuel-surcharge').pipe(take(1));
  }

  setSurchargesGridData() {
    // collaborator surcharges data
    if (this.globals.isInRoute('customerCollaborators') || this.globals.isInRoute('partners')) {
      console.log(this.surcharges)
      this.updateGrid(this.surcharges);
    }
    // settings surcharges data
    else {
      let surchargesUrl = 'api/internal/v1/settings/fuel-surcharges';
      this.http.get(surchargesUrl).pipe(take(1)).subscribe(resSurcharges => {
        this.surchargesDataArray = [];
        this.surcharges = resSurcharges['items'];
        this.updateGrid(this.surcharges);
      });
    };
  }

  updateGrid(data) {
    let gridObject = {};
    this.surchargesDataArray = [];

    // settings
    if (this.globals.isInRoute('settings')) {
      this.getAvailableZones().subscribe(resZones => {
        this.setAvailableZones(this.surcharges, resZones);
        const chargeCategories = resZones['items'];

        if (this.surcharges) {
          if (this.surcharges.length) {
            this.surcharges.forEach(surcharge => {
              const percentageLabel = String(surcharge.percentage + '%');

              // match zones with this collaborator's charge categories via their ids & create label for this discount's zones
              let labelsForZone = '';
              let selectedZonesIds = [];
              // chargeCategories.forEach(zone => {
              this.globals.chargeZones.forEach(zone => {
                if (surcharge.charge_category_ids) {
                  if (surcharge.charge_category_ids.length) {
                    surcharge.charge_category_ids.forEach(id => {
                      if (id == zone.id) {
                        selectedZonesIds.push(zone.id);

                        if (labelsForZone == '') {
                          labelsForZone = zone.name;
                        } else {
                          labelsForZone += ', ' + zone.name;
                        }
                      }
                    });
                  }
                }
              });

              let gridObject = {
                id: surcharge.id,
                name: surcharge.name,
                percentageLabel: percentageLabel,
                percentage: surcharge.percentage,
                selectedZonesIds: selectedZonesIds,
                zonesLabels: labelsForZone,
                objData: surcharge
              };

              // filter out any surcharge that doesn't have a zone
              if (selectedZonesIds.length) {
                this.surchargesDataArray.push(gridObject);
              }
            });
          }
          else {
            this.emptySurchargesGridData();
          }
        }
        else {
          this.emptySurchargesGridData();
        }

        this.rowData = of(this.surchargesDataArray);
      });
    }
    // collaborator
    else {
      if (this.surcharges) {
        if (this.surcharges.length) {
          this.surcharges.forEach(surcharge => {
            const percentageLabel = String(surcharge.percentage + '%');

            let gridObject = {
              id: surcharge.id,
              name: surcharge.name,
              percentageLabel: percentageLabel,
              percentage: surcharge.percentage,
              objData: surcharge
            };

            this.surchargesDataArray.push(gridObject);
          });
        }
        else {
          this.emptySurchargesGridData();
        }
      }
      else {
        this.emptySurchargesGridData();
      }

      this.rowData = of(this.surchargesDataArray);
    }
  }

  deleteSurcharge() {
    const surchargesDelete = {
      fuel_surcharge_ids: [
        this.selectedSurcharge.id
      ]
    };

    if (this.globals.isInRoute('settings')) {
      this.http.post('api/internal/v1/settings/fuel-surcharges-delete', surchargesDelete).pipe(take(1)).subscribe(response => {
        this.setSurchargesGridData();

        // reset dropdown
        this.selectedSurcharge = null;
        const dropdown = document.getElementById('surcharges-dropdown-container');
        dropdown.style.display = 'none';
      });
    } else {
      this.modalService.updateSurcharges([]);
      this.surcharges = [];
      this.updateGrid(this.surcharges);
    }
  }

  setAvailableZones(surcharges, zonesResponse) {
    if (this.globals.isInRoute('settings')) {
      let allZones = zonesResponse['items'];

      // set zones' names w/ collaborator name
      allZones.forEach((zone, index) => {
        let zoneCollaborator = zone.collaborators ? zone.collaborators[0].name : this.generalZoneLabel;
        allZones[index].name = zone.name + ' - ' + zoneCollaborator;
      });

      this.surchargeModalComponent.allZones = [...zonesResponse['items']];
      this.availableZones = zonesResponse['items'];

      allZones.forEach((zone, index) => {
        surcharges.forEach(surcharge => {
          if (surcharge.charge_category_ids) {
            if (surcharge.charge_category_ids.length) {
              surcharge.charge_category_ids.forEach(id => {
                if (zone.id == id) {
                  this.availableZones.splice(index, 1);
                }
              });
            }
          }
        });
      });
      this.surchargeModalComponent.availableZones = this.availableZones;
    }
  }

  emptySurchargesGridData() {
    this.surchargesDataArray = [{ noDataText: 'No data' }];
    this.rowData = of(this.surchargesDataArray);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  rowClicked(event) {
    const target = event.event.target.closest('div');
    if (event.data && this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN']) {
      if (!event.data.noDataText && !target.classList.contains('dropdown-dots') && !target.classList.contains('actions-container')) {
        this.surchargeModalComponent.chargeCategoryBeingEditedId = this.chargeCategoryBeingEditedId;
        this.surchargeModalComponent.loadSurcharge(event.data);
      }
      // collaborator view delete button (delete)
      else if (target.classList.contains('actions-container')) {
        this.selectedSurcharge = {
          id: event.data['id']
        };
        this.deleteSurcharge();
      }
      // settings dropdown options (delete)
      else if (target.classList.contains('dropdown-dots')) {
        this.selectedSurcharge = event.data;
      }
    }
  }

  newSurcharge() {
    this.surchargeModalComponent.chargeCategoryBeingEditedId = this.chargeCategoryBeingEditedId;
    this.surchargeModalComponent.loadSurcharge(null);
  }

  onFirstDataRendered(params) { }

  // custom renderer for dropdown actions
  dropdownRenderer(params) {
    const self = this;
    const dots = document.createElement('div');
    dots.classList.add('dropdown-dots');
    dots.innerHTML = '<i class="fas fa-ellipsis-v"></i>';
    const dropdown = document.getElementById('surcharges-dropdown-container');
    dots.addEventListener('click', function () {
      setTimeout(() => {
        if (!self.selectedRegion) {
          dropdown.style.top = 'calc(' + dots.getBoundingClientRect().bottom + 'px - 10px)';
          dropdown.style.left = 'calc(' + dots.getBoundingClientRect().left + 'px - 9vw)';
          dropdown.style.display = 'block';
          self.selectedRegion = params.data;
        } else {
          self.selectedRegion = null
          dropdown.style.display = 'none';
        }
      }, 20);
    });
    return dots;
  }

  // custom renderer for actions (only for collaboratorView -> X button to remove)
  actionsRenderer(params) {
    let columnObject = '';
    columnObject += '<div class="single-cell standard-width actions-container">' + '<i class="fas fa-times fa-lg actions-delete"></i>' + '</div>';

    return columnObject;
  }

  getTranslations() {
    let percentageLabel, zonesLabel, nameLabel;

    this.listen.push(this.translate.get('SETTINGS.SURCHARGES').subscribe((res: string) => { percentageLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_COLLABORATORS.ZONES').subscribe((res: string) => { zonesLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => { nameLabel = res; }));
    this.listen.push(this.translate.get('SETTINGS.GENERAL_ZONE_LABEL').subscribe((res: string) => { this.generalZoneLabel = res; }));
    this.listen.push(this.translate.get('SETTINGS.ACTIONS').subscribe((res: string) => { this.actionsTitle = res; }));

    if (this.globals.isInRoute('customerCollaborators') || this.globals.isInRoute('partners')) {
      this.columnDefs = [
        {
          headerName: nameLabel,
          field: 'name',
          width: this.gridsService.widthCalculatorContainerId(60, 'surcharges-container')
        },
        {
          headerName: percentageLabel,
          field: 'percentageLabel',
          width: this.gridsService.widthCalculatorContainerId(20, 'surcharges-container')
        },
        {
          headerName: this.actionsTitle,
          cellClass: 'actions',
          cellRenderer: this.actionsRenderer,
          onCellClicked: this.deleteSurcharge.bind(this),
          width: this.gridsService.widthCalculatorContainerId(20, 'surcharges-container')
        }
      ];
    } else {
      this.columnDefs = [
        {
          headerName: nameLabel,
          field: 'name',
          width: this.gridsService.widthCalculatorContainerId(25, 'surcharges-container')
        },
        {
          headerName: percentageLabel,
          field: 'percentageLabel',
          width: this.gridsService.widthCalculatorContainerId(25, 'surcharges-container')
        },
        {
          headerName: zonesLabel,
          field: 'zonesLabels',
          width: this.gridsService.widthCalculatorContainerId(43, 'surcharges-container')
        },
        {
          headerName: '', field: '',
          cellClass: 'dropdown-cell',
          cellRenderer: this.dropdownRenderer.bind(this),
          width: this.gridsService.widthCalculatorContainerId(7, 'surcharges-container')
        }
      ];
    }
  }

  ngOnInit() {
    // get ALL charge categories and create the available zones-collaborator object & then perform a get request to get surcharges, then match  otherwise set grid as empty
    if (this.globals.isInRoute('settings')) {
      this.setSurchargesGridData(); // to be removed
      this.emptySurchargesGridData();
    }

    // translations
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }
}
