import { AddressService } from '@app/services/address.service';
import { GenericUtils } from '@app/utils/generic-utils';
import { ExtraChargesMapComponent } from '@app/settings/extra-charges-regions/extra-charges-map/extra-charges-map.component';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Globals } from '@app/services/globals';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@app/services/settings.service';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal.service';
import countries from 'i18n-iso-countries';
import enCountriesJson from "i18n-iso-countries/langs/en.json";
import elCountriesJson from "i18n-iso-countries/langs/el.json";
import deCountriesJson from "i18n-iso-countries/langs/de.json";
import roCountriesJson from "i18n-iso-countries/langs/ro.json";
import { catchError, debounceTime, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';
import { concat, Observable, of, Subject } from 'rxjs';
import { DataService } from '@app/services/data.service';

@Component({
  selector: 'app-region-charges-form',
  templateUrl: './region-charges-form.component.html',
  styleUrls: ['./region-charges-form.component.scss']
})
export class RegionChargesFormComponent implements OnInit, AfterViewInit {
  @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
  @ViewChild(ExtraChargesMapComponent, { static: false }) extraChargesMapComponent: ExtraChargesMapComponent;
  @ViewChildren('ngSelect') ngSelects: QueryList<NgSelectComponent>;
  @ViewChild('map', { static: false }) public mapElement: ElementRef;

  spatialCategoryUrl = 'api/v1/spatial-category'

  listen = [];
  myForm: FormGroup;

  spatialCategoryId = null;
  spatialCategoryName = '';
  geofence = '';
  countryCode = null;
  chargeCategoryItems = [];
  tempId;
  regionsAlert = '';

  tempIdCounter = 1;
  countryDropdownOptions = [];
  postalCodesDropdownOptions = [];

  addressesDropdownOptions: Observable<any>;
  addressesLoading = false;
  addressesInput = new Subject<string>();

  postalCodeDropdownOptions: Observable<any>;
  postalCodeLoading = false;
  postalCodeInput = new Subject<string>();

  zones = [];
  zonesById = {};
  selectedZonesPerGroupIndex = [];
  spatialCategoryRegions = [];
  spatialCategoryPostalCodes = [];
  lastIndexToAddZone = 0;

  constructor(
    private http: HttpClient,
    public globals: Globals,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    private addressService: AddressService,
    private modalService: ModalService,
    private dataService: DataService,
    public router: Router,
    public genericUtils: GenericUtils,
  ) {
    this.listen.push(this.settingsService.updateZoneChargesGridListen().subscribe(() => {
      this.populateZonesDropdown();
    }));
    this.listen.push(this.settingsService.selectZoneInRegionsChargeListen().subscribe(zone => {
      this.populateZonesDropdown(zone);
    }));
    this.listen.push(this.modalService.updateCollaboratorsChargesFormListen().subscribe((charge) => {
      setTimeout(() => {
        this.populateZonesDropdown(charge);
      }, 50);
    }));
    this.listen.push(this.modalService.clearRegionsSelectionsListen().subscribe(() => {
      this.spatialCategoryPostalCodes = [];
      this.spatialCategoryRegions = [];
      this.countryCode = null;
      this.myForm.patchValue({
        'spatialCategoryRegions': this.spatialCategoryRegions,
        'spatialCategoryPostalCodes': this.spatialCategoryPostalCodes,
        'spatialCategoryCountryCode': {
          'iso_code_v2': this.countryCode,
        }
      });
      M.updateTextFields();
    }));
    countries.registerLocale(enCountriesJson);
    countries.registerLocale(elCountriesJson);
    countries.registerLocale(deCountriesJson);
    countries.registerLocale(roCountriesJson);
    this.myForm = formBuilder.group({
      'id': [this.spatialCategoryId],
      'name': [this.spatialCategoryName],
      'spatialCategoryGeofence': formBuilder.group({
        'geofence': [this.geofence],
      }),
      'spatialCategoryCountryCode': formBuilder.group({
        'iso_code_v2': [this.countryCode],
        // 'selectedCountry': [this.selectedCountry],
      }),
      'spatialCategoryRegions': [this.spatialCategoryRegions],
      'spatialCategoryPostalCodes': [this.spatialCategoryPostalCodes],
      'charge_category_groups': formBuilder.array([]),
      'temp_id': [this.tempId]
    });
    this.addChargeCategoryGroup(null);
  }

  patchFormAfterLoad() {
    this.myForm.patchValue({
      'id': this.spatialCategoryId,
      'name': this.spatialCategoryName,
      'spatialCategoryGeofence': {
        'geofence': this.geofence,
      },
      'spatialCategoryCountryCode': {
        'iso_code_v2': this.countryCode,
      },
      'spatialCategoryRegions': this.spatialCategoryRegions,
      'spatialCategoryPostalCodes': this.spatialCategoryPostalCodes,
      'charge_category_ids': this.chargeCategoryItems,
      'temp_id': this.tempId
    });
    M.updateTextFields();
  }

  loadSpatialCharge(data) {
    this.spatialCategoryId = data.id;
    this.spatialCategoryName = data.name;
    this.tempId = data.temp_id;
    if (data.spatialCategoryGeofence) {
      if (data.spatialCategoryGeofence.geofence) {
        this.geofence = data.spatialCategoryGeofence.geofence;
        this.extraChargesMapComponent.loadSpatialAreaOnMap(this.geofence);
      }
    }
    if (data.spatialCategoryCountryCode) {
      if (data.spatialCategoryCountryCode.length) {
        if (data.spatialCategoryCountryCode[0].iso_code_v2) {
          this.countryCode = data.spatialCategoryCountryCode[0].iso_code_v2;
        } else if (data.spatialCategoryCountryCode[0].country_data.iso_code_v2) {
          this.countryCode = data.spatialCategoryCountryCode[0].country_data.iso_code_v2;
        }
      }
    }

    // load regions
    this.spatialCategoryRegions = [];
    if (data.spatialCategoryRegions) {
      if (data.spatialCategoryRegions.length) {
        let regionObj = {
          address: null,
          label: null
        };
        let regions = [];


        data.spatialCategoryRegions.forEach(address => {
          // wrapper for load to work in sender form after fixSpatialData (because in sender form it will already be correctly formatted)
          if (address.address) { address = address.address }

          regionObj['label'] = this.addressService.getAddressLabel(address);
          regionObj['address'] = address;
          regions.push(JSON.parse(JSON.stringify(regionObj)));
        });
        this.spatialCategoryRegions = [...regions];
      }
    }

    // load postal codes
    this.spatialCategoryPostalCodes = [];
    if (data.spatialCategoryPostalCodes) {
      if (data.spatialCategoryPostalCodes.length) {
        let regionObj = {
          address: null,
          label: null
        };
        let postalCodes = [];

        data.spatialCategoryPostalCodes.forEach(address => {
          // wrapper for load to work in sender form after fixSpatialData (because in sender form it will already be correctly formatted)
          if (address.address) { address = address.address }

          regionObj['label'] = this.addressService.getPostalAddressLabel(address);
          regionObj['address'] = address;
          postalCodes.push(JSON.parse(JSON.stringify(regionObj)));
        });

        this.spatialCategoryPostalCodes = [...postalCodes];
      }
    }

    this.chargeCategoryItems = [];
    (<FormArray>this.myForm.controls['charge_category_groups']).controls = [];
    if (data.charge_category_groups) {
      data.charge_category_groups.forEach((group, index) => {
        this.addChargeCategoryGroup(group, index);
      });
    }

    this.patchFormAfterLoad();
  }

  onAddressChange() {
    this.extraChargesMapComponent.removePolygons();
    this.spatialCategoryRegions = this.myForm.value.spatialCategoryRegions;
    this.spatialCategoryPostalCodes = [];
    this.countryCode = null;
    this.geofence = '';
    this.myForm.patchValue({
      'spatialCategoryPostalCodes': this.spatialCategoryPostalCodes,
      'spatialCategoryCountryCode': {
        'iso_code_v2': this.countryCode,
      },
      'spatialCategoryGeofence': {
        'geofence': this.geofence,
      }
    });
    M.updateTextFields();
  }

  onPostalCodeChange() {
    this.extraChargesMapComponent.removePolygons();
    this.spatialCategoryPostalCodes = this.myForm.value.spatialCategoryPostalCodes;
    this.spatialCategoryRegions = [];
    this.countryCode = null;
    this.geofence = '';
    this.myForm.patchValue({
      'spatialCategoryRegions': this.spatialCategoryRegions,
      'spatialCategoryCountryCode': {
        'iso_code_v2': this.countryCode,
      },
      'spatialCategoryGeofence': {
        'geofence': this.geofence,
      }
    });
    M.updateTextFields();
  }

  countrySelectChange(event) {
    this.countryCode = this.myForm.value.spatialCategoryCountryCode.iso_code_v2;
    this.spatialCategoryRegions = [];
    this.spatialCategoryPostalCodes = [];
    if (this.countryCode) {
      this.extraChargesMapComponent.removePolygons();
      this.geofence = '';
      this.myForm.patchValue({
        'spatialCategoryRegions': this.spatialCategoryRegions,
        'spatialCategoryPostalCodes': this.spatialCategoryPostalCodes,
        'spatialCategoryGeofence': {
          'geofence': this.geofence,
        },
      });

      // if no name has been given, then autofill with country name
      if (!this.spatialCategoryName && event.name) {
        this.spatialCategoryName = event.name;
        this.myForm.patchValue({
          'name': this.spatialCategoryName,
        });
      }
    } else {
      this.countryCode = null;
      this.myForm.patchValue({
        'spatialCategoryRegions': this.spatialCategoryRegions,
        'spatialCategoryPostalCodes': this.spatialCategoryPostalCodes,
        'spatialCategoryCountryCode': {
          'iso_code_v2': this.countryCode,
        }
      });
    }
    M.updateTextFields();
  }

  resetForm() {
    this.spatialCategoryId = null;
    this.spatialCategoryName = '';
    this.geofence = '';
    this.countryCode = null;
    this.chargeCategoryItems = [];
    this.tempId = null;
    this.lastIndexToAddZone = 0;
    this.spatialCategoryRegions = [];
    this.spatialCategoryPostalCodes = [];
    // this.selectedCountry = null;
    this.extraChargesMapComponent.emptyDrawMode();
    this.patchFormAfterLoad();
  }

  displayAllRegionsChange(event) {
    if (event.target.checked) {
      this.http.get('api/v1/spatial-category-geofences').pipe(take(1)).subscribe(response => {
        const regions = [];
        if (response['items']) {
          response['items'].forEach(geofenceData => {
            if (geofenceData.geofence && geofenceData.spatial_category_id !== this.spatialCategoryId) {
              regions.push(geofenceData.geofence);
            }
          });
          if (regions.length) {
            this.extraChargesMapComponent.loadAllRegions(regions);
          }
        }
      });
    } else {
      this.extraChargesMapComponent.removeRegions();
    }
  }

  geofenceChangedListen(event) {
    if (event) {
      this.geofence = event;
      this.countryCode = null;
      this.myForm.patchValue({
        'spatialCategoryGeofence': {
          'geofence': this.geofence,
        },
        'spatialCategoryCountryCode': {
          'iso_code_v2': this.countryCode,
        }
      });
    }
  }


  countrySelectFocusOut() { }

  zonesSelectChange(event, index) {
    this.selectedZonesPerGroupIndex[index] = event;
  }

  openZoneModal(zone) {
    let zoneData = null;
    if (zone.value) {
      zoneData = this.zonesById[zone.value];
      // if (this.globals.chargeZonesById[zone.id]) {
      //   zoneData = this.globals.chargeZonesById[zone.id];
      // } else if (this.zonesById[zone.id]) {
      //   zoneData = this.zonesById[zone.id];
      // }
    }
    else {
      zoneData = this.zonesById['temp-' + zone.tempId];
    }

    this.settingsService.openZoneChargesModal(zoneData);
  }

  openZoneModalForRegionCharge(index) {
    this.lastIndexToAddZone = index;
    this.settingsService.openZoneChargesModal('forRegionCharge');
  }

  addZoneInChargeCategoryGroup(zone, index) {
    if (zone && (index !== null)) {
      const newZoneId = zone['id'];
      if (newZoneId) {
        const selectedZoneIds = this.myForm.value.charge_category_groups[index]['charge_category_group']['charge_category_ids'];
        selectedZoneIds.push(newZoneId);

        // select the zones in the ng select dropdown
        setTimeout(() => {
          const ngSelectArray = this.ngSelects.toArray();
          if (ngSelectArray[index]) {
            let item = ngSelectArray[index].itemsList.findItem(newZoneId);
            if (item) {
              ngSelectArray[index].select(item);
            }
          }
        }, 100);
      }
    }
  }

  removeChargeCategoryGroup(index) {
    const id = this.chargeCategoryItems[index]['charge_category_group']['id'];

    (<FormArray>this.myForm.controls['charge_category_groups']).removeAt(index);

    this.chargeCategoryItems.splice(index, 1);
    if (!this.chargeCategoryItems.length) {
      this.addChargeCategoryGroup(null);
    }

    const data = {
      chargeCategoryGroupIdsToDelete: [id]
    };
    this.http.post('api/v1/delete-charges', data).pipe(take(1)).subscribe(response => {
      this.settingsService.updateRegionalChargesGrid();
    });
  }

  addChargeCategoryGroup(data, index = null) {
    if (!data) {
      data = {
        charge_category_group: {
          id: null,
          is_import: false,
          is_export: false,
          extra_charge_string: '',
          charge_category_ids: [],
          temp_id: null
        }
      }
    }

    let chargeCategoryIds = [];
    if (data.charge_category_group.chargeCategories) {
      data.charge_category_group.chargeCategories.forEach(chargeCategory => {
        if (!chargeCategoryIds.includes(chargeCategory.id)) {
          chargeCategoryIds.push(chargeCategory.id);
        }
      });
    }
    if (!chargeCategoryIds.length && data.charge_category_group.charge_category_ids) {
      chargeCategoryIds = data.charge_category_group.charge_category_ids;
    }

    const subForm = this.formBuilder.group({
      charge_category_group: this.formBuilder.group({
        id: data.charge_category_group.id,
        is_import: data.charge_category_group.is_import,
        is_export: data.charge_category_group.is_export,
        extra_charge_string: data.charge_category_group.extra_charge ? String(data.charge_category_group.extra_charge) : '',
        charge_category_ids: this.formBuilder.array(chargeCategoryIds),
      })
    });
    (<FormArray>this.myForm.controls['charge_category_groups']).push(subForm);

    this.chargeCategoryItems.push(data);

    // select the zones in the ng select dropdown, after it is done repopulating
    if (chargeCategoryIds.length) {
      setTimeout(() => {
        const ngSelectArray = this.ngSelects.toArray();
        if (ngSelectArray[index]) {
          chargeCategoryIds.forEach(id => {
            let item = ngSelectArray[index].itemsList.findItem(id);
            if (item) {
              ngSelectArray[index].select(item);
            }
          });

        }
      }, 500);
    }
  }

  prepareDataForSubmit() {
    const data = this.myForm.value;

    // settings submit
    let totalZones = [];
    if (this.router.url.split('/')[1] == 'settings') {
      data.charge_category_ids = []; // empty array to prevent double charge creation on be
      totalZones = this.globals.chargeZones;
    } else {
      totalZones = this.modalService.collaboratorZones;
    }

    this.selectedZonesPerGroupIndex.forEach((zones, index) => {
      if (data.charge_category_groups[index]) {
        data.charge_category_groups[index]['charge_category_group']['charge_category_ids'] = [];
        data.charge_category_groups[index]['charge_category_group']['chargeCategories'] = [];
        zones.forEach(zone => {

          if (zone.tempId) {
            // this.modalService.collaboratorZones.forEach(chargeCategory => {
            totalZones.forEach(chargeCategory => {
              if (zone.tempId == chargeCategory.temp_id) {
                if (chargeCategory.temp_id) {
                  const chargeCategoryData = chargeCategory;
                  if (chargeCategoryData) {
                    data.charge_category_groups[index]['charge_category_group']['chargeCategories'].push(chargeCategoryData);
                  }
                }
              }
            })
          }
          else {
            // this.modalService.collaboratorZones.forEach(chargeCategory => {
            totalZones.forEach(chargeCategory => {
              if (zone.value == chargeCategory.id) {
                const chargeCategoryData = chargeCategory;

                data.charge_category_groups[index]['charge_category_group']['charge_category_ids'].push(zone.value);
                data.charge_category_groups[index]['charge_category_group']['chargeCategories'].push(chargeCategoryData);

                // empty ids array if in settings (prevents duplicate charge category creation)
                data.charge_category_groups[index]['charge_category_group']['charge_category_ids'] = [];
              }
            });
          }
        });
      }
    });
    data.charge_category_groups.forEach(group => {
      if (group['charge_category_group']['extra_charge_string']) {
        const stringFixed = group['charge_category_group']['extra_charge_string'].replace(/,/g, '.');
        group['charge_category_group']['extra_charge'] = parseFloat(stringFixed).toFixed(2);
      } else {
        group['charge_category_group']['extra_charge'] = 0;
      }
    });
    if (data.spatialCategoryCountryCode) {
      if (!data.spatialCategoryCountryCode.iso_code_v2) {
        delete data['spatialCategoryCountryCode'];
      }
    }

    return data;
  }

  submitForm() {
    // settings
    if (this.myForm.value?.spatialCategoryCountryCode?.iso_code_v2
      || this.myForm.value?.spatialCategoryGeofence?.geofence
      || this.myForm.value?.spatialCategoryPostalCodes?.length
      || this.myForm.value?.spatialCategoryRegions?.length) {
      let url = this.spatialCategoryUrl;
      const data = this.prepareDataForSubmit();
      const requestData = { spatialCategories: [{ ...data }] }
      if (this.spatialCategoryId) {
        url += '/' + this.spatialCategoryId;
        return this.http.put(url, requestData);
      } else {
        return this.http.post(url, requestData);
      }
    } else {
      alert(this.regionsAlert);
    }
  }

  submitOnCollaboratorForm() {
    // collaborator form
    if (this.myForm.value.spatialCategoryCountryCode.iso_code_v2
      || this.myForm.value.spatialCategoryGeofence.geofence
      || this.myForm.value.spatialCategoryPostalCodes.length
      || this.myForm.value.spatialCategoryRegions.length) {
      const data = this.prepareDataForSubmit();
      this.modalService.updateCollaboratorsChargesRegionForm(data);
      this.settingsService.closeRegionChargesModal();
    } else {
      alert(this.regionsAlert);
    }
  }

  populateZonesDropdown(zone = null) {
    const self = this;
    const zonesDataRefreshIntervalId = setInterval(zonesDataChecker, 200);
    function zonesDataChecker() {
      if (self.globals.zonesDataDone) {
        clearInterval(zonesDataRefreshIntervalId);
        const zonesOptions = [];
        self.globals.chargeZones.forEach(zone => {
          zonesOptions.push({
            value: zone.id,
            label: zone.name
          });
        });
        self.zones = zonesOptions;
        self.selectedZonesPerGroupIndex.forEach((zones, index) => {
          zones.forEach(zone => {
            if (self.globals.chargeZonesById[zone.value]) {
              zone.label = self.globals.chargeZonesById[zone.value]['name'];
            } else if (self.zonesById[zone.value]) {
              zone.label = self.zonesById[zone.value]['name'];
            }
          });
        });
        if (zone) {
          self.addZoneInChargeCategoryGroup(zone, self.lastIndexToAddZone);
        }
        // if we are not in settings, show collaborator zones
        if (self.router.url.split('/')[1] !== 'settings') {
          self.checkForCollaboratorZones();
        }
      }
    }
  }

  checkForCollaboratorZones() {
    // const zonesOptions = this.zones; // replaced with empty array because it duplicated zones in new collaborator
    const zonesOptions = [];
    this.zonesById = {};
    this.modalService.collaboratorZones.forEach(zone => {
      // if (zone.id) {
      zonesOptions.push({
        tempId: zone.temp_id,
        value: zone.id,
        label: zone.name,
      });
      if (zone.id) {
        this.zonesById[zone.id] = zone;
      } else {
        this.zonesById['temp-' + zone.temp_id] = zone;
      }
      // }
    });
    this.zones = zonesOptions;
  }

  getTranslations() {
    this.listen.push(this.translate.get('SETTINGS.REGIONS_ALERT').subscribe((res: string) => { this.regionsAlert = res; }));
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
    // country
    this.populateZonesDropdown();

    // addresses / regions
    this.addressesDropdownOptions = concat(
      of([]), // default items
      this.addressesInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.addressesLoading = true),
        switchMap(term => this.dataService.getAddresses(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.addressesLoading = false)
        ))
      )
    );

    // postal code addresses
    this.postalCodeDropdownOptions = concat(
      of([]), // default items
      this.postalCodeInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.postalCodeLoading = true),
        switchMap(term => this.dataService.getAddressesPostalCodes(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.postalCodeLoading = false)
        ))
      )
    );

    const countriesObject = countries.getNames(this.globals.currentLang, { select: 'official' });
    if (countriesObject) {
      const countryDropdownOptions = [];
      Object.keys(countriesObject).forEach(countryCode => {
        const countryName = countriesObject[countryCode];
        countryDropdownOptions.push({
          name: countryName,
          countryCode: countryCode
        })
      });
      this.countryDropdownOptions = countryDropdownOptions;
    }
  }

  public ngAfterViewInit() { }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
