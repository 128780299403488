import { ModalService } from '@app/services/modal.service';
import { Globals } from '@app/services/globals';
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SettingsService } from '@app/services/settings.service';
import { of } from 'rxjs';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
import { Router } from '@angular/router';
import { WeightChargeModalComponent } from '@app/modals/weight-charge-modal/weight-charge-modal.component';
import { eventNames } from 'process';

@Component({
  selector: 'app-weight-charges-grid',
  templateUrl: './weight-charges-grid.component.html',
  styleUrls: ['./weight-charges-grid.component.scss']
})
export class WeightChargesGridComponent implements OnInit, OnDestroy {
  @ViewChild('weightChargesGrid', { static: false }) weightChargesGrid: AgGridAngular;
  @ViewChild(WeightChargeModalComponent, { static: false }) weightChargeModalComponent: WeightChargeModalComponent;

  listen = [];
  searchString: String = '';

  gridApi;
  gridColumnApi;
  domLayout = 'autoHeight';
  weightChargesDataArray = [];
  deleteDefaultChargesIds = [];
  deleteAdditionalChargesIds = [];
  rowData: any;
  columnDefs;
  tempIdCounter = 1;

  weightType: string;
  confines: string;
  untilLabel: string;
  forEveryLabel: string;
  fromToLabel: string;
  currencyLabel: string;

  collaboratorExtraCharges;
  collaboratorId;

  // widths
  confinesWidth;
  measureWidth;
  amountWidth;
  actionsWidth;

  confinesTitle: string;
  measureTitle: string;
  amountTitle: string;
  actionsTitle: string;
  gridData: any;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private settingsService: SettingsService,
    public gridsService: GridsService,
    public modalService: ModalService,
    public globals: Globals,
    public router: Router,
  ) {
    this.listen.push(this.modalService.updateWeightChargesListen().subscribe((data) => {
      // edit already saved charge
      if (data.id) {
        if (data.confines) {
          this.gridData.additionalWeightCharge = {
            id: data.id,
            price_value: data.price_value,
            charge_value: data.charge_value
          };
        } else {
          for (let index = 0; index < this.gridData.defaultWeightCharges.length; index++) {
            if (this.gridData.defaultWeightCharges[index].id == data.id) {
              this.gridData.defaultWeightCharges[index] = data;
              break;
            }
          }
        }
      }

      // create/edit unsaved charge
      else {
        // replace unsaved additional charge
        if (data.confines) {
          this.gridData.additionalWeightCharge = {
            id: null,
            price_value: data.price_value,
            charge_value: data.charge_value,
          };
        }

        // create/edit unsaved additional charge
        else {
          // edit unsaved charge
          if (data.temp_id) {
            for (let index = 0; index < this.gridData.defaultWeightCharges.length; index++) {
              if (this.gridData.defaultWeightCharges[index].temp_id == data.temp_id) {
                this.gridData.defaultWeightCharges[index] = data;
                break;
              }
            }
          }

          // create unsaved charge
          else {
            data.temp_id = this.tempIdCounter;
            this.tempIdCounter++;
            this.gridData.defaultWeightCharges.push(data);
          }
        }
      }

      this.updateGrid(this.gridData);
      this.modalService.updateWeightChargesForm(this.gridData);
    }));
  }

  setWeightChargesGridData() {

  }

  updateGrid(data) {
    let gridObject = {};
    this.gridData = data;
    this.weightChargesDataArray = [];

    if (data) {
      if (data.defaultWeightCharges.length) {
        data.defaultWeightCharges.forEach(charge => {
          let confinesLabel = this.untilLabel + ' ' + charge.charge_value;

          gridObject = {
            id: charge.id,
            confines: confinesLabel,
            confinesValue: 0,
            measure: this.weightType + " (kg)",
            amount: charge.price_value + this.currencyLabel,
            actions: 'X',
            objData: charge
          };
          this.weightChargesDataArray.push(gridObject);
        });
      }

      if (data.additionalWeightCharge) {
        let confinesLabel = this.forEveryLabel + ' ' + data.additionalWeightCharge.charge_value;

        gridObject = {
          id: data.additionalWeightCharge.id,
          confines: confinesLabel,
          confinesValue: 1,
          measure: this.weightType + " (kg)",
          amount: data.additionalWeightCharge.price_value + this.currencyLabel,
          actions: 'X',
          objData: data.additionalWeightCharge
        };
        this.weightChargesDataArray.push(gridObject);
      }

      if (!data.defaultWeightCharges.length && !data.additionalWeightCharge) {
        const noData = { noDataText: 'No data' }
        this.weightChargesDataArray.push(noData);
      }
    } else {
      const noData = { noDataText: 'No data' }
      this.weightChargesDataArray.push(noData);
    }

    this.rowData = of(this.weightChargesDataArray);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  rowClicked(event) {
    const target = event.event.target.closest('div');
    if (!event.data.noDataText && !target.classList.contains('actions-container')) {
      let data = {
        rowData: event.data.objData,
        gridData: this.gridData,
        confinesValue: event.data.confinesValue
      }

      this.settingsService.openWeightChargesModal(data);
    }
  }

  onFirstDataRendered(params) { }

  newWeightCharge() {
    let data = {
      rowData: null,
      gridData: this.gridData
    }
    this.settingsService.openWeightChargesModal(data);
  }

  deleteWeightCharge(event) {
    // additional charge
    if (event.data.confinesValue) {
      if (event.data.id) {
        // remove existing additional charge
        if (this.gridData.additionalWeightCharge.id) {
          this.deleteAdditionalChargesIds.push(this.gridData.additionalWeightCharge.id);
          this.gridData.additionalWeightCharge = null;
          this.updateGrid(this.gridData);
          this.modalService.updateWeightChargesForm(this.gridData);
        }
        // remove new (submitted) additional charge
        else {
          this.gridData.additionalWeightCharge = null;
          this.updateGrid(this.gridData);
          this.modalService.updateWeightChargesForm(this.gridData);
        }
      }
    }

    // default charges
    else {
      if (event.data.id) {
        for (let index = 0; index < this.gridData.defaultWeightCharges.length; index++) {
          // remove existing default charge
          if (this.gridData.defaultWeightCharges[index].id) {
            if (this.gridData.defaultWeightCharges[index].id == event.data.id) {
              this.deleteDefaultChargesIds.push(this.gridData.defaultWeightCharges[index].id);
              this.gridData.defaultWeightCharges.splice(index, 1);
              this.updateGrid(this.gridData);
              this.modalService.updateWeightChargesForm(this.gridData);
              break;
            }
          }
        }
      }

      // remove new (submitted) default charge
      else {
        for (let index = 0; index < this.gridData.defaultWeightCharges.length; index++) {
          if (this.gridData.defaultWeightCharges[index].temp_id) {
            if (this.gridData.defaultWeightCharges[index].temp_id == event.data.objData.temp_id) {
              this.gridData.defaultWeightCharges.splice(index, 1);
              this.updateGrid(this.gridData);
              this.modalService.updateWeightChargesForm(this.gridData);
              break;
            }
          }
        }
      }
    }
  }

  // custom renderer for actions
  actionsRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      columnObject += '<div class="single-cell standard-width actions-container">' + '<i class="fas fa-times fa-lg actions-delete"></i>' + '</div>';
    }
    return columnObject;
  }

  getTranslations() {
    this.listen.push(this.translate.get('SETTINGS.CONFINES').subscribe((res: string) => { this.confinesTitle = res; }));
    this.listen.push(this.translate.get('SETTINGS.MEASURE').subscribe((res: string) => { this.measureTitle = res; }));
    this.listen.push(this.translate.get('SETTINGS.COST_AMOUNT').subscribe((res: string) => { this.amountTitle = res; }));
    this.listen.push(this.translate.get('SETTINGS.ACTIONS').subscribe((res: string) => { this.actionsTitle = res; }));
    this.listen.push(this.translate.get('SETTINGS.WEIGHT_TYPE').subscribe((res: string) => { this.weightType = res; }));
    this.listen.push(this.translate.get('SETTINGS.CHARGE_UNTIL').subscribe((res: string) => { this.untilLabel = res; }));
    this.listen.push(this.translate.get('SETTINGS.CHARGE_FOR_EVERY').subscribe((res: string) => { this.forEveryLabel = res; }));
    this.listen.push(this.translate.get('SETTINGS.FROM_TO').subscribe((res: string) => { this.fromToLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currencyLabel = res; }));

    this.updateColumnDefs();
  }

  updateColumnDefs() {
    this.columnDefs = [
      { headerName: this.confinesTitle, field: 'confines', width: this.gridsService.widthCalculatorContainerId(this.confinesWidth, 'weight-charges-container') },
      { headerName: this.measureTitle, field: 'measure', width: this.gridsService.widthCalculatorContainerId(this.measureWidth, 'weight-charges-container') },
      { headerName: this.amountTitle, field: 'amount', width: this.gridsService.widthCalculatorContainerId(this.amountWidth, 'weight-charges-container') },
      {
        headerName: this.actionsTitle,
        field: 'actions',
        cellRenderer: this.actionsRenderer,
        onCellClicked: this.deleteWeightCharge.bind(this),
        width: this.gridsService.widthCalculatorContainerId(this.actionsWidth, 'weight-charges-container')
      },
    ]
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    // set widths for columns based on url
    if (this.router.url === '/settings') {
      this.confinesWidth = 40;
      this.measureWidth = 20;
      this.amountWidth = 25;
      this.actionsWidth = 15;
    }
    else if (this.router.url.split('/')[2] == 'collaboratorView'
      || this.router.url.split('/')[1] == 'customerCollaborators') {
      this.confinesWidth = 30;
      this.measureWidth = 30;
      this.amountWidth = 20;
      this.actionsWidth = 20;
    }

    this.updateColumnDefs();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
