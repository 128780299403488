import { DepotUtils } from '@app/utils/depot-utils';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, take } from 'rxjs/operators';
import { DataService } from '@app/services/data.service';
import { ModalGridService } from '@app/services/modal-grid.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { AddressService } from '@app/services/address.service';
import { ModalService } from '@app/services/modal.service';
import { Router } from '@angular/router';
import { WeightChargesGridComponent } from '@app/grids/weight-charges-grid/weight-charges-grid.component';
import { WeightChargeModalComponent } from '@app/modals/weight-charge-modal/weight-charge-modal.component';
import { ServicesChargesGridComponent } from '@app/grids/services-charges-grid/services-charges-grid.component';
import { ZoneChargesGridComponent } from '@app/grids/zone-charges-grid/zone-charges-grid.component';
import { RegionChargesGridComponent } from '@app/grids/region-charges-grid/region-charges-grid.component';
import { CustomerCollaboratorMapComponent } from '../customer-collaborators-form/customer-collaborator-map/customer-collaborator-map.component';
import { GenericService } from '@app/services/generic.service';
import { CollaboratorDiscountsGridComponent } from '@app/grids/collaborator-discounts-grid/collaborator-discounts-grid.component';

declare var H: any;

@Component({
  selector: 'app-partner-form',
  templateUrl: './partner-form.component.html',
  styleUrls: ['./partner-form.component.scss']
})
export class PartnerFormComponent implements OnInit, AfterViewInit {
  @ViewChild('inviteCollaboratorButton', { static: false }) inviteCollaboratorButton;
  @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
  @ViewChild(CustomerCollaboratorMapComponent, { static: false }) customerCollaboratorMapComponent: CustomerCollaboratorMapComponent;
  @ViewChild(WeightChargesGridComponent, { static: false }) weightChargesGrid: WeightChargesGridComponent;
  @ViewChild(WeightChargeModalComponent, { static: false }) weightChargeModalComponent: WeightChargeModalComponent;
  @ViewChild(ServicesChargesGridComponent, { static: false }) servicesChargesGrid: ServicesChargesGridComponent;
  @ViewChild(ZoneChargesGridComponent, { static: false }) zoneChargesGridComponent: ZoneChargesGridComponent;
  @ViewChild(RegionChargesGridComponent, { static: false }) regionChargesGridComponent: RegionChargesGridComponent;
  @ViewChild(CollaboratorDiscountsGridComponent, { static: false }) collaboratorDiscountsGridComponent: CollaboratorDiscountsGridComponent;

  @Output() dataLoading = new EventEmitter<string>();

  @Output() toggleModal = new EventEmitter<string>();

  @ViewChild('ngSelect', { static: false }) ngSelect: NgSelectComponent;
  @ViewChild('ngSelectDepotAddress', { static: false }) ngSelectDepotAddress: NgSelectComponent;

  myForm: FormGroup;
  emptyFormValues;
  listen = [];
  isClickedOnce = false;

  // fields
  collaboratorId;
  delivery;
  lineHaul;
  createVoucher;
  collaboratorName;
  telephone = [];
  phoneNumber;
  countryPrefix;
  address;
  collaboratorHome;
  collaboratorEmail;
  taxOffice;
  tin;
  // collaboratorPrice;
  invoiceDaysItems = [5, 10, 15, 30, 45, 60];
  invoiceDays = 30;
  payReminderDaysItems = [1, 3, 5, 7, 10, 15, 30];
  payReminderDays = 5;
  collaboratorToPayAmount;
  collaboratorToGetPaidAmount;
  liveTracking = false;
  selfPickup = false;
  isViberMessageTemplateEnabled;
  viberMessageTemplate;
  drivers;
  driver = null;
  driverId;
  canPrint;
  spatialCategories = [];
  chargeCategories = [];
  discounts = [];
  chargeCategoryIdsToBeDeleted = [];
  spatialCategoryIdsToBeDeleted = [];
  isMember;
  canBeInvited;
  connectionId;
  collaboratorDepotAddressId = null;
  tempIdRegionCounter = 1;
  tempIdZoneCounter = 1;
  partnerType = this.globals.companyModes['NORMAL'];
  isAutoInvoiceApprovalEnabled;
  canAccessSystemicVoucher;
  iban;
  bankName;

  // address fields
  countryCode = '';
  state = '';
  county = '';
  city = '';
  district = '';
  street = '';
  street2 = '';
  houseNumber = '';
  postalCode = '';
  isPlace = false;
  placeName = '';
  freeformAddress;
  lat;
  lon;

  depotCountryCode = '';
  depotState = '';
  depotCounty = '';
  depotCity = '';
  depotDistrict = '';
  depotStreet = '';
  depotStreet2 = '';
  depotHouseNumber = '';
  depotPostalCode = '';
  depotIsPlace = false;
  depotPlaceName = '';
  depotFreeformAddress;
  depotLat;
  depotLon;

  addresses: Observable<any>;
  addressesLoading = false;
  addressInput = new Subject<string>();
  selectedAddress: any = <any>{};

  depotAddresses: Observable<any>;
  depotAddressInput = new Subject<string>();
  depotAddressesLoading = false;
  selectedDepotAddress: any = <any>{};

  // map
  positionSet = false;

  // driver
  selectedDriver;
  driversObject = {};

  // depot
  depotId;
  depots = [];
  selectedDepotItem;

  discount = null;
  discountOptions = [];

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public globals: Globals,
    private dataService: DataService,
    private modalService: ModalService,
    private modalGridService: ModalGridService,
    public translate: TranslateService,
    private addressService: AddressService,
    private genericService: GenericService,
    public router: Router,
    public depotUtils: DepotUtils
  ) {
    this.listen.push(this.modalService.openCustomerCollaboratorsModalListen().subscribe((data) => {
      if (data) {
        this.loadPartnersData(data);
      } else {
        this.newPartnerSetData();
      }
    }));

    // delete a zone charge 
    this.listen.push(this.modalService.deleteCollaboratorsChargesFormListen().subscribe((charge) => {
      // delete saved
      if (charge.id) {
        if (typeof charge.id !== 'string') {
          this.chargeCategoryIdsToBeDeleted.push(charge.id);
        }
        let indexToBeRemoved = null;
        for (let index = 0; index < this.chargeCategories.length; index++) {
          if (this.chargeCategories[index].id == charge.id) {
            indexToBeRemoved = index;
            break;
          }
        }
        if (!isNaN(indexToBeRemoved)) {
          if (this.chargeCategories[indexToBeRemoved]) {
            this.chargeCategories.splice(indexToBeRemoved, 1);
          }
        }
      }

      else {
        // delete existing unsaved
        if (charge.temp_id) {
          let indexToBeRemoved = null;
          for (let index = 0; index < this.chargeCategories.length; index++) {
            if (this.chargeCategories[index].temp_id == charge.temp_id) {
              indexToBeRemoved = index;
              // this.chargeCategories[index] = charge;
              break;
            }
          }
          if (!isNaN(indexToBeRemoved)) {
            if (this.chargeCategories[indexToBeRemoved]) {
              this.chargeCategories.splice(indexToBeRemoved, 1);
            }
          }
        }
      }
      const spatialCategories = [...this.spatialCategories]
      spatialCategories.forEach((spatialCategory, spatialCategoryIndex) => {
        spatialCategory.charge_category_groups.forEach((chargeCategoryGroup, chargeCategoryGroupIndex) => {
          chargeCategoryGroup.charge_category_group.chargeCategories.forEach((chargeCategory, chargeCategoryIndex) => {
            if (chargeCategory.id === charge.id) {
              this.spatialCategories[spatialCategoryIndex]['charge_category_groups'][chargeCategoryGroupIndex]['charge_category_group']['chargeCategories'].splice(chargeCategoryIndex, 1);
            }
          });
        });
      });
      this.myForm.patchValue({
        'companyCollaborator': {
          'connection': {
            'chargeCategories': this.chargeCategories,
            'spatialCategories': this.spatialCategories,
          },
        },
      });

      // delete charge category in all spatial charges, too
      this.deleteZoneInRegionsSubmit(charge);
    }));

    // delete a region charge 
    this.listen.push(this.modalService.deleteCollaboratorsRegionFormListen().subscribe((charge) => {
      // delete saved
      if (charge.id) {
        if (typeof charge.id !== 'string') {
          this.spatialCategoryIdsToBeDeleted.push(charge.id);
        }
        let indexToBeRemoved = null;
        for (let index = 0; index < this.spatialCategories.length; index++) {
          if (this.spatialCategories[index].id == charge.id) {
            indexToBeRemoved = index;
            break;
          }
        }
        if (!isNaN(indexToBeRemoved)) {
          if (this.spatialCategories[indexToBeRemoved]) {
            this.spatialCategories.splice(indexToBeRemoved, 1);
          }
        }
      }

      else {
        // delete existing unsaved
        if (charge.temp_id) {
          let indexToBeRemoved = null;
          for (let index = 0; index < this.spatialCategories.length; index++) {
            if (this.spatialCategories[index].temp_id == charge.temp_id) {
              indexToBeRemoved = index;
              break;
            }
          }
          if (!isNaN(indexToBeRemoved)) {
            if (this.spatialCategories[indexToBeRemoved]) {
              this.spatialCategories.splice(indexToBeRemoved, 1);
            }
          }
        }
      }
      this.myForm.patchValue({
        'companyCollaborator': {
          'connection': {
            'spatialCategories': this.spatialCategories,
          },
        },
      });
    }));

    this.listen.push(this.modalService.updateCollaboratorsChargesFormListen().subscribe((charge) => {
      // edit saved
      if (charge.id) {
        for (let index = 0; index < this.chargeCategories.length; index++) {
          if (this.chargeCategories[index].id == charge.id) {
            this.chargeCategories[index] = charge;
            break;
          }
        }
      }

      else {
        // edit existing unsaved
        if (charge.temp_id) {
          for (let index = 0; index < this.chargeCategories.length; index++) {
            if (this.chargeCategories[index].temp_id == charge.temp_id) {
              this.chargeCategories[index] = charge;
              break;
            }
          }
        }
        // add new unsaved
        else {
          charge.temp_id = this.tempIdZoneCounter;
          this.tempIdZoneCounter++;
          this.chargeCategories.push(charge);
        }
      }

      // update form & grid
      this.myForm.patchValue({
        'companyCollaborator': {
          'connection': {
            'chargeCategories': this.chargeCategories,
          },
        },
      });
      this.zoneChargesGridComponent.setZoneChargesGridDataWithSpecificData(this.chargeCategories);

      // update the changed charge in regions grid
      this.updateZoneInRegionsSubmit(charge);

      // update collaborator's available zones for discounts
      this.collaboratorDiscountsGridComponent.updateAvailableZones();
    }));

    this.listen.push(this.modalService.updateCollaboratorsChargesRegionFormListen().subscribe((charge) => {
      // edit saved
      if (charge.id) {
        for (let index = 0; index < this.spatialCategories.length; index++) {
          if (this.spatialCategories[index].id == charge.id) {
            this.spatialCategories[index] = charge;
            break;
          }
        }
      }

      else {
        // edit existing unsaved
        if (charge.temp_id) {
          for (let index = 0; index < this.spatialCategories.length; index++) {
            if (this.spatialCategories[index].temp_id == charge.temp_id) {
              this.spatialCategories[index] = charge;
              break;
            }
          }
        }
        // add new unsaved
        else {
          charge.temp_id = this.tempIdRegionCounter;
          this.tempIdRegionCounter++;
          this.spatialCategories.push(charge);
        }
      }

      this.myForm.patchValue({
        'companyCollaborator': {
          'connection': {
            'spatialCategories': this.spatialCategories,
          },
        },
      });
      this.regionChargesGridComponent.setRegionalChargesGridDataWithSpecificData(this.spatialCategories);
    }));

    this.myForm = formBuilder.group({
      'companyCollaborator': formBuilder.group({
        'connection': formBuilder.group({
          'id': [this.connectionId],
          'chargeCategories': [this.chargeCategories],
          'spatialCategories': [this.spatialCategories],
          'discounts': [this.discounts],
          'depot': [this.selectedDepotItem],
          'depot_id': [this.depotId],
          'live_tracking': [this.liveTracking],
          'self_pickup': [this.selfPickup],
          'is_delivery': [this.delivery],
          'can_create_new_voucher': [this.createVoucher],
          'is_line_haul': [this.lineHaul],
          'driver': [{ value: this.driver, disabled: true }],
          'driver_id': [this.driverId],
          'can_print': [this.canPrint],
          'partner_type': [this.partnerType],
          'is_auto_invoice_approval_enabled': [this.isAutoInvoiceApprovalEnabled],
          'can_access_systemic_voucher': [this.canAccessSystemicVoucher],
          'iban': [this.iban],
          'bank_name': [this.bankName]
        }),
        'collaborator': formBuilder.group({
          'address': formBuilder.group({
            'countryCode': [this.countryCode],
            'state': [this.state],
            'county': [this.county],
            'city': [this.city],
            'district': [this.district],
            'street': [this.street],
            'street2': [this.street2],
            'houseNumber': [this.houseNumber],
            'postalCode': [this.postalCode],
            'isPlace': [this.isPlace],
            'placeName': [this.placeName],
            'value': [this.freeformAddress],
            'lat': [this.lat],
            'lon': [this.lon],
            'term': [this.selectedAddress],
          }),
          'collaborator_depot': formBuilder.group({
            'address': formBuilder.group({
              'id': this.collaboratorDepotAddressId,
              'countryCode': [this.depotCountryCode],
              'state': [this.depotState],
              'county': [this.depotCounty],
              'city': [this.depotCity],
              'district': [this.depotDistrict],
              'street': [this.depotStreet],
              'street2': [this.depotStreet2],
              'houseNumber': [this.depotHouseNumber],
              'postalCode': [this.depotPostalCode],
              'isPlace': [this.depotIsPlace],
              'placeName': [this.depotPlaceName],
              'value': [this.depotFreeformAddress],
              'lat': [this.depotLat],
              'lon': [this.depotLon],
              'term': [this.selectedDepotAddress],
            }),
          }),
          'collaboratorData': formBuilder.group({
            'id': [this.collaboratorId],
            // 'collaborator_price': [this.collaboratorPrice],
            'collaborator_type': 1,
            'collaborator_name': [this.collaboratorName],
            'phoneNumber': [this.phoneNumber],
            'countryPrefix': [this.countryPrefix],
            'collaborator_home': [this.collaboratorHome], // mock field
            'tax_office': [this.taxOffice],
            'tin': [this.tin],
            'mail': [this.collaboratorEmail],
            'pay_on_delivery_days': [{ value: this.invoiceDays, disabled: true }],
            'pay_reminder_days': [{ value: this.payReminderDays, disabled: true }],
            'collaborator_to_get_paid_amount': '', // mock field
            'collaborator_to_pay_amount': '', // mock field
            'telephone': [this.telephone],
            'is_viber_message_template_enabled': [this.isViberMessageTemplateEnabled],
            'viber_message_template': [this.viberMessageTemplate]
          }),
        }),
      }),
    });

    this.emptyFormValues = this.myForm.value;
  }

  patchForm() {
    this.myForm.patchValue({
      'companyCollaborator': {
        'connection': {
          'id': this.connectionId,
          'chargeCategories': this.chargeCategories,
          'spatialCategories': this.spatialCategories,
          'discounts': this.discounts,
          'depot': this.selectedDepotItem,
          'depot_id': this.depotId,
          'live_tracking': this.liveTracking,
          'self_pickup': this.selfPickup,
          'is_delivery': this.delivery,
          'is_line_haul': this.lineHaul,
          'can_create_new_voucher': this.createVoucher,
          'driver': this.driver,
          'driver_id': this.driverId,
          'can_print': this.canPrint,
          'partner_type': this.partnerType,
          'is_auto_invoice_approval_enabled': this.isAutoInvoiceApprovalEnabled,
          'can_access_systemic_voucher': this.canAccessSystemicVoucher,
          'iban': this.iban,
          'bank_name': this.bankName
        },
        'collaborator': {
          'address': {
            'countryCode': this.countryCode,
            'state': this.state,
            'county': this.county,
            'city': this.city,
            'district': this.district,
            'street': this.street,
            'street2': this.street2,
            'houseNumber': this.houseNumber,
            'postalCode': this.postalCode,
            'isPlace': this.isPlace,
            'placeName': this.placeName,
            'value': this.freeformAddress,
            'lat': this.lat,
            'lon': this.lon,
            'term': this.selectedAddress,
          },
          'collaborator_depot': {
            'address': {
              'id': this.collaboratorDepotAddressId,
              'countryCode': this.depotCountryCode,
              'state': this.depotState,
              'county': this.depotCounty,
              'city': this.depotCity,
              'district': this.depotDistrict,
              'street': this.depotStreet,
              'street2': this.depotStreet2,
              'houseNumber': this.depotHouseNumber,
              'postalCode': this.depotPostalCode,
              'isPlace': this.depotIsPlace,
              'placeName': this.depotPlaceName,
              'value': this.depotFreeformAddress,
              'lat': this.depotLat,
              'lon': this.depotLon,
              'term': this.selectedDepotAddress,
            },
          },
          'collaboratorData': {
            'id': this.collaboratorId,
            // 'collaborator_price': this.collaboratorPrice,
            'collaborator_type': 1,
            'collaborator_name': this.collaboratorName,
            'phoneNumber': this.telephone,
            'countryPrefix': this.countryPrefix,
            'collaborator_home': this.collaboratorHome, // mock field
            'tax_office': this.taxOffice,
            'tin': this.tin,
            'mail': this.collaboratorEmail,
            'pay_on_delivery_days': this.invoiceDays,
            'pay_reminder_days': this.payReminderDays,
            'collaborator_to_get_paid_amount': this.collaboratorToGetPaidAmount, // mock field
            'collaborator_to_pay_amount': this.collaboratorToPayAmount, // mock field
            'is_viber_message_template_enabled': this.isViberMessageTemplateEnabled,
            'viber_message_template': this.viberMessageTemplate
          },
        },
      },
    });

    this.modalService.collaboratorZones = this.chargeCategories;

    // Load address input field value in form
    this.selectedAddress = {
      label: this.freeformAddress,
      position: [this.lat, this.lon],
    };
    this.selectedDepotAddress = {
      label: this.depotFreeformAddress,
      position: [this.depotLat, this.depotLon],
    };
    if (this.freeformAddress) {
      (<HTMLInputElement>document.getElementById('address-customer-collaborators-form')).value = this.freeformAddress.valueOf();
    }
    if (this.depotFreeformAddress) {
      (<HTMLInputElement>document.getElementById('depot-address-customer-collaborators-form')).value = this.depotFreeformAddress.valueOf();
    }

    setTimeout(() => {
      M.updateTextFields();
    }, 100);
  }

  fixSpatialData() {
    this.spatialCategories.forEach(data => {
      if (data.spatialCategoryCountryCode) {
        if (!data.spatialCategoryCountryCode.iso_code_v2) {
          if (data.spatialCategoryCountryCode.country_data) {
            data.spatialCategoryCountryCode['iso_code_v2'] = data.spatialCategoryCountryCode.country_data.iso_code_v2;
          }
        }
      }
      // data.charge_category_groups.forEach(group => {
      //   if (group.charge_category_group.chargeCategories) {
      //     const chargeCategoryIds = [];
      //     group.charge_category_group.chargeCategories.forEach(chargeCategory => {
      //       chargeCategoryIds.push(chargeCategory.id);
      //     });
      //     group.charge_category_group.charge_category_ids = chargeCategoryIds;
      //   }
      // });
    });
  }

  updateZoneInRegionsSubmit(charge) {
    let updatedSpatialCategoriesGridData = [];
    this.regionChargesGridComponent.regionalChargesDataArray.forEach(gridCharge => {

      if (!gridCharge.noDataText) {
        gridCharge.objData.charge_category_groups.forEach((group, groupIndex) => {
          group.charge_category_group.chargeCategories.forEach((chargeCategory, index) => {
            if (chargeCategory.id) {
              if (chargeCategory.id == charge.id) {
                gridCharge.objData.charge_category_groups[groupIndex].charge_category_group.chargeCategories[index] = charge;
              }
            }
            else {
              if (chargeCategory.temp_id == charge.temp_id) {
                gridCharge.objData.charge_category_groups[groupIndex].charge_category_group.chargeCategories[index] = charge;
              }
            }

          });
        });
        updatedSpatialCategoriesGridData.push(gridCharge.objData);
      }
    });

    // set spatial grid data
    this.modalService.setSpatialCollaborator(updatedSpatialCategoriesGridData)
  }

  deleteZoneInRegionsSubmit(charge) {
    let updatedSpatialCategoriesGridData = [];
    this.regionChargesGridComponent.regionalChargesDataArray.forEach(gridCharge => {

      if (gridCharge.objData) {
        gridCharge.objData.charge_category_groups[0].charge_category_group.chargeCategories.forEach((chargeCategory, index) => {

          if (chargeCategory.id) {
            if (chargeCategory.id == charge.id) {
              gridCharge.objData.charge_category_groups[0].charge_category_group.chargeCategories.splice(index, 1);
            }
          }
          else {
            if (chargeCategory.temp_id == charge.temp_id) {
              gridCharge.objData.charge_category_groups[0].charge_category_group.chargeCategories.splice(index, 1);
            }
          }
        });

        updatedSpatialCategoriesGridData.push(gridCharge.objData);
      }
    });

    // set spatial grid data
    this.modalService.setSpatialCollaborator(updatedSpatialCategoriesGridData)
  }

  toggleViberMessage() {
    this.isViberMessageTemplateEnabled = this.myForm.value.companyCollaborator.collaborator.collaboratorData.is_viber_message_template_enabled;
    this.myForm.patchValue({
      'companyCollaborator': {
        'collaborator': {
          'collaboratorData': {
            'is_viber_message_template_enabled': this.isViberMessageTemplateEnabled
          }
        }
      }
    });
  }

  loadPartnersData(data) {
    const collaborator = data.companyCollaborator.collaborator;
    const collaboratorData = collaborator.collaboratorData;
    const connection = data.companyCollaborator.connection;

    // load charges & discounts
    this.zoneChargesGridComponent.setZoneChargesGridData(collaboratorData.id);
    this.regionChargesGridComponent.setRegionalChargesGridData(collaboratorData.id);
    this.collaboratorDiscountsGridComponent.setDiscountsGridData(connection);

    // find depot address value based on depot_id
    let depotAddressValue;
    this.depotId = connection.depot_id;
    if (this.depotId) {
      this.globals.depotsArray.forEach(depot => {
        if (depot.companyDepot.id == connection.depot_id) {
          depotAddressValue = depot.companyDepot.address.value;
        }
      });
      this.selectedDepotItem = {
        companyDepot: {
          id: this.depotId,
          address: {
            value: depotAddressValue,
          },
        },
        name: this.depotUtils.getDepotName(this.globals.depots[this.depotId].companyDepot)
      };
    } else {
      this.selectedDepotItem = null;
    }

    // find driver name value based on driver_id
    this.driverId = connection.driver_id;
    if (this.driverId) {
      let driverName;
      this.drivers.forEach(driver => {
        if (driver.driver.id == connection.driver_id) {
          // driverName = driver.userProfile.name;
          this.driver = driver;
        }
      });
    }

    this.collaboratorId = collaboratorData.id;
    this.connectionId = connection.id;
    this.isMember = connection.is_member;
    this.canBeInvited = connection.can_be_invited;
    this.createVoucher = connection.can_create_new_voucher;
    this.lineHaul = connection.is_line_haul;
    this.delivery = connection.is_delivery;
    this.chargeCategories = [...connection.chargeCategories];
    this.spatialCategories = [...connection.spatialCategories];
    this.discounts = connection.discounts;
    this.chargeCategoryIdsToBeDeleted = [];
    this.iban = connection.iban;
    this.bankName = connection.bank_name;

    this.fixSpatialData();

    if (collaboratorData.telephone) {
      this.telephone = collaboratorData.telephone[0].telephone_number;
      this.countryPrefix = collaboratorData.telephone[0].telephone_code;
    }
    this.collaboratorName = collaboratorData.collaborator_name;
    this.tin = collaboratorData.tin;
    this.collaboratorHome = collaboratorData.collaborator_home;
    this.collaboratorEmail = collaboratorData.mail;
    this.taxOffice = collaboratorData.tax_office;
    // this.collaboratorPrice = collaboratorData.collaborator_price;
    this.collaboratorToGetPaidAmount = collaboratorData.collaborator_to_get_paid_amount;
    this.collaboratorToPayAmount = collaboratorData.collaborator_to_pay_amount;
    this.invoiceDays = collaboratorData.pay_on_delivery_days;
    this.payReminderDays = collaboratorData.pay_reminder_days;
    this.liveTracking = connection.live_tracking;
    this.selfPickup = connection.self_pickup;
    this.canPrint = connection.can_print;
    this.isViberMessageTemplateEnabled = collaboratorData.is_viber_message_template_enabled;
    this.viberMessageTemplate = collaboratorData.viber_message_template;
    this.isAutoInvoiceApprovalEnabled = connection.is_auto_invoice_approval_enabled;
    this.canAccessSystemicVoucher = connection.can_access_systemic_voucher;

    if (collaborator.address) {
      if (collaborator.address.lat && collaborator.address.lon) {
        this.setAddress(collaborator.address);

        this.customerCollaboratorMapComponent.showAddressDraggableMarker(this.lat, this.lon);
      }
    }

    if (collaborator.collaborator_depot) {
      this.collaboratorDepotAddressId = collaborator.collaborator_depot.address.id;
      if (collaborator.collaborator_depot.address) {
        if (collaborator.collaborator_depot.address.lat && collaborator.collaborator_depot.address.lon) {
          this.setDepotAddress(collaborator.address);
          this.customerCollaboratorMapComponent.showDepotAddressDraggableMarker(this.depotLat, this.depotLon);
        }
      }
    }

    this.patchForm();

    // });
  }

  // new collaborators' extra & service charges start with company charge settings
  // collaborator charges data are copied from the company's charge data
  // the ids are removed so the company's charges are just copied, not changed or deleted
  // a temp id is added so the copies can be edited
  newPartnerSetData() {
    this.chargeCategories = [];
    this.spatialCategories = [];
    this.globals.chargeZones.forEach((zone, index) => {
      this.chargeCategories.push(zone);
      this.chargeCategories[index]['id'] = null;
      this.chargeCategories[index]['temp_id'] = this.tempIdZoneCounter;
      this.tempIdZoneCounter++;
    });
    this.globals.spatialCategories.forEach((spatial, index) => {
      this.spatialCategories.push(spatial);
      this.spatialCategories[index]['id'] = null;
      this.spatialCategories[index]['temp_id'] = this.tempIdRegionCounter;
      this.tempIdRegionCounter++;
    });

    // insert charge categories to discounts' available zones
    this.modalService.collaboratorZones = this.chargeCategories;
    this.collaboratorDiscountsGridComponent.updateAvailableZones();

    this.zoneChargesGridComponent.setZoneChargesGridDataWithSpecificData(this.chargeCategories);
    this.regionChargesGridComponent.setRegionalChargesGridDataWithSpecificData(this.spatialCategories);

    this.fixSpatialData();

    // set auto-invoicing as default (from settings) for new collaborators only
    this.isAutoInvoiceApprovalEnabled = this.globals.isAutoInvoiceApprovalEnabled;

    this.myForm.patchValue({
      'companyCollaborator': {
        'connection': {
          'spatialCategories': this.spatialCategories,
          'chargeCategories': this.chargeCategories,
          'is_auto_invoice_approval_enabled': this.isAutoInvoiceApprovalEnabled
        }
      }
    });
  }

  // if the collaborator has charges or spatial from company settings, remove the ids so they are copied, not moved
  fixCompanyChargesForSubmit() {
    const data = this.myForm.value;
    const connectionData = data.companyCollaborator.connection;
    if (connectionData) {
      if (connectionData.chargeCategories) {
        connectionData.chargeCategories.forEach(chargeCategory => {
          if (chargeCategory.id) {
            if (this.globals.chargeZonesById[chargeCategory.id] || typeof chargeCategory.id === 'string') {
              chargeCategory.id = null;
            }
          }
        });
      }
      if (connectionData.spatialCategories) {
        connectionData.spatialCategories.forEach(spatialCategory => {
          if (spatialCategory.id) {
            if (this.globals.spatialCategoriesById[spatialCategory.id]) {
              spatialCategory.id = null;
            }
          }
        });
      }
    }
    return data;
  }

  // submit
  submitPartnerData(invite: boolean) {
    // telephone object to place inside telephone array of objects
    this.telephone = [
      {
        'id': null,
        'telephone_code': this.myForm.value.companyCollaborator.collaborator.collaboratorData.countryPrefix,
        'telephone_number': this.myForm.value.companyCollaborator.collaborator.collaboratorData.phoneNumber,
        'label': 'test label'
      }
    ];

    // depot send only id
    if (this.myForm.value.companyCollaborator.connection.depot) {
      this.depotId = this.myForm.value.companyCollaborator.connection.depot.companyDepot.id;
    }

    // driver send only id
    if (this.myForm.value.companyCollaborator.connection.driver) {
      this.driverId = this.myForm.value.companyCollaborator.connection.driver.driver.id;
    } else {
      this.driverId = null;
    }
    this.myForm.patchValue({
      'companyCollaborator': {
        'collaborator': {
          'collaboratorData': {
            'telephone': this.telephone,
          }
        },
        'connection': {
          'driver_id': this.driverId,
          'depot_id': this.depotId,
        }
      }
    });

    // check viber message empty
    if (!this.myForm.value.companyCollaborator.collaborator.collaboratorData.viber_message_template || this.myForm.value.companyCollaborator.collaborator.collaboratorData.viber_message_template === '') {
      this.isViberMessageTemplateEnabled = false;
      this.myForm.patchValue({
        'companyCollaborator': {
          'collaborator': {
            'collaboratorData': {
              'is_viber_message_template_enabled': this.isViberMessageTemplateEnabled
            }
          }
        }
      });
    }

    // TODO this is probably useless, check to remove
    const submitData = this.fixCompanyChargesForSubmit();

    // submit handlers
    this.isClickedOnce = true;
    const myObserver = {
      next: (response) => {
        if (response['items'][0]) {
          this.globals.partners[this.collaboratorId] = response['items'][0];
        }
        this.modalGridService.updateCustomerCollaborators();

        // if invite is true then invite the collaborator via the submitted email
        if (invite) {
          this.inviteCollaborator();
        }

        // refresh all grids after submit (if in company's partner view!)
        if (!this.globals.collaboratorModeEnabled) {
          this.genericService.submitPartner();
        }
        this.globals.updatePartners();
      },
      error: (error) => {
        console.error(error);
        this.isClickedOnce = false;
      },
      complete: () => {
        this.isClickedOnce = false;
        this.toggleModal.emit('close');
      },
    };

    // make http request to either put or post
    if (this.collaboratorId) {
      const deleteCharges = {
        'chargeCategoriesIdsToDelete': this.chargeCategoryIdsToBeDeleted,
        'spatialCategoriesIdsToDelete': this.spatialCategoryIdsToBeDeleted
      };
      this.http.post('api/v1/delete-charges', JSON.stringify(deleteCharges)).pipe(take(1)).subscribe(() => {
        this.http.put('api/v1/company-collaborators/' + this.collaboratorId, submitData).pipe(take(1)).subscribe(myObserver);
      });
    } else {
      this.http.post('api/v1/company-collaborators', submitData).pipe(take(1)).subscribe(myObserver);
    }
  }

  // set address
  setAddress(address) {
    document.getElementById('address-label').classList.add('active');
    this.freeformAddress = this.addressService.getAddressLabel(address);
    this.placeName = this.addressService.getAddressPlace(address);

    if (address.countryCode) {
      this.countryCode = address.countryCode;
    } else {
      this.countryCode = '';
    }
    if (address.state) {
      this.state = address.state;
    } else {
      this.state = '';
    }
    if (address.county) {
      this.county = address.county;
    } else {
      this.county = '';
    }
    if (address.city) {
      this.city = address.city;
    } else {
      this.city = '';
    }
    if (address.district) {
      this.district = address.district;
    } else {
      this.district = '';
    }
    if (address.street) {
      this.street = address.street;
    } else {
      this.street = '';
    }
    if (address.street2) {
      this.street2 = address.street2;
    } else {
      this.street2 = '';
    }
    if (address.houseNumber) {
      this.houseNumber = address.houseNumber;
    } else {
      this.houseNumber = '';
    }
    if (address.postalCode) {
      this.postalCode = address.postalCode;
    } else {
      this.postalCode = '';
    }
    if (address.isPlace) {
      this.isPlace = address.isPlace;
    } else {
      this.isPlace = false;
    }
    if (address.lat) {
      this.lat = address.lat;
    }
    if (address.lon) {
      this.lon = address.lon;
    }

    this.patchAddresses();
  }

  // set depot address
  setDepotAddress(depotAddress) {
    document.getElementById('depot-address-label').classList.add('active');
    this.depotFreeformAddress = this.addressService.getAddressLabel(depotAddress);
    this.depotPlaceName = this.addressService.getAddressPlace(depotAddress);

    if (depotAddress.countryCode) {
      this.depotCountryCode = depotAddress.countryCode;
    } else {
      this.countryCode = '';
    }
    if (depotAddress.state) {
      this.depotState = depotAddress.state;
    } else {
      this.depotState = '';
    }
    if (depotAddress.county) {
      this.depotCounty = depotAddress.county;
    } else {
      this.depotCounty = '';
    }
    if (depotAddress.city) {
      this.depotCity = depotAddress.city;
    } else {
      this.depotCity = '';
    }
    if (depotAddress.district) {
      this.depotDistrict = depotAddress.district;
    } else {
      this.depotDistrict = '';
    }
    if (depotAddress.street) {
      this.depotStreet = depotAddress.street;
    } else {
      this.depotStreet = '';
    }
    if (depotAddress.street2) {
      this.depotStreet2 = depotAddress.street2;
    } else {
      this.depotStreet2 = '';
    }
    if (depotAddress.houseNumber) {
      this.depotHouseNumber = depotAddress.houseNumber;
    } else {
      this.depotHouseNumber = '';
    }
    if (depotAddress.postalCode) {
      this.depotPostalCode = depotAddress.postalCode;
    } else {
      this.depotPostalCode = '';
    }
    if (depotAddress.isPlace) {
      this.depotIsPlace = depotAddress.isPlace;
    } else {
      this.depotIsPlace = false;
    }
    if (depotAddress.lat) {
      this.depotLat = depotAddress.lat;
    }
    if (depotAddress.lon) {
      this.depotLon = depotAddress.lon;
    }
    if (depotAddress.id) {
      this.depotId = depotAddress.id;
    } else {
      this.depotId = null;
    }

    this.patchDepotAddresses();
  }

  // patch addresses
  patchAddresses() {
    this.myForm.patchValue({
      'companyCollaborator': {
        'collaborator': {
          'address': {
            'countryCode': this.countryCode,
            'state': this.state,
            'county': this.county,
            'city': this.city,
            'district': this.district,
            'street': this.street,
            'street2': this.street2,
            'houseNumber': this.houseNumber,
            'postalCode': this.postalCode,
            'isPlace': this.isPlace,
            'placeName': this.placeName,
            'value': this.freeformAddress,
            'lat': this.lat,
            'lon': this.lon,
          },
        },
      },
    });
  }

  // patch depot addresses
  patchDepotAddresses() {
    this.myForm.patchValue({
      'companyCollaborator': {
        'collaborator': {
          'collaborator_depot': {
            'address': {
              'id': this.collaboratorDepotAddressId,
              'countryCode': this.depotCountryCode,
              'state': this.depotState,
              'county': this.depotCounty,
              'city': this.depotCity,
              'district': this.depotDistrict,
              'street': this.depotStreet,
              'street2': this.depotStreet2,
              'houseNumber': this.depotHouseNumber,
              'postalCode': this.depotPostalCode,
              'isPlace': this.depotIsPlace,
              'placeName': this.depotPlaceName,
              'value': this.depotFreeformAddress,
              'lat': this.depotLat,
              'lon': this.depotLon,
            },
          },
        },
      },
    });
  }

  // reset form
  resetForm() {
    this.collaboratorId = null;
    this.connectionId = null;
    this.collaboratorDepotAddressId = null;
    this.chargeCategories = [];
    this.spatialCategories = [];
    this.discounts = [];
    this.countryPrefix = this.globals.defaultCountryCode;
    this.telephone = [];
    this.collaboratorName = '';
    this.tin = '';
    this.collaboratorHome = '';
    this.driver = null;
    this.driverId = null;
    this.collaboratorEmail = '';
    this.taxOffice = '';
    this.iban = null;
    this.bankName = null;
    // this.collaboratorPrice = null;
    this.delivery = false;
    this.lineHaul = false;
    this.createVoucher = false;
    this.collaboratorToGetPaidAmount = null;
    this.collaboratorToPayAmount = null;
    this.invoiceDays = 30;
    this.payReminderDays = 5;
    this.liveTracking = false;
    this.selfPickup = false;
    this.isViberMessageTemplateEnabled = false;
    this.viberMessageTemplate = '';
    this.isAutoInvoiceApprovalEnabled = false;
    this.canAccessSystemicVoucher = false;
    this.canPrint = false;
    this.partnerType = this.globals.companyModes['NORMAL'];

    // address fields reset
    this.countryCode = '';
    this.state = '';
    this.county = '';
    this.city = '';
    this.district = '';
    this.street = '';
    this.street2 = '';
    this.houseNumber = '';
    this.postalCode = '';
    this.isPlace = false;
    this.placeName = '';
    this.freeformAddress = '';
    this.lat = '';
    this.lon = '';

    // address fields reset
    this.depotCountryCode = '';
    this.depotState = '';
    this.depotCounty = '';
    this.depotCity = '';
    this.depotDistrict = '';
    this.depotStreet = '';
    this.depotStreet2 = '';
    this.depotHouseNumber = '';
    this.depotPostalCode = '';
    this.depotIsPlace = false;
    this.depotPlaceName = '';
    this.depotFreeformAddress = '';
    this.depotLat = '';
    this.depotLon = '';

    this.chargeCategoryIdsToBeDeleted = [];
    this.spatialCategoryIdsToBeDeleted = [];

    this.selectedAddress = '';
    this.selectedDepotAddress = '';
    (<HTMLInputElement>document.getElementById('address-customer-collaborators-form')).value = this.freeformAddress.valueOf();
    (<HTMLInputElement>document.getElementById('depot-address-customer-collaborators-form')).value = this.depotFreeformAddress.valueOf();

    this.depotId = this.globals.depotId;
    this.selectedDepotItem = {
      companyDepot: {
        id: this.globals.depotId,
        address: {
          value: this.globals.depotAddress,
        },
      },
      name: this.depotUtils.getDepotName(this.globals.depots[this.globals.depotId].companyDepot)
    };

    // Reset grids (remove unsaved grid entries)
    this.chargeCategories = [];
    this.spatialCategories = [];
    this.zoneChargesGridComponent.emptyZoneChargesGridData();
    this.regionChargesGridComponent.emptyRegionChargesGridData();
    this.collaboratorDiscountsGridComponent.emptyDiscountsGridData();

    // Remove marker
    this.customerCollaboratorMapComponent.removeDraggableMarker();

    this.patchForm();

    setTimeout(() => {
      M.updateTextFields();
    }, 100);
  }

  // address (inputFocusOut)
  inputFocusOut() {
    if (!this.myForm.value.companyCollaborator.collaborator.address.term.timeZone) {
      if (this.ngSelect.itemsList['_filteredItems']) {
        const firstItem = this.ngSelect.itemsList['_filteredItems'][0];
        if (firstItem) {
          this.ngSelect.select(firstItem);
        }
      }
    }
  }

  // depot address (inputFocusOut)
  inputDepotFocusOut() {
    if (!this.myForm.value.companyCollaborator.collaborator.collaborator_depot.address.term.timeZone) {
      if (this.ngSelectDepotAddress.itemsList['_filteredItems']) {
        const firstItem = this.ngSelectDepotAddress.itemsList['_filteredItems'][0];
        if (firstItem) {
          this.ngSelectDepotAddress.select(firstItem);
        }
      }
    }
  }

  // invite collaborator
  inviteCollaborator() {
    let obj = {};
    this.http.put('api/v1/send-partner-registration-mail/' + this.connectionId, JSON.stringify(obj)).pipe(take(1)).subscribe(response => {
      this.canBeInvited = false;
    });
  }

  // address (inputAddress)
  inputAddress() {
    this.selectedAddress = '';
    this.myForm.patchValue({
      'customer': {
        'address': {
          'term': this.selectedAddress,
        },
      },
    });
    this.ngSelect.filter((<HTMLInputElement>document.getElementById('address-customer-collaborators-form')).value);
  }

  // address depot (inputAddress)
  inputDepotAddress() {
    this.selectedAddress = '';
    this.myForm.patchValue({
      'customer': {
        'address': {
          'term': this.selectedAddress,
        },
      },
    });
    this.ngSelectDepotAddress.filter((<HTMLInputElement>document.getElementById('depot-address-customer-collaborators-form')).value);
  }

  // address (onAddressChange)
  onAddressChange() {
    if (this.myForm.value.companyCollaborator.collaborator.address) {
      const selectedAddress = this.myForm.value.companyCollaborator.collaborator.address.term;
      if (selectedAddress) {
        (<HTMLInputElement>document.getElementById('address-customer-collaborators-form')).value = selectedAddress.label;
        this.lat = selectedAddress.position[0];
        this.lon = selectedAddress.position[1];
        this.freeformAddress = selectedAddress.label;
        this.setAddress(selectedAddress.address);
        this.customerCollaboratorMapComponent.showAddressDraggableMarker(Number(this.lat), Number(this.lon));

        // if the depot address is empty, fill it with the same address as this
        if (!this.myForm.value.companyCollaborator.collaborator.collaborator_depot.address.term.label) {
          this.myForm.patchValue({
            'companyCollaborator': {
              'collaborator': {
                'collaborator_depot': {
                  'address': {
                    'term': selectedAddress,
                  },
                },
              },
            },
          });
          this.onDepotAddressChange();
        }

      }
    }
  }

  // depot address (onAddressChange)
  onDepotAddressChange() {
    if (this.myForm.value.companyCollaborator.collaborator.collaborator_depot.address) {
      const selectedAddress = this.myForm.value.companyCollaborator.collaborator.collaborator_depot.address.term;
      if (selectedAddress) {
        (<HTMLInputElement>document.getElementById('depot-address-customer-collaborators-form')).value = selectedAddress.label;
        this.depotLat = selectedAddress.position[0];
        this.depotLon = selectedAddress.position[1];
        this.depotFreeformAddress = selectedAddress.label;
        this.setDepotAddress(selectedAddress.address);
        this.customerCollaboratorMapComponent.showDepotAddressDraggableMarker(Number(this.depotLat), Number(this.depotLon));
      }
    }
  }

  // delete collaborator
  deleteCollaborator() {
    let url = 'api/v1/company-collaborators/' + this.collaboratorId;

    this.http.delete(url).pipe(take(1)).subscribe(response => {
      this.router.navigate(['partnerView/partners']);
    });
  }

  // map listen
  collaboratorDragListen(event) {
    this.positionSet = true;
    this.lat = this.customerCollaboratorMapComponent.lat;
    this.lon = this.customerCollaboratorMapComponent.lon;
    this.patchAddresses();
  }

  collaboratorAddressListen(event) {
    this.positionSet = true;
    this.lat = this.customerCollaboratorMapComponent.lat;
    this.lon = this.customerCollaboratorMapComponent.lon;
    this.isClickedOnce = true;
    this.http.get(`api/v1/search/reverse-locations?searchQuery=${this.lat},${this.lon}`).pipe(take(1)).subscribe(location => {
      this.isClickedOnce = false;
      if (location['data']['addresses']['items']) {
        if (location['data']['addresses']['items'].length) {
          this.setAddress(location['data']['addresses']['items'][0]['address']);

          // if depot field is empty and we fill address field, fill depot field with same address
          const depotAddressLabelElement = <HTMLInputElement>document.getElementById('depot-address-customer-collaborators-form');
          if (!depotAddressLabelElement.value) {
            this.depotLat = this.lat;
            this.depotLon = this.lon;
            this.setDepotAddress(location['data']['addresses']['items'][0]['address']);
            depotAddressLabelElement.value = this.depotFreeformAddress;
          }

        } else {
          this.freeformAddress = 'Address';
        }
      }
      (<HTMLInputElement>document.getElementById('address-customer-collaborators-form')).value = this.freeformAddress;
    });
  }

  collaboratorDepotAddressListen(event) {
    this.positionSet = true;
    this.depotLat = this.customerCollaboratorMapComponent.lat;
    this.depotLon = this.customerCollaboratorMapComponent.lon;
    this.isClickedOnce = true;
    this.http.get(`api/v1/search/reverse-locations?searchQuery=${this.depotLat},${this.depotLon}`).pipe(take(1)).subscribe(location => {
      this.isClickedOnce = false;
      if (location['data']['addresses']['items']) {
        if (location['data']['addresses']['items'].length) {
          this.setDepotAddress(location['data']['addresses']['items'][0]['address']);
        } else {
          this.depotFreeformAddress = 'Depot Address';
        }
      }

      (<HTMLInputElement>document.getElementById('depot-address-customer-collaborators-form')).value = this.depotFreeformAddress;
    });
  }

  // get drivers
  getDrivers() {
    this.http.get('api/internal/v2/drivers').pipe(take(1)).subscribe(response => {
      // empty drivers array because otherwise the options are not assigned to the dropdown for some twisted reason
      this.drivers = [];
      response['items'].forEach(driver => {
        this.drivers.push(driver);
      });
    });
  }

  // populateDiscountOptions() {
  //   for (let i = 1; i < 15; i++) {
  //     this.discountOptions.push({
  //       value: i * 5,
  //       label: (i * 5) + '%'
  //     });
  //   }
  // }

  ngAfterViewInit() { }

  ngOnInit() {
    // populate drivers array
    this.getDrivers();
    // this.populateDiscountOptions();

    // depots populate & get initial depot
    const self = this;
    const depotsDataRefreshIntervalId = setInterval(depotsDataChecker, 200);
    function depotsDataChecker() {
      if (self.globals.depotsDataDone) {
        clearInterval(depotsDataRefreshIntervalId);
        self.depotId = self.globals.depotId;
        self.selectedDepotItem = {
          companyDepot: {
            id: self.globals.depotId,
            address: {
              value: self.globals.depotAddress,
            },
          },
          name: self.depotUtils.getDepotName(self.globals.depots[self.globals.depotId].companyDepot)
        };

        self.myForm.patchValue({
          'companyCollaborator': {
            'connection': {
              'depot': self.selectedDepotItem,
            }
          }
        });
      }
    }

    // default country prefix
    this.countryPrefix = this.globals.defaultCountryCode;
    this.myForm.patchValue({
      'companyCollaborator': {
        'collaborator': {
          'collaboratorData': {
            'countryPrefix': this.countryPrefix,
          }
        }
      }
    });

    // address field
    this.addresses = concat(
      of([]), // default items
      this.addressInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.addressesLoading = true),
        switchMap(term => this.dataService.getAddresses(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.addressesLoading = false)
        ))
      )
    );
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }
}
