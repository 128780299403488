import { CollaboratorSelectModalComponent } from './../../modals/collaborator-select-modal/collaborator-select-modal.component';
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@app/services/settings.service';
import { of } from 'rxjs';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
import { Router } from '@angular/router';
import { Globals } from '@app/services/globals';
import { ModalService } from '@app/services/modal.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-zone-charges-grid',
  templateUrl: './zone-charges-grid.component.html',
  styleUrls: ['./zone-charges-grid.component.scss']
})
export class ZoneChargesGridComponent implements OnInit, OnDestroy {
  @ViewChild('extraChargesGrid', { static: false }) extraChargesGrid: AgGridAngular;
  @ViewChild(CollaboratorSelectModalComponent, { static: false }) collaboratorSelectModalComponent: CollaboratorSelectModalComponent;

  listen = [];
  chargeRegions = [];
  searchString: String = '';
  colours = [];
  rowId;

  gridApi;
  gridColumnApi;
  domLayout = 'autoHeight';
  extraChargesDataArray = [];
  rowData: any;
  columnDefs;

  collaboratorId;
  selectedZone = null;

  amountLabel: string;
  actionsLabel: string;
  currencyLabel: string;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private settingsService: SettingsService,
    public gridsService: GridsService,
    public router: Router,
    public globals: Globals,
    private modalService: ModalService,
  ) {
    this.listen.push(this.settingsService.updateZoneChargesGridListen().subscribe(() => {
      if (this.router.url.split('/')[1] == 'settings') {
        this.setZoneChargesGridData();
        this.settingsService.setSurcharges();
        this.globals.getZones();
      }
    }));
  }

  setZoneChargesGridDataWithSpecificData(chargesData) {
    this.extraChargesDataArray = [];
    if (chargesData.length) {
      chargesData.forEach(data => {
        const gridObject = {
          id: data.id,
          name: data.name,
          objData: data,
        };
        this.extraChargesDataArray.push(gridObject);
      });
    } else {
      const noData = { noDataText: 'No data' }
      this.extraChargesDataArray.push(noData);
    }
    this.rowData = of(this.extraChargesDataArray);
  }

  setZoneChargesGridData(collaboratorId = null) {
    // collaborator
    if (collaboratorId) {
      this.collaboratorId = collaboratorId;
      this.http.get('api/v1/charge-categories?pageSize=200&collaboratorId=' + this.collaboratorId).pipe(take(1)).subscribe(response => {
        let data = response['items'];

        let gridObject = {};
        this.extraChargesDataArray = [];
        if (data.length) {
          data.forEach(data => {
            gridObject = {
              id: data.id,
              name: data.name,
              objData: data,
            };
            this.extraChargesDataArray.push(gridObject);
          });
        } else {
          const noData = { noDataText: 'No data' }
          this.extraChargesDataArray.push(noData);
        }
        this.rowData = of(this.extraChargesDataArray);
      });
    }

    // settings & new collaborator
    else {
      this.http.get('api/v1/charge-categories?pageSize=200').pipe(take(1)).subscribe(response => {
        let data = response['items'];

        let gridObject = {};
        this.extraChargesDataArray = [];
        if (data.length) {
          data.forEach(data => {
            gridObject = {
              id: data.id,
              name: data.name,
              objData: data,
            };
            this.extraChargesDataArray.push(gridObject);
          });
        } else {
          const noData = { noDataText: 'No data' }
          this.extraChargesDataArray.push(noData);
        }
        this.rowData = of(this.extraChargesDataArray);
      });
    }
  }

  emptyZoneChargesGridData() {
    this.extraChargesDataArray = [{ noDataText: 'No data' }];
    this.rowData = of(this.extraChargesDataArray);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  rowClicked(event) {
    console.log(event)
    this.rowId = event.data.id;
    const target = event.event.target.closest('div');
    if (event.data && this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN']) {
      if (!event.data.noDataText && !target.classList.contains('dropdown-dots')) {
        this.settingsService.openZoneChargesModal(event.data.objData);
      }
    }
  }

  onFirstDataRendered(params) { }

  newExtraCharge() {
    this.settingsService.openZoneChargesModal(null);
  }

  removeRowFromArray(indexToBeRemoved) {
    if (!isNaN(indexToBeRemoved)) {
      if (this.extraChargesDataArray[indexToBeRemoved]) {
        this.extraChargesDataArray.splice(indexToBeRemoved, 1);

        const dataToUpdateGrid = [];
        this.extraChargesDataArray.forEach(charge => {
          dataToUpdateGrid.push(charge.objData);
        });
        this.setZoneChargesGridDataWithSpecificData(dataToUpdateGrid);

        // delete zone from discounts (saved or unsaved)
        this.modalService.deleteZoneInCollaborator(this.selectedZone.objData.id);

        this.selectedZone = null;
        const dropdown = document.getElementById('zones-dropdown-container');
        dropdown.style.display = 'none';
      }
    }
  }

  deleteZone() {
    // delete saved zone
    if (this.selectedZone) {
      if (this.selectedZone.id) {
        const deleteCharges = {
          'chargeCategoriesIdsToDelete': [this.selectedZone.id],
        };
        if (this.router.url.split('/')[1] == 'settings') {
          this.http.post('api/v1/delete-charges', JSON.stringify(deleteCharges)).pipe(take(1)).subscribe(() => {
            this.setZoneChargesGridData();
            this.settingsService.updateRegionalChargesGrid();
            this.settingsService.setSurcharges();
            this.globals.getZones();

            this.selectedZone = null
            const dropdown = document.getElementById('zones-dropdown-container');
            dropdown.style.display = 'none';
          });
        } else {
          let indexToBeRemoved = null;
          this.extraChargesDataArray.forEach((charge, index) => {
            if (charge.objData.id === this.selectedZone.id) {
              indexToBeRemoved = index;
            }
          });
          this.modalService.deleteCollaboratorsChargesForm(this.selectedZone.objData);
          this.removeRowFromArray(indexToBeRemoved);
        }
      }
      // delete unsaved zone
      else if (this.selectedZone.objData) {
        const selectedTempId = this.selectedZone.objData.temp_id
        if (selectedTempId) {
          let indexToBeRemoved = null;
          this.extraChargesDataArray.forEach((charge, index) => {
            if (charge.objData.temp_id === selectedTempId) {
              indexToBeRemoved = index;
            }
          });
          this.modalService.deleteCollaboratorsChargesForm(this.selectedZone.objData);
          this.removeRowFromArray(indexToBeRemoved);
        }
      }
    }
  }

  copyZone() {
    this.collaboratorSelectModalComponent.mode = 'copyZone';
    this.collaboratorSelectModalComponent.zoneId = this.selectedZone['id'];
    this.collaboratorSelectModalComponent.getCompanyCollaborators();
    this.collaboratorSelectModalComponent.openModal();
  }

  // custom renderer for dropdown actions
  dropdownRenderer(params) {
    const self = this;
    const dots = document.createElement('div');
    dots.classList.add('dropdown-dots');
    dots.innerHTML = '<i class="fas fa-ellipsis-v"></i>';
    const dropdown = document.getElementById('zones-dropdown-container');
    dots.addEventListener('click', function () {
      setTimeout(() => {
        if (!self.selectedZone) {
          if (!self.globals.isInRoute('settings')) {
            dropdown.style.top = 'calc(' + dots.getBoundingClientRect().bottom + 'px - 64px)';
            dropdown.style.left = 'calc(' + dots.getBoundingClientRect().left + 'px - 14vw)';
          } else {
            dropdown.style.top = 'calc(' + dots.getBoundingClientRect().bottom + 'px - 10px)';
            dropdown.style.left = 'calc(' + dots.getBoundingClientRect().left + 'px - 9vw)';
          }
          dropdown.style.display = 'block';
          self.selectedZone = params.data;
        } else {
          self.selectedZone = null
          dropdown.style.display = 'none';
        }
      }, 20);
    });
    return dots;
  }

  getTranslations() {
    let zonesLabel;

    this.listen.push(this.translate.get('SETTINGS.ZONES').subscribe((res: string) => { zonesLabel = res; }));

    // set zones column width
    let zonesWidth = 91;
    if (!this.globals.isInRoute('settings')) {
      zonesWidth = 86;
    }

    this.columnDefs = [
      {
        headerName: zonesLabel,
        field: 'name',
        width: this.gridsService.widthCalculatorContainerId(zonesWidth, 'zone-charges')
      },
      {
        headerName: '', field: '',
        cellClass: 'dropdown-cell',
        cellRenderer: this.dropdownRenderer.bind(this),
        width: this.gridsService.widthCalculatorContainerId(7, 'zone-charges')
      }
    ];
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    // settings grid data
    if (this.router.url.split('/')[1] == 'settings') {
      this.setZoneChargesGridData();
    }
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
