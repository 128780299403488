import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { SettingsService } from '@app/services/settings.service';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal.service';

@Component({
    selector: 'app-services-charge-modal',
    templateUrl: './services-charge-modal.component.html',
    styleUrls: ['./services-charge-modal.component.scss']
})
export class ServicesChargeModalComponent implements OnInit, OnDestroy {

    myForm: FormGroup;
    listen = [];
    isClickedOnce = false;
    id;
    tempId;
    serviceName = '';
    servicePrice;
    servicePriceString;
    isDeleteMode;
    isDefaultService = false;
    isPerItem = false;
    serviceType = null;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        private settingsService: SettingsService,
        private modalService: ModalService,
        public router: Router,
        formBuilder: FormBuilder,
    ) {
        this.serviceType = this.globals.voucherServicesTypesConstants['CUSTOM'];
        this.myForm = formBuilder.group({
            "id": [this.id],
            "service_type": [this.serviceType],
            "service_name": [this.serviceName],
            "service_price": [this.servicePrice],
            "service_price_string": [this.servicePriceString],
            "is_default_service": [this.isDefaultService],
            "is_per_item": [this.isPerItem],
            "is_delete_mode": false,
            "temp_id": [this.tempId]
        });
        this.listen.push(this.settingsService.openServicesChargesModalListen().subscribe((data) => {
            if (data) {
                this.loadServicesCharges(data);
            }

            this.openModal();
        }));
    }

    priceValueChanged(event) {
        const stringFixed = event.target.value.replace(/,/g, '.');
        this.servicePrice = parseFloat(stringFixed).toFixed(2);
        if (isNaN(this.servicePrice)) { this.servicePrice = 0; }

        this.myForm.patchValue({
            "service_price": this.servicePrice,
        })
    }

    patchForm() {
        this.myForm.patchValue({
            "id": this.id,
            "service_type": this.serviceType,
            "service_name": this.serviceName,
            "service_price": this.servicePrice,
            "service_price_string": this.servicePriceString,
            "is_default_service": this.isDefaultService,
            "is_per_item": this.isPerItem,
            "temp_id": this.tempId
        });
        setTimeout(() => {
            M.updateTextFields();
        }, 100);
    }

    loadServicesCharges(data) {
        // check if it's a default service to disable name input
        if (data.is_default_service) {
            document.getElementById('services-charges-name-form').setAttribute('disabled', 'true');
        } else {
            document.getElementById('services-charges-name-form').removeAttribute('disabled');
        }

        this.id = data.id;
        this.serviceName = data.service_name;
        this.servicePrice = data.service_price;
        this.servicePriceString = data.service_price;
        this.isPerItem = data.is_per_item;
        this.serviceType = data.service_type;
        this.isDefaultService = data.is_default_service;
        this.tempId = data.temp_id;

        this.patchForm();
        this.openModal();
    }

    openModal() {
        const modal = document.querySelector('#services-charges-modal');
        const modalBackground = document.querySelector('#services-charges-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');

        // if (this.router.url.split('/')[2] == 'collaboratorView') {
        //     document.getElementById('services-charges-name-form').removeAttribute('disabled');
        // }
    }

    closeModal() {
        const modal = document.querySelector('#services-charges-modal');
        const modalBackground = document.querySelector('#services-charges-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');
        this.resetServicesChargeModal();
    }

    resetServicesChargeModal() {
        this.id = null;
        this.serviceName = null;
        this.servicePrice = null;
        this.serviceType = this.globals.voucherServicesTypesConstants['CUSTOM'];
        this.isPerItem = false;
        this.isDefaultService = false;
        this.tempId = null;
        this.servicePriceString = '';

        // make sure name is editable on new charge creation
        document.getElementById('services-charges-name-form').removeAttribute('disabled');

        this.patchForm();
    }

    submitServicesCharge() {
        this.modalService.updateServiceCharges(this.myForm.value);
        this.isClickedOnce = false;
        this.closeModal();
    }

    getTranslations() {
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}