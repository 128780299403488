import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { SettingsService } from '@app/services/settings.service';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-extra-charges-modal',
    templateUrl: './extra-charges-modal.component.html',
    styleUrls: ['./extra-charges-modal.component.scss']
})
export class ExtraChargesModalComponent implements OnInit, OnDestroy {

    myForm: FormGroup;
    listen = [];
    isClickedOnce = false;
    id;
    operatorType = 'POGRT'; // to be removed on proper data fetching
    chargeName: string;
    chargeType = 'PSTCH';
    chargeRegisteredValue: number;
    pricingType: string;
    chargeValue: number;
    geometry;
    confinesSelectValues = [];
    confinesMeasureSelectValues = [];
    confinesLessThan: string;
    confinesMoreThan: string;
    confinesMeasure: string;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        private settingsService: SettingsService,
        formBuilder: FormBuilder,
    ) {
        this.myForm = formBuilder.group({
            'companyExtraCharges': formBuilder.group({
                "id": [this.id],
                "charge_type": [this.chargeType],
                "charge_name": [this.chargeName],
                "operator_type": [this.operatorType],
                "charge_registered_value": null,
                "pricing_type": 'PINCP',
                "measure": null,
                "geometry": [this.geometry],
                "charge_value": [this.chargeValue]
            }),
        });
        this.listen.push(this.settingsService.openExtraChargesModalListen().subscribe((data) => {
            if (data) {
                this.loadExtraCharges(data);
            }

            this.openModal();
        }));
        this.listen.push(this.settingsService.setRegionChargeGeometryListen().subscribe((geometry) => {
            this.geometry = geometry;
            this.patchForm();
        }));
    }

    populateConfines() {
        this.confinesSelectValues = [
            {
                value: 'POGRT',
                label: this.confinesMoreThan
            },
            {
                value: 'POLST',
                label: this.confinesLessThan
            },
        ]

        this.confinesMeasureSelectValues = [
            {
                value: 'kg',
                label: this.confinesMeasure
            }
        ]

        this.myForm.patchValue({
            'companyExtraCharges': {
                "operator_type": 'POGRT',
                "measure": 'kg'
            },
        })


    }

    patchForm() {
        this.myForm.patchValue({
            'companyExtraCharges': {
                "id": this.id,
                "charge_type": this.chargeType,
                "charge_name": this.chargeName,
                "operator_type": this.operatorType,
                "charge_registered_value": null,
                "pricing_type": 'PINCP',
                "geometry": this.geometry,
                "charge_value": this.chargeValue
            },
        });
        setTimeout(() => {
            M.updateTextFields();
        }, 100);
    }

    loadExtraCharges(data) {
        this.id = data.id;
        this.chargeType = data.charge_type;
        this.chargeName = data.charge_name;
        this.operatorType = data.operator_type;
        this.chargeRegisteredValue = data.charge_registered_value;
        this.pricingType = data.pricing_type;
        this.chargeValue = data.charge_value;
        this.geometry = data.geometry;
        this.patchForm();
        this.openModal();
    }

    openModal() {
        const modal = document.querySelector('#extra-charges-modal');
        const modalBackground = document.querySelector('#extra-charges-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');
    }

    closeModal() {
        const modal = document.querySelector('#extra-charges-modal');
        const modalBackground = document.querySelector('#extra-charges-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');
        this.resetWeightChargesModal();
    }

    resetWeightChargesModal() {
        this.id = null;
        this.chargeName = '';
        this.operatorType = 'POGRT';
        this.chargeRegisteredValue = null;
        this.chargeValue = null;
        this.geometry = null;
        this.patchForm();
    }

    submitExtraCharges() {
        this.isClickedOnce = true;
        const myObserver = {
            next: (response) => {
                this.settingsService.updateExtraChargesGrid();
                // this.globals.updateImportFieldConfigs();
            },
            error: (error) => {
                console.error(error);
            },
            complete: () => {
                this.isClickedOnce = false;
                this.closeModal();
            },
        };

        this.http.put('api/v1/companies/' + this.globals.companyId, JSON.stringify(this.myForm.value)).pipe(take(1)).subscribe(myObserver);
    }

    ngOnInit() { }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}