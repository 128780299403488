import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { SettingsService } from '@app/services/settings.service';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-surcharge-modal',
    templateUrl: './surcharge-modal.component.html',
    styleUrls: ['./surcharge-modal.component.scss']
})
export class SurchargeModalComponent implements OnInit, OnDestroy {

    myForm: FormGroup;
    listen = [];
    isClickedOnce = false;

    id;
    tempId;
    name;
    percentage;
    percentageValueString;
    selectedZones = [];
    selectedZonesIds = [];

    allZones = [];
    availableZones = []; // zones that have not been assigned + the current zone's assigned zones

    chargeCategoryBeingEditedId; // used to enable the grid in a collaborator's charge category that already exists

    collaboratorId;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        private settingsService: SettingsService,
        public modalService: ModalService,
        public router: Router,
        formBuilder: FormBuilder,
    ) {
        this.myForm = formBuilder.group({
            "id": [this.id],
            "name": [this.name],
            "percentage": [this.percentage],
            "percentage_value_string": [this.percentageValueString],
            // "selectedZones": [this.selectedZones],
            "charge_category_ids": [this.selectedZonesIds],
            "temp_id": [this.tempId]
        });
    }

    percentageValueChanged(event) {
        const stringFixed = event.target.value.replace(/,/g, '.');
        this.percentage = parseFloat(stringFixed).toFixed(2);
        this.percentage = parseFloat(stringFixed);

        this.myForm.patchValue({
            "percentage": this.percentage
        })
    }

    selectPercentage() {
        (<HTMLInputElement>document.getElementById('percentage-form')).select();
    }

    patchForm() {
        this.percentageValueString = String(parseFloat(this.percentage).toFixed(2));
        this.myForm.patchValue({
            "id": this.id,
            "name": this.name,
            "percentage": this.percentage,
            "percentage_value_string": this.percentageValueString,
            // "selectedZones": this.selectedZones,
            "charge_category_ids": this.selectedZonesIds,
            "temp_id": this.tempId
        });
        // setTimeout(() => {
        //     M.updateTextFields();
        // }, 100);
    }

    resetForm() {
        this.id = null;
        this.name = null;
        this.percentage = 10;
        this.percentageValueString = '';
        this.selectedZonesIds = [];

        this.patchForm();

        setTimeout(() => {
            M.updateTextFields();
        }, 100);

        this.settingsService.setAvailableZones();
    }

    loadSurcharge(data = null) {
        console.log(data);
        // edit surcharge
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.percentage = data.percentage;
            this.selectedZones = data.zones;
            this.selectedZonesIds = data.selectedZonesIds;
            this.tempId = 1;

            this.setAvailableZones(data);
        }
        // new surcharge
        else {
            this.id = null;
            this.name = null;
            this.percentage = 10;
            this.selectedZones = [];
            this.selectedZonesIds = [];
            this.tempId = 1;
        }

        // if in collaborator/partner view -> add the charge category being edited in the selected zone ids only
        if (this.globals.isInRoute('collaboratorView') || this.globals.isInRoute('collaboratorPartnerView')) {
            this.selectedZonesIds = [this.chargeCategoryBeingEditedId];
        }

        this.patchForm();
        this.openModal();
    }

    // sets the modal's available zones to be availableZones + this surcharge's assigned zones
    setAvailableZones(surcharge) {
        // only for settings
        if (this.globals.isInRoute('settings')) {
            this.allZones = this.globals.chargeZones;
            const availableZones = [...this.availableZones];

            // existing surcharge (selected zones must be taken into account so that they are available) - matches ids with objects and pushes them into availableZones array
            if (surcharge) {
                if (surcharge.selectedZonesIds.length) {
                    surcharge.selectedZonesIds.forEach(id => {
                        this.allZones.forEach(zone => {
                            if (zone.id == id) {
                                availableZones.push(zone);
                            }
                        });
                    });

                    this.availableZones = availableZones;
                }
            }
        }
    }

    openModal() {
        const modal = document.querySelector('#surcharge-modal');
        const modalBackground = document.querySelector('#surcharge-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');
    }

    closeModal() {
        const modal = document.querySelector('#surcharge-modal');
        const modalBackground = document.querySelector('#surcharge-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');

        this.resetForm();
    }

    submitSurcharge() {
        this.isClickedOnce = false;

        const surchargeToSubmit = {
            fuel_surcharges: [
                this.myForm.value
            ]
        };

        if (this.globals.isInRoute('settings')) {
            this.http.put('api/internal/v1/settings/fuel-surcharges', surchargeToSubmit).pipe(take(1)).subscribe(response => {
                this.settingsService.setSurcharges();
            });
        } else {
            this.modalService.updateSurcharges(surchargeToSubmit['fuel_surcharges']);
        }

        this.closeModal();
    }

    getTranslations() { }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}