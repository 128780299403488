// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#extra-charges-map {
  height: calc(70vh - 65px);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImV4dHJhLWNoYXJnZXMtbWFwLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0kseUJBQUE7QUFESiIsImZpbGUiOiJleHRyYS1jaGFyZ2VzLW1hcC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgJ3N0eWxlcy9hYnN0cmFjdHMvdmFyaWFibGVzJztcblxuI2V4dHJhLWNoYXJnZXMtbWFwIHtcbiAgICBoZWlnaHQ6IGNhbGMoNzB2aCAtIDY1cHgpO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/settings/extra-charges-regions/extra-charges-map/extra-charges-map.component.scss"],"names":[],"mappings":"AAEA;EACI,yBAAA;AADJ;AACA,wZAAwZ","sourcesContent":["@import 'styles/abstracts/variables';\n\n#extra-charges-map {\n    height: calc(70vh - 65px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
