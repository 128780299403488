import { StopPointUtils } from '@app/utils/stop-point-utils';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientNavbarComponent } from '@app/navs/client-navbar/client-navbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, EMPTY, Observable, Subject, of } from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { PortalAddressMapComponent } from './portal-address-map/portal-address-map.component';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import flatpickr from 'flatpickr';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import polyline from '@mapbox/polyline';
import { expand, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { NoteModalComponent } from './note-modal/note-modal.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Globals } from '@app/services/globals';
import { ImageUtils } from '@app/utils/image-utils';
import { ClientPortalChatModalComponent } from './client-portal-chat-modal/client-portal-chat-modal.component';
import { IPudoCourierType } from '@app/model/pudo-courier-type-model';
import QRCode from 'easyqrcodejs';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { addDays, eachDayOfInterval, format, getDay } from 'date-fns';
import { el, enUS, de, ro } from 'date-fns/locale';
import { createArrayOfNumbersBetween, extractInteger, hasValue } from '@app/shared/utils';
import { ILiveTrackingRescheduleOption, ILiveTrackingRescheduler, ILiveTrackingSlot } from '@app/model/livetracking-reschedule-option-model';
import { ICoordinatesModel } from '@app/model/location-model';
import { ILmSwipeEvent, LmSwipeDirective } from '@app/shared/directives/swipe.directive';
import { ILmTheme } from '@app/model/theme-model';
import { INITIAL_DATA } from '@app/model/initial-data';
import { LOCALSTORAGE_CACHE_TOKEN, LmCacheService } from '@app/model/cache-service';
import { ILmRatingItemModel, ILmRatingModel } from '@app/model/rating-model.';
import { LmRating } from '@app/shared/misc/rating/rating-utils';

declare var H: any;

@UntilDestroy()
@Component({
  selector: 'app-client-portal',
  templateUrl: './client-portal.component.html',
  styleUrls: ['./client-portal.component.scss']
})
export class ClientPortalComponent implements OnInit, AfterViewInit {

  @ViewChild(ClientPortalChatModalComponent, { static: false }) clientPortalChatModalComponent: ClientPortalChatModalComponent;
  @ViewChild(NoteModalComponent, { static: false }) noteModalComponent: NoteModalComponent;
  @ViewChild(ClientNavbarComponent, { static: false }) clientNavbarComponent: ClientNavbarComponent;
  @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
  @ViewChild(PortalAddressMapComponent, { static: false }) portalAddressMapComponent: PortalAddressMapComponent;
  @ViewChild('map', { static: false }) public mapElement: ElementRef;
  @ViewChild('modal', { static: false }) public modal: ElementRef;
  @ViewChild('modalOptionsView', { static: false }) public modalOptionsView: ElementRef;
  @ViewChild('modalConfirmView', { static: false }) public modalConfirmView: ElementRef;
  @ViewChild('modalTimeView', { static: false }) public modalTimeView: ElementRef;
  @ViewChild('modalDateView', { static: false }) public modalDateView: ElementRef;
  @ViewChild('modalAddressView', { static: false }) public modalAddressView: ElementRef;
  @ViewChild('smartPointsNgSelect', { static: false }) smartPointsNgSelect: NgSelectComponent;
  @ViewChild('rescheduleCtrl') rescheduleCtrl;
  @ViewChild('drawer',{read: LmSwipeDirective}) drawer:LmSwipeDirective

  listen = [];

  hash = '';
  greeceLat = '38.65238769104773';
  greeceLon = '24.0691524677357';

  locales = {
    el: el,
    en: enUS,
    de: de,
    ro: ro
  };

  private platform: any;
  bubbleTextMsg;
  timeArrived;
  canceled;
  completedAt;
  estimatedTime;
  estimatedTimeIn;
  gettingTrackingMSG;
  routeBeginMSg;
  noNameLabel = '';
  wrongUrl;
  map;
  ui;
  behavior;
  routeMapObject;
  destinationMarker;
  vehiclesMapObject;
  vehiclesMapObjectBackground;
  displayInfoBubble;
  infoBubble;
  clientSolutionGroup = new H.map.Group();
  clientOldRouteGroup = new H.map.Group();
  clientMarkersGroup = new H.map.Group();
  clientMapGroup = new H.map.Group();
  smartPointsGroup = new H.map.Group();
  trackRequestDone = true;
  errorText;

  fulfillmentReason;
  isChatEnabled;
  canSelectSmartPoint;
  isSmartPointSelectMode;
  selectedReason = '';
  modalWindowOpen = 'options';
  hours = [];
  hoursRight = [];
  hoursPopulated = false;

  isSmartPointsDropdownOpen = false;

  confirmMsg = '';
  nextDayConfirmMsg = '';
  smartPointConfirmMsg = '';
  otherDayConfirmMsg = '';
  otherAddressConfirmMsg = '';
  otherDayAndAddressConfirmMsg = '';
  cancelConfirmMsg = '';
  confirmMsg2 = '';
  nextDayConfirmMsg2 = '';
  smartPointConfirmMsg2 = '';
  otherDayConfirmMsg2 = '';
  otherAddressConfirmMsg2 = '';
  otherDayAndAddressConfirmMsg2 = '';
  cancelConfirmMsg2 = '';
  thankYouMessage = '';

  isDriverVisible = false;
  canChangeDateTime = false;
  canCancel = false;
  canChangeAddress = false;
  canRate = false;
  cancelMessage = '';
  fulfillmentStatus;

  selectedReasonConstant = null;
  // selectedDate = '';
  // selectedTimeStart = '';
  // selectedTimeEnd = '';
  selectedDate = undefined;
  selectedTimeStart = undefined;
  selectedTimeEnd = undefined;

  selectedAddress = {};

  disableNext = true;

  generalCancelMsg = '';
  cancelForNextDayMsg = '';
  cancelAnotherDateTimeMsg = '';
  cancelAnotherAddressMsg = '';
  cancelAnotherDateTimeAddressMsg = '';
  cancelNotAcceptMsg = '';
  hoursShortMsg = '';
  minutesShortMsg = '';
  cancelReasonDescription = '';
  inProgressSoonMsg = '';
  inProgressPudoPickupPinMsg = '';
  inProgressCheckAgainMsg = '';
  smartPointRerouteMsg = '';
  
  hasSentRating;
  ratingStars = [1, 2, 3, 4, 5];
  starsSet = false;
  ratingValue;
  isRated;

  fbLinkEnabled;
  googleLinkEnabled;
  fbLink;
  googleLink;

  routesColour = '#00aeba';
  stopPointIcon;
  iconBackground;
  staticIcon;
  gapIcon;
  shipperLogoIcon;

  smartPoints = [];
  selectedSmartPoint;
  searchSmartPointsTimeout;
  smartPointsPage = 0;
  totalSmartPointsPages = 0;
  isLoading;
  smartPointData;
  shipperBubbleMapObject;

  // mapView = false;
  isEasyMailUser = false;
  easyMailCancelUrl: string;

  isPudoEnabled: boolean;
  pudoCourierTypeEnum: IPudoCourierType;
  pointName: string;
  pointAddress: string;
  pointHasParking: string;
  pointOpenUntil: string;
  pudoPointId: number;
  pointPIN: string;
  pudoQR: any;
  showPudoPointInfo = false;
  pudoPreventRatingScreen = false;
  pudoPayload: {
    stopPoint: {
        fulfillment_event: { reason: 1220, description: 'From Live Tracking.'}, 
        pudoPoint: { 
          pudo_point_id: null, 
          pudoShipmentStopPoint: {  type:1, order:1 }, 
          pudoFulfillment : {fulfillment_event: { reason: 10200, description: "Scheduled" }} 
        }, 
        agreed_shipping:[] 
    }
  } ;

  //remove after demonstration
  gapMapObject;
  isGAP = false;

  step = 0;
  boundsSet = false;
  lastLocationId = '0,0';
  interval;
  initialTrackingInfo: any;
  isSpeedexAddressChangeDisabled = false;
  isPinReadyToShow: boolean;
  isPinScanMandatory:boolean;
  theme: ILmTheme;
  pinMsg: string;
  drawerOpen = false;
  userHidPin = false;
  showDrawerTip = true;
  isSmartPointRedirectionDisabled = false;
  drawerPIN: string | number;
  showDrawer = true;
  userSwipes = false;
  courierCompanyLogo:string;
  shiperLogo:string;
  driverPhoto:string;
  driverName: string;
  stars:ILmRatingItemModel[];
  starRating: ILmRatingModel = LmRating(5);
  CO2: string;
  googlePlaceId: string;
  showRating = false;

  
  constructor(
    public translate: TranslateService,
    public globals: Globals,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private dateTimeCalculatorService: DateTimeCalculatorService,
    private stopPointUtils: StopPointUtils,
    private imageUtils: ImageUtils,
    private doms : DomSanitizer,
    private renderer: Renderer2,
    @Inject(LOCALSTORAGE_CACHE_TOKEN) private _localStorage: LmCacheService
  ) {
    this.pudoCourierTypeEnum = this.globals.pudoCourierTypeEnum;
    this.platform = new H.service.Platform({
      'apikey': 'bd63CgaPUjQlOT5A_hCfWTkbWY8JM5cM-II59BTLuN8',
      useHTTPS: true
    });

    const _hash = location.pathname.split('/')[2];
    this.CO2 = `-${Math.floor(Math.random()*(28-14)+14)}% CO2`;

    this.getData(_hash, 0)
      .pipe(
        switchMap(res => of(res.item)),
        tap(item => {
          const {
              company_depot:{google_place_id},
              driver:{location:{lat=undefined, lon=undefined}={}}={}, angle, is_static,
              pin, is_address_change_disabled, is_smart_point_redirection_disabled, is_pin_scan_mandatory,
              company_client_portal_settings:{live_tracking_color_theme_collaborator, live_tracking_color_theme}
          } = item;

          this.theme = live_tracking_color_theme_collaborator ?? live_tracking_color_theme ?? INITIAL_DATA.defaultTheme;
          this.routesColour = this.theme.color1;
          this.isSpeedexAddressChangeDisabled = is_address_change_disabled;
          this.isSmartPointRedirectionDisabled = is_smart_point_redirection_disabled;
          this.isPinScanMandatory = is_pin_scan_mandatory;
          this.googlePlaceId = google_place_id && !!google_place_id.length ? google_place_id : undefined;

          if(is_pin_scan_mandatory && pin) this.generateQR(document.getElementById('qr-pin-container'), pin);
          if(lat && lon && angle) this.initialTrackingInfo = [lat, lon, angle, is_static];

          this.stars = this.starRating.items;

        }),
        switchMap(item => of(item.company_client_portal_settings.live_tracking_image_hash_collaborator)),
        switchMap(_imgHash => this.getBase64ShipperLogo$(_hash, _imgHash)),
        tap(base64Data => this.renderShipperLogo(base64Data)),
        take(1), untilDestroyed(this)
      )
      .subscribe(_=>  {
        if(this.initialTrackingInfo){
          const ti = {...this.initialTrackingInfo};
          this.trackVehicle(ti[0],ti[1],ti[2],ti[3]);
        }
      })
  }

  drawerSwiped(e:ILmSwipeEvent){
    const {element:{status, state}} = e;
    const limitUp = state[status].max;
    const limitDown = state[status].min;
    
    if(e.incrY <= limitUp) {
      e.element.reset();
      e.element.status = 'open';
      this.drawerOpen = true;
    }
    if(e.incrY > limitDown) {
      this.renderer.setStyle(e.element.nativeEl, 'transform', `translateY(235px)`);
      e.element.status = 'closed';
      this.drawerOpen = false;
      if(this.isPinReadyToShow) this.cacheUserSwipedPinHidden();
    };
  }

  private setStatusOpen(){
    this.drawer.setStatus('open');
    this.drawerOpen = true;
  }

  private setStatusClosed(){
    this.drawer.setStatus('closed');
    this.drawerOpen = false;
  }


  private generateQR(el, pin){
    this.drawerPIN = pin;
     const qr = {text: pin, logo: 'assets/lastmilyAssets/logo.png', quietZone: 2, width:140, height:140, colorLight: '#fafafa'};
     new QRCode(el, qr);
  }


  private getBase64ShipperLogo$(hash, imageHash){
    return imageHash ? this.http.get('api/unregistered/v1/image-cloud?hash=' + hash + '&imageHashKey=' + imageHash).pipe(switchMap(res => of((<any>res)?.items))) : of(null) 
  }  

  
  private renderShipperLogo(base64Data){
    
    if(base64Data){
      this.shiperLogo = `data:image/png;base64,${base64Data}`;
      
      const logoIcon = this.svgIconsComponent.shipperBubbleWithLogoDomSvg.replace('BASE_64_HASH', `data:image/png;base64,${base64Data}`);
      this.shipperLogoIcon = new H.map.DomIcon(logoIcon)
    }
  }


  private generateMapIcons(color, pinMsg){
    this.iconBackground = new H.map.Icon(this.svgIconsComponent.svgMakerBackground);
    this.staticIcon = new H.map.Icon(this.svgIconsComponent.svgVehicleMakerClientPortal.replace('markerColour', color));
    this.gapIcon = new H.map.DomIcon(this.svgIconsComponent.GAPmarkerBubble);
    this.stopPointIcon = this.isPinScanMandatory ? 
      new H.map.DomIcon(this.svgIconsComponent.pinMarkerWithHoleAndBubbleDomSvg(color, pinMsg)) :
      new H.map.Icon(this.svgIconsComponent.svgPinMarkerWithHole(color));
  }


  private cacheUserSwipedPinHidden(){
    this.userHidPin = true;
    this._localStorage.set('lm-livetracking-user-hid-pin', 'true');
  }


  private decacheUserSwipedPinHidden$(){
    return this._localStorage.get('lm-livetracking-user-hid-pin');
  }


  private cacheDrawerTip(){
    this._localStorage.set('lm-livetracking-disable-drawer-pin', 'true');
    setTimeout(_=> this.renderer.addClass(this.drawer.nativeEl.querySelector('#drawer-tip'), 'lm-fade-out'), 2000);
    setTimeout(_=> this.showDrawerTip = false, 5000);
  }

  private deCacheDrawerTip$(){
    return this._localStorage.get('lm-livetracking-disable-drawer-pin')
  }
  

  public getData(hash, lastLocationId): Observable<any> {
    this.trackRequestDone = false;
    return this.http.get('api/unregistered/v1/hash-view?hash=' + hash + '&lastLocationId=' + lastLocationId);
  }

  vehicleTracker(hash) {

    this.getData(hash, this.lastLocationId).subscribe(response => {    
      const data = response['item'];

      if(!this.RS){
        const {item:{company_client_portal_settings:{live_tracking_reschedule_options}}} = response;
        this.RS = this.Rescheduler(live_tracking_reschedule_options);
        this.rescheduleOptions = this.RS.options;
      }
      this.hash = hash;
      this.trackRequestDone = true;

      if(!this.stopPointIcon) this.generateMapIcons(this.routesColour, this.pinMsg);

      this.isPinReadyToShow = data.is_pin_ready_to_show;

      if(this.isPinReadyToShow && !this.userHidPin) {
        this.drawerOpen = true;
        if(this.drawer) this.drawer.setStatus('open');
      }

      if (response) {
        this.isGAP = response.item?.is_gap_company

        const {item:{pudo_type}} = response;

        this.isPudoEnabled = pudo_type && this.pudoCourierTypeEnum['PUDO_POINTS_LASTMILY'] === pudo_type ? true : false;


        if(this.isPudoEnabled){
          const {item:{pudoShipment}} = response;
          
          if(pudoShipment){
            const {fulfillment_event, qr_code_identifier, pudo_point} = pudoShipment;
            this.pudoPreventRatingScreen = fulfillment_event[0].reason === 10400 ? true : false;

            if(this.pudoPreventRatingScreen && qr_code_identifier && !this.pudoQR) {
              const {pin} = pudoShipment;
              this.populatePudoInfo(pudo_point);
              this.pointPIN = pin;
              this.pudoQR = {
                text: qr_code_identifier,
                logo: 'assets/lastmilyAssets/logo.png',
                quietZone: 10,
                colorLight: '#fafafa',
              };

              const qrContainer = document.getElementById('qr-container');

              if(qrContainer && qrContainer.childNodes.length === 0){
                new QRCode(document.getElementById('qr-container'), this.pudoQR);
              }
              
            }
          }
        }
        
        document.getElementById('error-text').classList.add('hidden');
        this.emptyMap();
        
        const {cancelation_redirection_url} = data;

        if(cancelation_redirection_url){
          this.isEasyMailUser = true;
          this.easyMailCancelUrl = cancelation_redirection_url;
        }
        
        this.isChatEnabled = response['item'].is_chat_enabled ?? false;
        this.canSelectSmartPoint = (data.is_smart_points_enabled && !data.is_smart_point_redirection_disabled) || this.isPudoEnabled;
        this.fulfillmentReason = data.fulfillment_reason ? data.fulfillment_reason : null;
        
        const startDateTime = moment(data.startDateTime);
        let routeStartDateTimeFormatted = startDateTime.format('DD-MM-YYYY, HH:mm');
        let nowToStart = moment.duration(moment().diff(startDateTime)).asMilliseconds();
        if (data.routeStartDateTime) {
          const routeStartDateTime = moment(data.routeStartDateTime);
          // if routeStartDatetime is older than startDateTime, use routeStartDateTime to compare with the current time
          const startDifference = moment.duration(routeStartDateTime.diff(startDateTime)).asMinutes();
          if (startDifference < 0) {
            routeStartDateTimeFormatted = routeStartDateTime.format('DD-MM-YYYY, HH:mm');
            nowToStart = moment.duration(moment().diff(routeStartDateTime)).asMilliseconds();
          }
        }
        // if the start time has passed, show the map
        if (nowToStart > 0) {
          if (data.oldRoute.lines.length || data.oldRoute.lastLocationId) {
            // this.isDriverVisible = data.;
            this.canChangeDateTime = data.company_client_portal_settings.is_client_change_datetime_enabled;
            this.canCancel = data.company_client_portal_settings.is_client_cancel_enabled;
            this.canChangeAddress = data.company_client_portal_settings.is_client_change_address_enabled;
            this.canRate = data.company_client_portal_settings.is_rating_enabled;

            if (data.driver) {
              if (data.driver.name) {
                const driverName = data.driver.name;
                const nameWords = driverName.split(" ");
                this.driverName = this.clientNavbarComponent.driverName = nameWords[0];
              }
              if (data.driver.driverImage) {
                this.driverPhoto = this.clientNavbarComponent.avatarBase64 = this.clientNavbarComponent.avatarSrc.replace('AVATAR_HASH', data.driver.driverImage);
              }
              if (data.driver.imageHash && !this.clientNavbarComponent.avatarBase64) {
                this.loadDriverAvatarAsync(hash, data.driver.imageHash);
              }
              if (data.driver.location) {
                this.trackVehicle(data.driver.location.lat, data.driver.location.lon, data.angle, data.is_static);
              }
            }

            if (data.companyLogo) {
              this.courierCompanyLogo = this.clientNavbarComponent.logoBase64 = this.clientNavbarComponent.logoSrc.replace('LOGO_HASH', data.companyLogo);

            }
            if (data.imageHash && !this.clientNavbarComponent.logoBase64) {
              this.loadMainLogoAsync(hash, data.imageHash);
            }

            this.lastLocationId = data.oldRoute.lastLocationId;

            document.getElementById('error-text').classList.add('hidden');
            this.clientNavbarComponent.companyName = data.company_name;
            let estimatedTime = '-';
            let estimatedDeliveryDate = '-';
            let durationInMinutes = false;
            if (data.startEstimationTime) {
              estimatedTime = moment(data.startEstimationTime).format('HH:mm');
              if(data.startEstimationTime != data.endEstimationTime){
                this.clientNavbarComponent.estimatedTimeEnd = moment(data.endEstimationTime).format('HH:mm');
              } else {
                estimatedDeliveryDate = moment(data.startEstimationTime).format('DD-MM-YYYY');
                var start = moment.utc(data.startEstimationTime);
                var now = moment.utc();
                const duration = moment.duration(start.diff(now));
                const durationMinutes = duration.asMinutes();
                if (durationMinutes < 30 && durationMinutes > 0) {
                  durationInMinutes = true;
                  const estimatedTimeString = this.dateTimeCalculatorService.calculateDelayInHoursAndMinutes(duration);
                  estimatedTime = estimatedTimeString.replace('MINUTES_MSG', this.minutesShortMsg).replace('HOURS_MSG', this.hoursShortMsg);
                }
              }
              this.clientNavbarComponent.estimatedTime = estimatedTime;
            }
            // the fulfillment status is hard coded because we don't have access to global data from the Live Tracking
            // if the sp is arrived, canceled or complete, the recipient can't cancel it
            this.fulfillmentStatus = data.fulfillment_status;
            this.fulfillmentReason = data.fulfillment_reason;
            if (this.stopPointUtils.isArrived(data.fulfillment_reason)) {
              this.clientNavbarComponent.estimatedTimeLabel = this.timeArrived ? (this.timeArrived + ':') : '';
              this.canCancel = false;
              clearInterval(this.interval);
            } else if (this.stopPointUtils.isCanceled(data.fulfillment_reason)) {

              this.clientNavbarComponent.estimatedTimeLabel = this.canceled ? this.canceled : '';
              this.canCancel = false;
              if (data.application_type !== this.globals.requestedByConstants['CLIENT_PORTAL_APP']) {
                this.errorText = data.company_client_portal_settings.portal_message_template;
              } else {
                this.errorText = this.getCancelMsg(this.fulfillmentReason);
              }
              document.getElementById('error-text').classList.remove('hidden');
              clearInterval(this.interval);
            }
            else if (this.stopPointUtils.isInProgressPending(data.fulfillment_reason)) {
              
              this.errorText = this.inProgressSoonMsg;
              if(this.isPudoEnabled) this.errorText = this.doms.bypassSecurityTrustHtml(`
              <br><b class="lm-txt-dark-color-mily" style="font-size:2rem">${this.inProgressSoonMsg}!</b><br><br>
                ${this.inProgressPudoPickupPinMsg}<br>
                <b>${this.inProgressCheckAgainMsg}</b>
              `)
              document.getElementById('error-text').classList.remove('hidden');
              clearInterval(this.interval);
            }
            else if (this.stopPointUtils.isCompleted(data.fulfillment_reason)) {              
              this.clientNavbarComponent.estimatedTimeLabel = this.completedAt ? (this.completedAt + ':') : '';
              this.isRated = data.stop_point_url_hash.is_rated;
              this.canCancel = false;
              this.thankYouMessage = data.company_client_portal_settings.thank_you_rating_message;
              this.fbLinkEnabled = data.company_client_portal_settings.is_fb_link_enabled;
              this.googleLinkEnabled = data.company_client_portal_settings.is_google_link_enabled;
              this.fbLink = data.company_client_portal_settings.fb_link;
              this.googleLink = data.company_client_portal_settings.google_link;
              if (!this.fbLink) {
                this.fbLinkEnabled = false;
              }
              if (!this.googleLink) {
                this.googleLinkEnabled = false;
              }
              // this.showRatingScreen();
              this.showRating = true;
              clearInterval(this.interval);
            }
            // show smart point overlay message
            else if (data.smartPoint) {
              if (data.smartPoint['requested_by'] == this.globals.requestedByConstants['MOBILE_APP']) {
                this.smartPointData = data.smartPoint;
                document.getElementById('error-text-smart-point').classList.remove('hidden');
                this.canCancel = false;
              }
            }
            else {
              if (durationInMinutes) {
                this.clientNavbarComponent.estimatedTimeLabel = this.estimatedTimeIn ? (this.estimatedTimeIn + ':') : '';
              } else {
                this.clientNavbarComponent.estimatedTimeLabel = this.estimatedTime ? (this.estimatedTime + ':') : '';
              }
            }
            this.clientNavbarComponent.estimatedTime = estimatedTime ? estimatedTime : '';
            this.clientNavbarComponent.estimatedDeliveryDate = estimatedDeliveryDate;
            if (data.pay_amount) {
              this.clientNavbarComponent.payAmount = data.pay_amount;
            }
            this.clientNavbarComponent.address = data.address;
            this.clientNavbarComponent.addressMobile = data.address.split(',')[0];
            this.clientNavbarComponent.contactName = data.contact_name;
            if (this.clientNavbarComponent.contactName === '_NO_NAME') {
              this.clientNavbarComponent.contactName = this.noNameLabel;
            }

            if (data.solution && data.solution !== 'null') {
              this.addEncodedElements(data.solution, data.angle, data.is_static);
            }
            data.oldRoute.lines.forEach(lineSegment => {
              if (lineSegment.line !== '????') {
                this.addPreviousRoutes(lineSegment.line, '#8adbe0', lineSegment.time);
              }
            });
            if (!this.destinationMarker) {
              this.addStopPoint(data.lat, data.lon);
            }
            if (!this.boundsSet) {
              setTimeout(() => {
                this.map.getViewPort().resize();
                this.setMapBounds();
              }, 500);
              this.boundsSet = true;
            }
          } else {
            // getting vehicles data
            this.errorText = this.gettingTrackingMSG;
            document.getElementById('error-text').classList.remove('hidden');
          }
        } else {
          // come back in ...
          this.errorText = this.routeBeginMSg + routeStartDateTimeFormatted;
          document.getElementById('error-text').classList.remove('hidden');
          clearInterval(this.interval);
        }
      } else {
        this.errorText = this.wrongUrl;
        document.getElementById('error-text').classList.remove('hidden');
        clearInterval(this.interval);
      }
    },
      error => {
        this.trackRequestDone = true;
        this.errorText = this.wrongUrl;
        document.getElementById('error-text').classList.remove('hidden');
        clearInterval(this.interval);
      });
  }

  
  ngOnInit() {
    var comm100 = document.querySelector('[id^="comm100-button"]');
    if (comm100) comm100.classList.add('hidden');
    var comm1002 = document.querySelector('[id^="comm100-container"]');
    if (comm1002) comm1002.classList.add('hidden');
    
    this.decacheUserSwipedPinHidden$().pipe(take(1), untilDestroyed(this)).subscribe(res => res && (this.userHidPin = true));

    this.deCacheDrawerTip$().pipe(take(1), untilDestroyed(this)).subscribe(res => res && (this.showDrawerTip = false));

    let hash;
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.activatedRoute.params.subscribe(params => { hash = params['hash']; });
    this.vehicleTracker(hash);
    this.interval = setInterval(() => {
      if (this.trackRequestDone) {
        this.vehicleTracker(hash);
      }
    }, 10000);
  }


  // load & create smart points on map
  

  // smart points
  onSmartPointsInputChanged(event) {
    clearInterval(this.searchSmartPointsTimeout);
    this.searchSmartPointsTimeout = setTimeout(() => {
      const searchQuery = (<HTMLInputElement>document.getElementById('smart-points-input-field')).value;
      
      const _path = this.isPudoEnabled ? `pudo-points?hash=${this.hash}&` : 'smart-points?';

      if (searchQuery != '' && searchQuery != null) {
              
        this.http.get(`api/unregistered/v1/${_path}searchQuery=${searchQuery}&page=${this.smartPointsPage}`).subscribe(res => {
          this.totalSmartPointsPages = res['itemsMeta']['pagesCount'];
          this.smartPoints = res['items'];

          if ((<HTMLInputElement>document.getElementById('smart-points-input-field')).value == '' || (<HTMLInputElement>document.getElementById('smart-points-input-field')).value == null) {
            this.isSmartPointsDropdownOpen = false;
          } else {
            document.getElementById('dropdown-container').scrollTop = 0;
            this.isSmartPointsDropdownOpen = true;
          }
        });
      } else {
        this.isSmartPointsDropdownOpen = false;
        this.emptySmartPointsDropdown();
      }
    }, 1200);
  }

  closeSmartPointsDropdown(erase) {
    if (erase) {
      (<HTMLInputElement>document.getElementById('smart-points-input-field')).value = '';
    }

    this.isSmartPointsDropdownOpen = false;
    this.emptySmartPointsDropdown();
  }

  smartPointsDropdownScroll() {
    const elem = document.getElementById('dropdown-container');
    if (elem.scrollHeight / 1.2 < elem.scrollTop + elem.clientHeight && !this.isLoading) {
      if (this.smartPointsPage < this.totalSmartPointsPages - 1) { // -1 because first page is loaded in the initial search
        this.isLoading = true;
        this.smartPointsPage += 1;
        const searchQuery = (<HTMLInputElement>document.getElementById('smart-points-input-field')).value;
        this.http.get('api/unregistered/v1/smart-points?searchQuery=' + searchQuery + '&page=' + this.smartPointsPage).subscribe(res => {
          this.smartPoints = this.smartPoints.concat(res['items']);
          this.smartPoints = [...this.smartPoints];
          this.isLoading = false;
        });
      }
    }
  }

  smartPointSelect(smartPoint) {
    this.selectedSmartPoint = smartPoint;
    const _label =  this.isPudoEnabled ?
      `${smartPoint.description ?? ''} - ${smartPoint.address?.value ?? ''}` :
      `${smartPoint.name ?? ''} - ${smartPoint.address?.street ?? ''}, ${smartPoint.address?.postalCode ?? ''}, ${smartPoint.address?.city ?? ''}`

    ;(<HTMLInputElement>document.getElementById('smart-points-input-field')).value = _label;
    this.closeSmartPointsDropdown(false);

    this.map.setCenter({ lat: smartPoint['address']['lat'], lng: smartPoint['address']['lon'] });
    this.map.setZoom(15);
  }

  smartPointClick(event) {
    this.selectedSmartPoint = event.target.getData().smartPoint;

    const _label =  this.isPudoEnabled ?
      `${this.selectedSmartPoint.description ?? ''} - ${this.selectedSmartPoint.address?.value ?? ''}` :
      `${this.selectedSmartPoint.name ?? ''} - ${this.selectedSmartPoint.address?.street ?? ''}, ${this.selectedSmartPoint.address?.postalCode ?? ''}, ${this.selectedSmartPoint.address?.city ?? ''}`;

    (<HTMLInputElement>document.getElementById('smart-points-input-field')).value = _label;
  }


  smartPointSubmit() {
    if (this.selectedSmartPoint) {
      const pudoPayload =  {
        stopPoint: {
            fulfillment_event: { reason: 1220, description: 'From Live Tracking.'}, 
            pudoPoint: { 
              pudo_point_id: this.selectedSmartPoint.id, 
              pudoShipmentStopPoint: {  type:1, order:1 }, 
              pudoFulfillment : {fulfillment_event: { reason: 10200, description: "Scheduled" }} 
            }, 
            agreed_shipping:[] 
        }
      }

      const smartPayload = {
        stopPoint: {
          fulfillment_event: { reason: this.globals.smartPointFulfillmentConstant, description: ''}, 
          smartPoint: {
            code: this.selectedSmartPoint['code'],
            prefix: this.selectedSmartPoint['prefix'],
          }
        }
      };

      const selectedSmartPointObj = this.isPudoEnabled ? pudoPayload : smartPayload;

      // this.smartPointsGroup.removeAll();
      //   this.closeNotThereModal();
      //   this.isSmartPointSelectMode = false;
      //   this.canCancel = false;

      this.http.put('api/unregistered/v1/client-stop-point/' + this.hash, selectedSmartPointObj).subscribe(res => {
        this.smartPointsGroup.removeAll();
        this.closeNotThereModal();
        this.isSmartPointSelectMode = false;
        this.canCancel = false;        
      });
    }
  }

  emptySmartPointsDropdown() {
    document.getElementById('dropdown-container').scrollTop = 0;
    this.smartPointsPage = 0;
    this.totalSmartPointsPages = 0;
    this.smartPoints = [];
  }

  postCancelReason() {
    let agreedShipping = [];
    const agreedShippingObject = {
      id: null,
      date: '',
      start: '',
      end: ''
    }
    if (this.selectedDate) {
      agreedShippingObject.date = moment(this.selectedDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      agreedShipping = [agreedShippingObject];
    }
    if (this.selectedTimeStart && this.selectedTimeEnd) {
      agreedShippingObject.start = this.selectedTimeStart;
      agreedShippingObject.end = this.selectedTimeEnd;
      agreedShipping = [agreedShippingObject];
    }
    const data = {
      stopPoint: {
        fulfillment_status: this.globals.stopPointFulfillmentStatusConstants['CANCELED'],
        fulfillment_event: {
          reason: this.selectedReasonConstant,
          description: this.cancelReasonDescription
        },
        agreed_shipping: agreedShipping
      }
    };
    if (this.selectedAddress['address']) {
      if (this.selectedAddress['address']['lat'] && this.selectedAddress['address']['lon']) {
        data.stopPoint['address'] = this.selectedAddress['address'];
      }
    }

    this.http.put('api/unregistered/v1/client-stop-point/' + this.hash, data).pipe(take(1)).subscribe(response => {
      this.closeNotThereModal();
    },
      error => {
        console.error(error);
      });
  }

  selectDefaultTimes() {
    const firstStartHour = document.querySelector('.start-hour-option[time="8:00"]') as HTMLElement;
    const lastEndHour = document.querySelector('.end-hour-option[time="18:00"]') as HTMLElement;
    if (firstStartHour) {
      firstStartHour.click();
    }
    if (lastEndHour) {
      lastEndHour.click();
    }
    const endHoursContainer = document.getElementById('end-hours-container');
    if (endHoursContainer) {
      endHoursContainer.scrollTo(0, endHoursContainer.scrollHeight);
    }
  }

  getSelectedAddress() {
    return this.portalAddressMapComponent.myForm.value;
  }

  addressSelectedListen(event) {
    if (event === 'true') {
      this.disableNext = false;
    } else if (event === 'false') {
      this.disableNext = true;
    }
  }


  loadAddressPicker() {
    this.disableNext = true;
    this.selectedAddress = this.getSelectedAddress();
    if (this.selectedAddress['address']) {
      if (this.selectedAddress['address']['lat'] && this.selectedAddress['address']['lon']) {
        this.disableNext = false;
      }
    }
    this.modalAddressView.nativeElement.classList.add('open');
    this.modalWindowOpen = 'address';
  }

  loadConfirm() {
    if (!this.confirmMsg) {
      this.confirmMsg = this.cancelConfirmMsg;
    }
    if (!this.confirmMsg2) {
      this.confirmMsg = this.cancelConfirmMsg2;
    }
    this.modalConfirmView.nativeElement.classList.add('open');
    this.modalWindowOpen = 'confirm';
  }

  loadOptions() {
    this.selectedReasonConstant = null;
    this.confirmMsg = '';
    this.confirmMsg2 = '';
    this.modalOptionsView.nativeElement.classList.add('open');
    this.modalWindowOpen = 'options';
    this.goNext = false;
  }

  openNoteModal() {
    this.noteModalComponent.openModal();
  }

  activateRescheduler() {
    this.confirmMsg = this.cancelConfirmMsg;
    this.confirmMsg2 = this.cancelConfirmMsg2;
    this.disableNext = true;
    
    this.renderer.addClass(this.rescheduleCtrl.nativeElement, 'open')
    this.modalWindowOpen = 'reschedule';
  }

  

  rescheduleDelivery(goBack = false){
    switch (this.modalWindowOpen) {
      // if we are in the options view load time select
      case 'options':
        if (goBack) this.closeNotThereModal();
        else {
          this.closeOpenModalView();
          this.activateRescheduler();
        }
        break;
      case 'reschedule':
        if (goBack) {
          this.closeOpenModalView();
          this.loadOptions();
        } 
        else {
          this.renderer.removeClass(this.rescheduleCtrl.nativeElement, 'open')
          this.loadConfirm();
        }
        break;
      case 'confirm':
        if (goBack) {
          this.closeOpenModalView();
          this.activateRescheduler();
          this.disableNext = false;
        } else {
          this.confirmMsg = '';
          this.confirmMsg2 = '';
          this.postCancelReason();
        }
        break;
    }
  }



  rescheduleDeliveryAndAddress(goBack = false){
    switch (this.modalWindowOpen) {
      // if we are in the options view load time select
      case 'options':
        if (goBack) this.closeNotThereModal();
        else {
          this.closeOpenModalView();
          this.activateRescheduler();
        }
        break;
        case 'reschedule':
          if (goBack) {
            this.closeOpenModalView();
            this.loadOptions();
          } 
          else {
            this.renderer.removeClass(this.rescheduleCtrl.nativeElement, 'open')
            this.loadAddressPicker();
            this.portalAddressMapComponent.mapToggled();
          }
        break;
      case 'address':
        if (goBack) {
          this.closeOpenModalView();
          this.activateRescheduler();
          this.disableNext = false;
        } else {
          if (!this.disableNext) {
            this.modalAddressView.nativeElement.classList.remove('open');
            this.selectedAddress = this.getSelectedAddress();
            this.loadConfirm();
          }
        }
        break;
      case 'confirm':
        if (goBack) {
          this.closeOpenModalView();
          this.disableNext = false;
          this.loadAddressPicker();
        } else {
          this.confirmMsg = '';
          this.confirmMsg2 = '';
          this.postCancelReason();
        }
        break;

    }
  }

  anotherAddressDelivery(goBack = false) {
    switch (this.modalWindowOpen) {
      // if we are in the options view load time select
      case 'options':
        if (goBack) {
          this.closeNotThereModal();
        } else {
          this.closeOpenModalView();
          this.loadAddressPicker();
          this.portalAddressMapComponent.mapToggled();
        }
        break;
      case 'address':
        if (goBack) {
          this.closeOpenModalView();
          this.disableNext = false;
          this.loadOptions();
        } else {
          if (!this.disableNext) {
            this.modalAddressView.nativeElement.classList.remove('open');
            this.selectedAddress = this.getSelectedAddress();
            this.loadConfirm();
          }
        }
        break;
      case 'confirm':
        if (goBack) {
          this.closeOpenModalView();
          this.loadAddressPicker();
        } else {
          this.confirmMsg = '';
          this.confirmMsg2 = '';
          this.postCancelReason();
        }
        break;
    }
  }

  nextDayDelivery(goBack = false) {
    switch (this.modalWindowOpen) {
      case 'options':
        if (goBack) {
          this.closeNotThereModal();
        } else {
          this.closeOpenModalView();
          this.loadConfirm();
        }
        break;
      case 'confirm':
        if (goBack) {
          this.closeOpenModalView();
          this.loadOptions();
        } else {
          this.confirmMsg = '';
          this.confirmMsg2 = '';
          this.postCancelReason();
        }
        break;
    }
  }

  cancelDelivery(goBack = false) {
    switch (this.modalWindowOpen) {
      case 'options':
        if (goBack) {
          this.closeNotThereModal();
        } else {
          this.closeOpenModalView();
          this.loadConfirm();
        }
        break;
      case 'confirm':
        if (goBack) {
          this.closeOpenModalView();
          this.loadOptions();
        } else {
          this.confirmMsg = '';
          this.postCancelReason();
        }
        break;
    }
  }

  nextButtonClicked(pudo?) {
    this.goNext = true;
    this.step++;
    switch (this.selectedReason) {
      case 'next-day':
        this.confirmMsg = this.nextDayConfirmMsg;
        this.confirmMsg2 = this.nextDayConfirmMsg2;
        this.selectedReasonConstant = this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['NOT_THERE'];
        this.nextDayDelivery();
        break;
      case 'reschedule':
      this.confirmMsg = this.otherDayConfirmMsg;
      this.confirmMsg2 = this.otherDayConfirmMsg2;
      this.selectedReasonConstant = this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['AGREED_SHIPPING'];
      this.rescheduleDelivery();
      break;
      case 'other-address':
        this.confirmMsg = this.otherAddressConfirmMsg;
        this.confirmMsg2 = this.otherAddressConfirmMsg2;
        this.selectedReasonConstant = this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['WRONG_ADDRESS'];
        this.anotherAddressDelivery();
        break;
        case 'reschedule-and-address':
          this.confirmMsg = this.otherDayAndAddressConfirmMsg;
          this.confirmMsg2 = this.otherDayAndAddressConfirmMsg2;
          this.selectedReasonConstant = this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['AGREED_SHIPPING_WRONG_ADDRESS'];
          this.rescheduleDeliveryAndAddress();
          break;
      case 'smart-point':
        this.confirmMsg = this.smartPointConfirmMsg;
        this.confirmMsg2 = this.smartPointConfirmMsg2;
        this.isSmartPointSelectMode = true;
        this.loadSmartPointsOnMap();
        this.closeNotThereModal();
        break;
        case 'qr-screen':
          this.confirmMsg = this.smartPointConfirmMsg;
          this.confirmMsg2 = this.smartPointConfirmMsg2;
          this.isSmartPointSelectMode = true;
          // this.loadSmartPointsOnMap();
          // this.closeNotThereModal();
          break;
      case 'cancel':
        this.confirmMsg = this.cancelConfirmMsg;
        this.confirmMsg2 = this.cancelConfirmMsg2;
        this.selectedReasonConstant = this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['DID_NOT_ACCEPT_IT'];
        this.cancelDelivery();
        break;
    }
  }


  loadSmartPointsOnMap() {
    const _path = this.isPudoEnabled ? `pudo-points?hash=${this.hash}` : 'smart-points';

    this.http.get(`api/unregistered/v1/${_path}`).subscribe(res => {
      const iconSmartPoint = new H.map.DomIcon(this.svgIconsComponent.smartPointMarker);

      res['items'].forEach(point => {
        const smartPointMarker = new H.map.DomMarker({ lat: Number(point.address.lat), lng: Number(point.address.lon) }, { icon: iconSmartPoint });
        smartPointMarker.setData({
          smartPoint: point
        });
        smartPointMarker.addEventListener('pointerdown', (event) => {
          this.smartPointClick(event);
        });
        smartPointMarker.addEventListener('pointerdown', (event) => {
          this.smartPointClick(event);
        });

        this.smartPointsGroup.addObject(smartPointMarker);
        this.map.addObject(this.smartPointsGroup);
      });
    });
  }

  closeNotThereModal() {
    this.modal.nativeElement.classList.remove('open');
    this.closeOpenModalView();
    this.modalConfirmView.nativeElement.classList.remove('open');
    // if(!this.isPudoEnabled) 
    this.removeSelectedReason();
    this.resetNotThereModal();
    this.selectedSmartPoint = null;
    this.showDrawer = true;
    if (document.getElementById('smart-points-input-field')) {
      (<HTMLInputElement>document.getElementById('smart-points-input-field')).value = '';
    }
  }

  resetNotThereModal() {
    this.selectedSmartPoint = null;
    this.selectedReasonConstant = null;

    this.selectedDate = undefined;
    this.selectedTimeStart = undefined;
    this.selectedTimeEnd = undefined;

    this.selectedAddress = {};
    this.portalAddressMapComponent.resetForm();
    this.step = 0;
    this.RS.reset();
  }


  previousButtonClicked() {
    if(this.step > 0) this.step--;
    if (this.selectedReasonConstant) {
      switch (this.selectedReason) {
        case 'next-day':
          this.nextDayDelivery(true);
          break;
        case 'reschedule':
          this.rescheduleDelivery(true);
          break;
        case 'other-address':
          this.anotherAddressDelivery(true);
          break;
          case 'reschedule-and-address':
           this.rescheduleDeliveryAndAddress(true);
          break;
        case 'cancel':
          this.cancelDelivery(true);
          break;
        default:
          this.closeNotThereModal();
      }
    } else {
      this.closeNotThereModal();
      this.disableNext = true;
    }
  }

  notThereClicked() {
    this.openNotThereModal();
  }

  backButtonSmartPointsClicked() {
    if(this.step > 0) this.step--;
    this.smartPointsGroup.removeAll();
    this.isSmartPointSelectMode = false;
    this.goNext = false;
    this.openNotThereModal();
  }

  nextButtonSmartPointsClicked() {
    if (this.selectedSmartPoint) {
      this.step++;
      this.confirmMsg = this.smartPointConfirmMsg;
      this.confirmMsg2 = this.smartPointConfirmMsg2 + ':';
      // this.selectedReasonConstant = 801; // this will be decided later...
      this.openNotThereModal();
      // this.nextDayDelivery();
      this.closeOpenModalView();
      this.loadConfirm();
    }
  }

  smartPointNameChange(event) {
    // console.log(event);
  }

  smartPointInputName(event) {
    // console.log(event);
    // this.smartPointsNgSelect.filter('test');
  }

  // show rating screen when fulfillment_status is returned completed (basically, removes the 'large' class from the map to make way for the 'Send' button)
  showRatingScreen() {
    document.getElementById('map').classList.remove('large');
  }

  // highlight all previous stars on hover
  highlightStars(hoveredStarIndex) {
    if (!this.starsSet) {
      for (let index = 0; index <= hoveredStarIndex; index++) {
        document.getElementById('star-' + index).style.fill = "#00aeba";
      }
    }
  }

  // unhighlight all previous stars on hover
  unhighlightStars() {
    if (!this.starsSet) {
      for (let index = 0; index < this.ratingStars.length; index++) {
        document.getElementById('star-' + index).style.fill = "#999";
      }
    }
  }

  // set the recipient's rating
  setRating(starIndex) {
    this.starsSet = false;
    this.unhighlightStars();
    this.highlightStars(starIndex)
    this.starsSet = true;
    this.ratingValue = (starIndex + 1) * 2;
  }

  submitRating(){
    const data = {
      stopPoint: {
        fulfillment_status: this.globals.stopPointFulfillmentStatusConstants['COMPLETED'],
        rating: this.starRating.score
      }
    };
    this.http.put('api/unregistered/v1/client-stop-point/' + this.hash, data)
      .pipe(take(1), untilDestroyed(this))
      .subscribe();

    setTimeout(() => this.isRated = true);
  }


  googleRate(){
    const w = 380;
    const x = innerWidth/2-w/2;
    const h = 580;
    const y = innerHeight/2-h/2
    window.open( `https://search.google.com/local/writereview?placeid=${this.googlePlaceId}`, 'parent', `left=${x}, top=${y}, toolbar=no, location=no, directories=no,status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}`); 
  }


  // submit the recipient's rating
  sendRating() {
    this.isRated = true;

    const data = {
      stopPoint: {
        fulfillment_status: this.globals.stopPointFulfillmentStatusConstants['COMPLETED'],
        rating: this.ratingValue,
        rating_note: this.noteModalComponent.note
      }
    };
    this.http.put('api/unregistered/v1/client-stop-point/' + this.hash, data).pipe(take(1)).subscribe(response => {
      // document.getElementById('map').classList.add('large');
      // document.querySelector('rating-container').style.height = '100vh';
    });
  }

  removeSelectedReason() {
    const previouslySelectedReason = document.querySelector('.modal-option.selected');
    if (previouslySelectedReason) {
      previouslySelectedReason.classList.remove('selected');
      this.renderer.removeStyle(previouslySelectedReason, 'background-color');
    }
    this.selectedReason = '';
  }

  cancelReasonClicked(reason) {
    this.disableNext = false;
    this.removeSelectedReason();
    const reasonToSelect = document.getElementById('reason-' + reason);
    if (reasonToSelect) {
      reasonToSelect.classList.add('selected');
      this.renderer.setStyle(reasonToSelect, 'background-color', this.routesColour);
    }
    this.selectedReason = reason;
  }



  goNext = false;
  openNotThereModal() {
    this.modalOptionsView.nativeElement.classList.add('open');
    this.modal.nativeElement.classList.add('open');
    this.modalWindowOpen = 'options';
    this.showDrawer = false;
    // this.disableNext = false;
  }

  closeOpenModalView() {
    const openView = document.querySelector('.modal-view.open');
    if (openView) {
      openView.classList.remove('open');
    }
    this.modalWindowOpen = '';
  }

 
  addStopPoint(lat, lon) {
    let coords;
    coords = { lat: lat, lng: lon };
    this.destinationMarker = this.isPinScanMandatory ? 
      new H.map.DomMarker(coords, { icon: this.stopPointIcon }) : 
      new H.map.Marker(coords, { icon: this.stopPointIcon });
    this.clientMarkersGroup.addObject(this.destinationMarker);
    this.clientMarkersGroup.addEventListener('tap', ()=> this.setDrawerStatus(), false)
  }

  setDrawerStatus(){
    if(this.drawer){
      const _status = this.drawer.status;
    
      if(_status === 'open') {
        this.setStatusClosed();
        if(this.isPinReadyToShow) this.cacheUserSwipedPinHidden();
      }
      else this.setStatusOpen();
    }
  }


  addEncodedElements(encodedPolyline, angle, isStatic) {
    const decodedPolyline = polyline.decode(encodedPolyline);
    const linestring = new H.geo.LineString();
    let linePoint;
    decodedPolyline.forEach((point, i) => {
      linePoint = {
        lat: point[0],
        lng: point[1]
      };
      linestring.pushPoint(linePoint);
    });
    this.routeMapObject = new H.map.Polyline(linestring, {
      style: {
        lineWidth: 7,
        strokeColor: this.routesColour,
      }
    });
    this.clientSolutionGroup.addObject(this.routeMapObject);
  }

  addPreviousRoutes(encodedPolyline, colour, time) {
    const arrivalTime = moment(time).format('HH:mm');
    const bubbleText = '<div>' + this.bubbleTextMsg + ': ' + arrivalTime + '</div>';
    const decodedPolyline = polyline.decode(encodedPolyline);
    const linestring = new H.geo.LineString();
    let linePoint;
    decodedPolyline.forEach((point, i) => {
      linePoint = {
        lat: point[0],
        lng: point[1]
      };
      linestring.pushPoint(linePoint);
    });
    this.routeMapObject = new H.map.Polyline(linestring, {
      style: {
        lineWidth: 6,
        strokeColor: colour,
      },
    });
    const invisiblePolyline = new H.map.Polyline(linestring, {
      style: {
        lineWidth: 15,
        strokeColor: 'rgba(255, 255, 255, 0)',
      },
      data: bubbleText
    });
    invisiblePolyline.addEventListener('tap', this.displayInfoBubble);
    this.clientOldRouteGroup.addObjects([this.routeMapObject, invisiblePolyline]);
  }

  setMapBounds() {
    if (this.clientMapGroup) {
      setTimeout(() => {
        const bounds = this.clientMapGroup.getBoundingBox()
        // const bounds = this.updateBox(this.clientMapGroup.getBoundingBox())
        this.map.getViewModel().setLookAtData({ bounds: bounds }, false);
        this.portalAddressMapComponent.bounds = bounds;
        setTimeout(() => {
          const currentZoomLevel = this.map.getZoom();
          this.map.setZoom(currentZoomLevel - 1);
        }, 100);
      }, 1000);
    }
  }

  updateBox(box) {
    if (box == null) {
      console.warn("zoom To Route. No route to zoom");
      return box;
    }
    const horizontalPercentage = 1.3;
    const verticalPercentage = 0.3;

    // expands geo bounding box 20% width and 20% height(10% top %10 bottom ) so the marker will fit
    const bottomRight = {
      lng: this.calculateNewLongitude(box.getCenter().lng, 30000, box.getCenter().lat),
      lat: this.calculateNewLatitude(box.getCenter().lat, 2000)
    }
    const topLeft = {
      lng: this.calculateNewLongitude(box.getCenter().lng, -30000, box.getCenter().lat),
      lat: this.calculateNewLatitude(box.getCenter().lat, -2000)
    };
    const testBox = new H.geo.Rect(bottomRight.lat.toFixed(5), topLeft.lng.toFixed(5), topLeft.lat.toFixed(5), bottomRight.lng.toFixed(5));


    const horizontalDistance = Math.abs(this.calculateDistance(box.getBottomRight().lat, box.getBottomRight().lng, box.getTopLeft().lat, box.getBottomRight().lng))
    const verticalDistance = Math.abs(this.calculateDistance(box.getBottomRight().lat, box.getBottomRight().lng, box.getBottomRight().lat, box.getTopLeft().lng))
    const horizontalDistanceAfterCalculations = Math.abs(this.calculateDistance(testBox.getBottomRight().lat, testBox.getBottomRight().lng, testBox.getTopLeft().lat, testBox.getBottomRight().lng))
    const verticalDistanceAfterCalculations = Math.abs(this.calculateDistance(testBox.getBottomRight().lat, testBox.getBottomRight().lng, testBox.getBottomRight().lat, testBox.getTopLeft().lng))

    return testBox;
  }

  calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c;
    return d;
  }

  calculateNewLongitude(longitude: number, your_meters: number, latitude: number): number {
    const earth = 6378.137  //radius of the earth in kilometer
    const pi = Math.PI
    const m = (1 / ((2 * pi / 360) * earth)) / 1000;  //1 meter in degree
    return longitude + (your_meters * m) / Math.cos(latitude * (pi / 180));
  }

  calculateNewLatitude(latitude: number, your_meters: number): number {
    const earth = 6378.137  //radius of the earth in kilometer
    const pi = Math.PI
    const m = (1 / ((2 * pi / 360) * earth)) / 1000;  //1 meter in degree
    return latitude + (your_meters * m);
  }

  vehiclesMapStaticObject;
  vehiclesMapMovingObject

  trackVehicle(lat, lon, angle, isStatic) {
    const movingIcon = new H.map.DomIcon(this.svgIconsComponent.svgVehicleMarkerMoving.replace('rotationAngle', angle).replace('markerColour', this.routesColour));

    const vehicleCoords = { lat: lat, lng: lon };
    if (!this.vehiclesMapStaticObject) {
      this.vehiclesMapObjectBackground = new H.map.Marker(vehicleCoords, { icon: this.iconBackground });
      this.vehiclesMapStaticObject = new H.map.Marker(vehicleCoords, { icon: this.staticIcon });
      this.clientMarkersGroup.addObject(this.vehiclesMapObjectBackground);
      this.clientMarkersGroup.addObject(this.vehiclesMapStaticObject);
    }
    else {
      this.vehiclesMapObjectBackground.setGeometry(vehicleCoords);
      this.vehiclesMapStaticObject.setGeometry(vehicleCoords);
    }
    if (!this.vehiclesMapMovingObject) {
      this.vehiclesMapMovingObject = new H.map.DomMarker(vehicleCoords, { icon: movingIcon });
      this.clientMarkersGroup.addObject(this.vehiclesMapMovingObject);
    }
    else {
      this.vehiclesMapMovingObject.setGeometry(vehicleCoords);
      this.vehiclesMapMovingObject.setIcon(movingIcon);
    }
    if (this.shipperLogoIcon) {
      if (!this.shipperBubbleMapObject) {
        this.shipperBubbleMapObject = new H.map.DomMarker(vehicleCoords, { icon: this.shipperLogoIcon });
        this.shipperBubbleMapObject.setZIndex(10)
        this.clientMarkersGroup.addObject(this.shipperBubbleMapObject);
      }
      else {
        this.shipperBubbleMapObject.setGeometry(vehicleCoords);
      }
    } else if (this.isGAP) {
      if (!this.gapMapObject) {
        this.gapMapObject = new H.map.DomMarker(vehicleCoords, { icon: this.gapIcon });
        this.gapMapObject.setZIndex(10)
        this.clientMarkersGroup.addObject(this.gapMapObject);
      }
      else {
        this.gapMapObject.setGeometry(vehicleCoords);
      }
    }
    if (isStatic) {
      this.vehiclesMapObjectBackground.setVisibility(true);
      this.vehiclesMapStaticObject.setVisibility(true);
      this.vehiclesMapMovingObject.setVisibility(false);
    }
    else {
      this.vehiclesMapObjectBackground.setVisibility(false);
      this.vehiclesMapStaticObject.setVisibility(false);
      this.vehiclesMapMovingObject.setVisibility(true);
    }
  }


  emptyMap() {
    this.clientSolutionGroup.removeAll();
  }


  getCancelMsg(fulfillmentReason = null) {
    if (fulfillmentReason === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['CUSTOM']) {
      return this.cancelForNextDayMsg;
    } else if (fulfillmentReason === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['WRONG_ADDRESS']) {
      return this.cancelAnotherAddressMsg;
    } else if (fulfillmentReason === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['DID_NOT_ACCEPT_IT']) {
      return this.cancelNotAcceptMsg;
    } else if (fulfillmentReason === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['AGREED_SHIPPING']) {
      return this.cancelAnotherDateTimeMsg;
    } else if (fulfillmentReason === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['AGREED_SHIPPING_WRONG_ADDRESS']) {
      return this.cancelAnotherDateTimeAddressMsg;
    } else {
      return this.generalCancelMsg;
    }
  }

  openBrowserFacebook() {
    window.open(this.fbLink, '_blank');
  }

  openBrowserGoogle() {
    window.open(this.googleLink, '_blank');
  }

  loadMainLogoAsync(stopPointHash, imageHash) {
    this.imageUtils.fetchImagesViaHashes(`api/unregistered/v1/images/company-logo/${stopPointHash}`, [imageHash]).then(images => {
      this.courierCompanyLogo = this.clientNavbarComponent.logoBase64 = this.clientNavbarComponent.logoSrc.replace('LOGO_HASH', images[0]);
    });
  }

  loadDriverAvatarAsync(stopPointHash, imageHash) {
    this.imageUtils.fetchImagesViaHashes(`api/unregistered/v1/images/drivers/${stopPointHash}`, [imageHash]).then(images => {
      this.driverPhoto = this.clientNavbarComponent.avatarBase64 = this.clientNavbarComponent.avatarSrc.replace('AVATAR_HASH', images[0]);
      console.log('this.driverPhoto:', this.driverPhoto)
    });
  }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.HERE_AT').subscribe((res: string) => { this.bubbleTextMsg = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.TIME_ARRIVED').subscribe((res: string) => { this.timeArrived = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.CANCELED').subscribe((res: string) => { this.canceled = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.COMPLETED_AT').subscribe((res: string) => { this.completedAt = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.ESTIMATED_TIME').subscribe((res: string) => { this.estimatedTime = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.ESTIMATED_TIME_IN').subscribe((res: string) => { this.estimatedTimeIn = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.GETTING_TRACKING_MSG').subscribe((res: string) => { this.gettingTrackingMSG = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.ROUTE_BEGIN_MSG').subscribe((res: string) => { this.routeBeginMSg = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.WRONG_URL').subscribe((res: string) => { this.wrongUrl = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.CANCELED_REASON_DESCRIPTION').subscribe((res: string) => { this.cancelReasonDescription = res; }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));

    this.listen.push(this.translate.get('CLIENT_PORTAL.PERMANENT_CANCEL_CONFIRM').subscribe((res: string) => { this.cancelConfirmMsg = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.PERMANENT_CANCEL_CONFIRM_2').subscribe((res: string) => { this.cancelConfirmMsg2 = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.CHANGE_ADDRESS_CONFIRM').subscribe((res: string) => { this.otherAddressConfirmMsg = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.CHANGE_ADDRESS_CONFIRM_2').subscribe((res: string) => { this.otherAddressConfirmMsg2 = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.CHANGE_DATE_TIME_ADDRESS_CONFIRM').subscribe((res: string) => { this.otherDayAndAddressConfirmMsg = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.CHANGE_DATE_TIME_ADDRESS_CONFIRM_2').subscribe((res: string) => { this.otherDayAndAddressConfirmMsg2 = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.CHANGE_DATE_TIME_CONFIRM').subscribe((res: string) => { this.otherDayConfirmMsg = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.CHANGE_DATE_TIME_CONFIRM_2').subscribe((res: string) => { this.otherDayConfirmMsg2 = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.NEXT_DAY_CONFIRM').subscribe((res: string) => { this.nextDayConfirmMsg = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.NEXT_DAY_CONFIRM_2').subscribe((res: string) => { this.nextDayConfirmMsg2 = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.SMART_POINT_CONFIRM').subscribe((res: string) => { this.smartPointConfirmMsg = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.SMART_POINT_CONFIRM_2').subscribe((res: string) => { this.smartPointConfirmMsg2 = res; }));

    this.listen.push(this.translate.get('CLIENT_PORTAL.CANCELED_ROUTE').subscribe((res: string) => { this.generalCancelMsg = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.CANCELED_NEXT_DAY_MSG').subscribe((res: string) => { this.cancelForNextDayMsg = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.CANCELED_OTHER_DATE_TIME_MSG').subscribe((res: string) => { this.cancelAnotherDateTimeMsg = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.CANCELED_OTHER_ADDRESS_MSG').subscribe((res: string) => { this.cancelAnotherAddressMsg = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.CANCELED_OTHER_DATE_TIME_ADDRESS_MSG').subscribe((res: string) => { this.cancelAnotherDateTimeAddressMsg = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.CANCELED_NOT_ACCEPT').subscribe((res: string) => { this.cancelNotAcceptMsg = res; }));

    this.listen.push(this.translate.get('CLIENT_PORTAL.IN_PROGRESS_SOON_MSG').subscribe((res: string) => { this.inProgressSoonMsg = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.IN_PROGRESS_PUDO_PICKUP_PIN_MSG').subscribe((res: string) => { this.inProgressPudoPickupPinMsg = res; }));
    this.listen.push(this.translate.get('CLIENT_PORTAL.IN_PROGRESS_PUDO_CHECK_AGAIN_MSG').subscribe((res: string) => { this.inProgressCheckAgainMsg = res; }));
     
    this.listen.push(this.translate.get('CLIENT_PORTAL.SMART_POINT_REROUTE_MSG').subscribe((res: string) => { this.smartPointRerouteMsg = res; }));

    this.listen.push(this.translate.get('GENERIC.HOURS_SHORT').subscribe((res: string) => { this.hoursShortMsg = res; }));
    this.listen.push(this.translate.get('GENERIC.MINUTES_SHORT').subscribe((res: string) => { this.minutesShortMsg = res; }));

    this.translate.get('CLIENT_PORTAL.SHOW_PIN_MSG').pipe(take(1), untilDestroyed(this)).subscribe(pinMsg => this.pinMsg = pinMsg)
  }

  // boundsSet = false;
  // lastLocationId = 0;
  // interval;
  // ngOnInit() {
  //   var comm100 = document.querySelector('[id^="comm100-button"]');
  //   if (comm100) {
  //     comm100.classList.add('hidden');
  //   }
  //   var comm1002 = document.querySelector('[id^="comm100-container"]');
  //   if (comm1002) {
  //     comm1002.classList.add('hidden');
  //   }

  //   let hash;
  //   this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
  //     this.getTranslations();
  //   }));
  //   this.getTranslations();

  //   // this.populateHours();

  //   this.activatedRoute.params.subscribe(params => { hash = params['hash']; });
  //   this.vehicleTracker(hash);
  //   this.interval = setInterval(() => {
  //     if (this.trackRequestDone) {
  //       this.vehicleTracker(hash);
  //     }
  //   }, 10000);
  // }

  private populatePudoInfo(point) {
    const {address:{label}, description, has_parking, pudo_point_working_hours, id } = point;
    const today = new Date().getDay() === 0 ? 7 : new Date().getDay();
    const day = (pudo_point_working_hours && !!pudo_point_working_hours.length) ? 
              pudo_point_working_hours.find(({day_of_the_week}) => day_of_the_week === today) : null;
  
    this.pointOpenUntil = day.open_to_time;
    this.pointName = description;
    this.pointAddress = label;
    this.pointHasParking = has_parking;
    this.pudoPointId = id;
  }  
  
  getDatesLang() {
    return this.locales[this.globals.currentLang] || enUS;
  }

  gotoEasyMailCancelationURL() {
    window.location.href = this.easyMailCancelUrl
  }


  RS: ILiveTrackingRescheduler;
  rescheduleOptions: ILiveTrackingRescheduleOption[]

  private Rescheduler({delivery_days, delivery_reschedule_days, delivery_time_frame, delivery_time_range:{end, start}}){
    const parent = this;
    const from = parseInt(start.split(':')[0]);
    const max = parseInt(end.split(':')[0]);
    const frame = extractInteger(delivery_time_frame)
    const until = max - frame;
    const days = Object.values(delivery_days);
    const unused = days.length - days.filter(Boolean).length;
    const datesLang = this.getDatesLang();

    function reset(RS:ILiveTrackingRescheduler){
      if(RS){
        RS.options.forEach(option => {
          option.slots.from.forEach(slot => slot.checked=false);
          option.slots.to.forEach(slot => {
            slot.checked=false; 
            slot.disabled=true;
          });
        })
        RS.activeOption = undefined;
        RS.activeFromSlot = undefined;
        RS.activeToSlot = undefined;
        RS.activeSlots = undefined;
      }
      parent.selectedDate = undefined;
      parent.selectedTimeStart = undefined;
      parent.selectedTimeEnd = undefined;
      parent.disableNext = true;
    }

    function generateFromSlots(RS:ILiveTrackingRescheduler, day){
      return createArrayOfNumbersBetween(from, until)
            .map((num, i) => ({
                id: i,
                label: `${num}:00`,
                value: num, 
                checked: false,
                click: (oi, fi) => {
                  reset(RS);
                  RS.activeOption = RS.options.find(({id}) => id===oi);
                  const slots = RS.activeOption.slots;
                  const fromSlot = RS.activeFromSlot = slots.from[fi];
                  fromSlot.checked = true;

                  parent.selectedDate = <any>day;
                  parent.selectedTimeStart = fromSlot.label;

                  slots.to.forEach(slot =>{
                    if(slot.value - fromSlot.value >= frame) slot.disabled = false;
                  });
                }
            }))
    }


    function generateToSlots(RS:ILiveTrackingRescheduler, day){
      return createArrayOfNumbersBetween(until, max)
            .map((num, i) => ({
                id: i,
                label: `${num}:00`,
                value: num, 
                checked: false,
                disabled: true,
                click: (oi, ti) => {
                  RS.activeOption = RS.options.find(({id}) => id===oi);
                  const slots = RS.activeOption.slots;
                  slots.to.forEach(slot => slot.checked = false);

                  const toSlot = RS.activeToSlot = slots.to[ti];
                  toSlot.checked = true;

                  parent.selectedDate = <any>day;
                  parent.selectedTimeEnd = toSlot.label;
                  parent.disableNext = false;
                }
            }))
    };


    return new function(){
      const self = this;
      const options = eachDayOfInterval({
        start: addDays(new Date(),1), 
        end: addDays(new Date(), extractInteger(delivery_reschedule_days) + unused)
      })
      .reduce((nu, day, i) =>  [...nu, {
          id: i,
          iso: day,
          number: format(day, 'dd', {locale: datesLang}),
          weekday: format(day, 'eeee', {locale: datesLang}),
          month: format(day, 'LLLL', {locale: datesLang}),
          disabled: !delivery_days[getDay(day)],
          slots:{
            from: generateFromSlots(self, day),
            to: generateToSlots(self, day)
          }
        }]
    , []);
      
      this.options = options;
      this.activeOption = undefined;
      this.activeSlots = undefined;
      this.activeFromSlot = undefined;
      this.activeToSlot = undefined;
      this.reset = (self) => reset(self);
    };
  }
  


  public ngAfterViewInit() {
    const defaultLayers = this.platform.createDefaultLayers();
    this.map = new H.Map(
      this.mapElement.nativeElement,
      defaultLayers.vector.normal.map,
      {
        zoom: 6,
        center: { lat: this.greeceLat, lng: this.greeceLon },
        // pixelRatio: window.devicePixelRatio || 1
      }
    );
    var provider = this.map.getBaseLayer().getProvider();
    var style = new H.map.Style('/assets/lastmilyAssets/light-final.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
    provider.setStyle(style);
    const mapEvents = new H.mapevents.MapEvents(this.map);
    // Instantiate the default behavior, providing the mapEvents object:
    this.behavior = new H.mapevents.Behavior(mapEvents);
    this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
    const mapSettings = this.ui.getControl('mapsettings');
    const scalebar = this.ui.getControl('scalebar');
    mapSettings.setAlignment('top-left');
    scalebar.setAlignment('top-left');
    this.displayInfoBubble = (evt) => {
      if (this.infoBubble && this.ui.getBubbles()) {
        this.ui.removeBubble(this.infoBubble);
        this.infoBubble.close();
      }
      this.infoBubble = new H.ui.InfoBubble(evt.target.getGeometry().extractPoint(0), {

        // Finally add content
        content: evt.target.getData()

      });

      this.ui.addBubble(this.infoBubble);
    };
    this.clientMapGroup.addObject(this.clientSolutionGroup);
    this.clientMapGroup.addObject(this.clientOldRouteGroup);
    this.clientMapGroup.addObject(this.clientMarkersGroup);
    this.map.addObject(this.clientMapGroup);

    flatpickr('#basicDate', {
      enableTime: false,
      dateFormat: "d/m/Y",
      inline: true,
      minDate: 'today',
      disable: ["today"],
      monthSelectorType: "static"
    });

    setTimeout(_=> {
      if(this.clientPortalChatModalComponent) this.clientPortalChatModalComponent.selectedColor = this.theme.color2;
    }, 500);

    setTimeout(_=>{
      if(this.showDrawerTip || (this.isPinReadyToShow && !this.userHidPin)){
        this.setStatusOpen();
        if(this.showDrawerTip) this.cacheDrawerTip();
        if(!this.isPinReadyToShow) setTimeout(_=> this.setStatusClosed(), 4000);
      }
    })


  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
    // this.interval
  }

}
