import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  // when in collaborator form, fill this with the collaborator's zones so the spatial charges can see them
  collaboratorZones = [];

  private listeners = new Subject<any>();
  private openCustomerModalListeners = new Subject<any>();
  private openCustomerDateModalListeners = new Subject<any>();
  private openInvoiceModalListeners = new Subject<any>();
  private openPaymentModalListeners = new Subject<any>();
  private updatePaymentModalListeners = new Subject<any>();
  private openCustomerCollaboratorsModalListeners = new Subject<any>();
  private openPartnerModalListeners = new Subject<any>();
  private openDriverModalListeners = new Subject<any>();
  private updateWeightChargesListener = new Subject<any>();
  private updateDiscountZonesListener = new Subject<any>();
  private updateSurchargeListener = new Subject<any>();
  private updateItemChargesListener = new Subject<any>();
  private updateWeightChargesFormListener = new Subject<any>();
  private updateItemsChargesFormListener = new Subject<any>();
  private updateServicesChargesFormListener = new Subject<any>();
  private removeWeightChargesListener = new Subject<any>();
  private updateServiceChargesListener = new Subject<any>();
  private updateSurchargesListener = new Subject<any>();
  private removeServiceChargesListener = new Subject<any>();
  private updateStopPointsDateListener = new Subject<any>();
  private submitDateRangeListener = new Subject<any>();
  private submitDateListener = new Subject<any>();
  private openDateModalListener = new Subject<any>();
  private updateInvoiceDateListener = new Subject<any>();
  private updateCustomerDateListener = new Subject<any>();
  private updateCollaboratorsChargesFormListener = new Subject<any>();
  private deleteCollaboratorsChargesFormListener = new Subject<any>();
  private deleteCollaboratorsRegionFormListener = new Subject<any>();
  private updateCollaboratorsChargesRegionFormListener = new Subject<any>();
  private updateStopPointsGridListener = new Subject<any>();
  private setSpatialCollaboratorListener = new Subject<any>();
  private deleteZoneInCollaboratorListener = new Subject<any>();
  private refreshStopsGridListener = new Subject<any>();
  private openShipmentsModalListener = new Subject<any>();
  private clearRegionsSelectionsListener = new Subject<any>();
  private updateReportShipmentsModalListener = new Subject<any>();
  private closeAddStopModalListener = new Subject<any>();
  private openAddStopModalListener = new Subject<any>();

  listen(): Observable<any> {
    return this.listeners.asObservable();
  }
  openCustomerModalListen(): Observable<any> {
    return this.openCustomerModalListeners.asObservable();
  }
  openCustomerDateModalListen(): Observable<any> {
    return this.openCustomerDateModalListeners.asObservable();
  }
  openInvoiceModalListen(): Observable<any> {
    return this.openInvoiceModalListeners.asObservable();
  }
  openPaymentModalListen() {
    return this.openPaymentModalListeners.asObservable();
  }
  updatePaymentModalListen() {
    return this.updatePaymentModalListeners.asObservable();
  }
  openCustomerCollaboratorsModalListen(): Observable<any> {
    return this.openCustomerCollaboratorsModalListeners.asObservable();
  }
  openPartnerModalListen(): Observable<any> {
    return this.openPartnerModalListeners.asObservable();
  }
  openDriverModalListen(): Observable<any> {
    return this.openDriverModalListeners.asObservable();
  }
  updateWeightChargesListen(): Observable<any> {
    return this.updateWeightChargesListener.asObservable();
  }
  updateDiscountZonesListen(): Observable<any> {
    return this.updateDiscountZonesListener.asObservable();
  }
  updateSurchargeListen(): Observable<any> {
    return this.updateSurchargeListener.asObservable();
  }
  updateItemChargesListen(): Observable<any> {
    return this.updateItemChargesListener.asObservable();
  }
  updateWeightChargesFormListen(): Observable<any> {
    return this.updateWeightChargesFormListener.asObservable();
  }
  updateItemsChargesFormListen(): Observable<any> {
    return this.updateItemsChargesFormListener.asObservable();
  }
  updateServicesChargesFormListen(): Observable<any> {
    return this.updateServicesChargesFormListener.asObservable();
  }
  removeWeightChargesListen(): Observable<any> {
    return this.removeWeightChargesListener.asObservable();
  }
  updateServiceChargesListen(): Observable<any> {
    return this.updateServiceChargesListener.asObservable();
  }
  updateSurchargesListen(): Observable<any> {
    return this.updateSurchargesListener.asObservable();
  }
  removeServiceChargesListen(): Observable<any> {
    return this.removeServiceChargesListener.asObservable();
  }
  updateStopPointsDateListen(): Observable<any> {
    return this.updateStopPointsDateListener.asObservable();
  }
  submitDateRangeListen(): Observable<any> {
    return this.submitDateRangeListener.asObservable();
  }
  openDateModalListen(): Observable<any> {
    return this.openDateModalListener.asObservable();
  }
  submitDateListen(): Observable<any> {
    return this.submitDateListener.asObservable();
  }
  updateInvoiceDateListen(): Observable<any> {
    return this.updateInvoiceDateListener.asObservable();
  }
  updateCustomerDateListen(): Observable<any> {
    return this.updateCustomerDateListener.asObservable();
  }
  updateCollaboratorsChargesFormListen(): Observable<any> {
    return this.updateCollaboratorsChargesFormListener.asObservable();
  }
  deleteCollaboratorsChargesFormListen(): Observable<any> {
    return this.deleteCollaboratorsChargesFormListener.asObservable();
  }
  deleteCollaboratorsRegionFormListen(): Observable<any> {
    return this.deleteCollaboratorsRegionFormListener.asObservable();
  }
  updateCollaboratorsChargesRegionFormListen(): Observable<any> {
    return this.updateCollaboratorsChargesRegionFormListener.asObservable();
  }
  updateStopPointsGridListen(): Observable<any> {
    return this.updateStopPointsGridListener.asObservable();
  }
  setSpatialCollaboratorListen(): Observable<any> {
    return this.setSpatialCollaboratorListener.asObservable();
  }
  deleteZoneInCollaboratorListen(): Observable<any> {
    return this.deleteZoneInCollaboratorListener.asObservable();
  }
  refreshStopsGridListen(): Observable<any> {
    return this.refreshStopsGridListener.asObservable();
  }
  openShipmentsModalListen(): Observable<any> {
    return this.openShipmentsModalListener.asObservable();
  }
  clearRegionsSelectionsListen(): Observable<any> {
    return this.clearRegionsSelectionsListener.asObservable();
  }
  updateReportShipmentsModalListen(): Observable<any> {
    return this.updateReportShipmentsModalListener.asObservable();
  }
  closeAddStopModalListen(): Observable<any> {
    return this.closeAddStopModalListener.asObservable();
  }
  openAddStopModalListen(): Observable<any> {
    return this.openAddStopModalListener.asObservable();
  }

  filter(formName: string, type, id) {
    this.listeners.next({
      form: formName,
      type: type,
      id: id
    });
  }

  openCustomerModal(id) {
    this.openCustomerModalListeners.next(id);
  }
  openCustomerDateModal() {
    this.openCustomerDateModalListeners.next(1);
  }
  openInvoiceModal(data) {
    this.openInvoiceModalListeners.next(data);
  }
  openPaymentModal(data) {
    this.openPaymentModalListeners.next(data);
  }
  updatePaymentModal() {
    this.updatePaymentModalListeners.next(1);
  }
  openCustomerCollaboratorsModal(data) {
    this.openCustomerCollaboratorsModalListeners.next(data);
  }
  openPartnerModal(data) {
    this.openPartnerModalListeners.next(data);
  }
  openDriverModal() {
    this.openDriverModalListeners.next(1);
  }
  updateWeightCharges(newCharge) {
    this.updateWeightChargesListener.next(newCharge);
  }
  updateDiscountZones(discount) {
    this.updateDiscountZonesListener.next(discount);
  }
  updateSurcharge(surcharge) {
    this.updateSurchargeListener.next(surcharge);
  }
  updateItemCharges(newCharge) {
    this.updateItemChargesListener.next(newCharge);
  }
  updateWeightChargesForm(data) {
    this.updateWeightChargesFormListener.next(data);
  }
  updateItemsChargesForm(data) {
    this.updateItemsChargesFormListener.next(data);
  }
  updateServicesChargesForm(data) {
    this.updateServicesChargesFormListener.next(data);
  }
  removeWeightCharges(charge) {
    this.removeWeightChargesListener.next(charge);
  }
  updateServiceCharges(newCharge) {
    this.updateServiceChargesListener.next(newCharge);
  }
  updateSurcharges(newCharge) {
    this.updateSurchargesListener.next(newCharge);
  }
  removeServiceCharges(charge) {
    this.removeServiceChargesListener.next(charge);
  }
  updateStopPointsDate(dates) {
    this.updateStopPointsDateListener.next(dates);
  }
  submitDateRange(dates) {
    this.submitDateRangeListener.next(dates);
  }
  submitDate(date) {
    this.submitDateListener.next(date);
  }
  openDateModal(type) {
    this.openDateModalListener.next(type);
  }
  updateInvoiceDate(date) {
    this.updateInvoiceDateListener.next(date);
  }
  updateCustomerDate(date) {
    this.updateCustomerDateListener.next(date);
  }
  updateCollaboratorsChargesForm(chargeCategories) {
    this.updateCollaboratorsChargesFormListener.next(chargeCategories);
  }
  deleteCollaboratorsChargesForm(chargeCategories) {
    this.deleteCollaboratorsChargesFormListener.next(chargeCategories);
  }
  deleteCollaboratorsRegionForm(chargeCategories) {
    this.deleteCollaboratorsRegionFormListener.next(chargeCategories);
  }
  updateCollaboratorsChargesRegionForm(regionCharge) {
    this.updateCollaboratorsChargesRegionFormListener.next(regionCharge);
  }
  updateStopPointsGrid() {
    this.updateStopPointsGridListener.next(1);
  }
  setSpatialCollaborator(spatialCategories) {
    this.setSpatialCollaboratorListener.next(spatialCategories);
  }
  deleteZoneInCollaborator(zone) {
    this.deleteZoneInCollaboratorListener.next(zone);
  }
  refreshStopsGrid() {
    this.refreshStopsGridListener.next(1);
  }
  openShipmentsModal(type) {
    this.openShipmentsModalListener.next(type);
  }
  clearRegionsSelections() {
    this.clearRegionsSelectionsListener.next(1);
  }
  updateReportShipmentsModal() {
    this.updateReportShipmentsModalListener.next(1);
  }
  closeAddStopModal() {
    this.closeAddStopModalListener.next(1);
  }
  openAddStopModal() {
    this.openAddStopModalListener.next(1);
  }
}
